import React, { Component } from "react";
import store from "store";
import * as ReactDOM from "react-dom";
import TutorialCard from "./TutorialCard";
import TutorialDetail from "./TutorialDetail";
import { getTutorialsByCategory } from "../../services/tutorialService";
import Carousel from "../../components/Carousel";
import { Icon } from "../../components/Common";
import { t } from "../../utils/core";

class TutorialGallery extends Component {
  handleTutorialClick = (e, tut, index) => {
    const { id } = this.props;
    if (this.props.setSelected) {
      // Set this item as selected
      this.props.setSelected(id, index);
    }
  };

  closeDetail = () => {
    const { id } = this.props;
    this.props.setSelected(id, undefined);
  };

  getCarouselDOM() {
    let carouselDom = ReactDOM.findDOMNode(this.refs["carousel"]);
    return carouselDom;
  }

  render() {
    const { category, selectedIndex } = this.props;
    const tutorials = getTutorialsByCategory(category.id);
    const projects = Array.isArray(store.get("projects")) ? store.get("projects") : [];
    projects.forEach(p => {
      const tut = tutorials.find(tut => tut.id === p.id);
      if (tut && p.step) {
        tutorials[tutorials.indexOf(tut)].progress = p.step;
        tutorials[tutorials.indexOf(tut)].isFinished = p.isFinished || false;
      }
    });
    return (
      <div className="ui segment gallerysegment">
        <h2 className="ui header heading">{t(category.name)}</h2>
        <div className="content">
          <div>
            <Carousel bleedPercent={20} selectedIndex={selectedIndex} ref="carousel">
              {tutorials.map((tut, index) => (
                <TutorialCard
                  tutorial={tut}
                  key={tut.id}
                  label={tut.label}
                  labelClass={tut.labelClass}
                  onTutorialClick={this.handleTutorialClick}
                  catName={category.name}
                  index={index}
                />
              ))}
            </Carousel>
            <div
              ref="detailView"
              className={`detailview ${
                tutorials.filter((tut, index) => index === selectedIndex).length > 0 ? "visible" : ""
              }`}
            >
              {tutorials.filter((tut, index) => index === selectedIndex).length > 0 ? (
                <div className="close">
                  <Icon tabIndex={0} icon="remove circle" onClick={this.closeDetail} />
                </div>
              ) : (
                undefined
              )}
              {tutorials
                .filter((tut, index) => index === selectedIndex)
                .map(tut => (
                  <TutorialDetail tutorial={tut} key={tut.id} />
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TutorialGallery;
