module.exports = {
    
    id: "https://ohstem.vn/cham-soc-vuon-cay-thong-minh/",
    title: "Chăm sóc vườn cây thông minh",
    detail:
      'Khi bạn muốn học lập trình nhưng lại bị bắt chăm cây. Không sao! Hãy cùng Yolo:Bit làm một bộ chăm sóc cây thông minh nào!',
    image: "/assets/tutorials/courses/tuoicay.jpg",
    categoryIds: ["creation"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  