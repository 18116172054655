import React, { Component } from "react";
import logo from "../../assets/logo.png";
import vnFlag from "../../assets/vn.svg";
import enFlag from "../../assets/en.svg";
import { Icon } from "./../../components/Common";

class Menu extends Component {
  changeLanguage = lang => {
    const { i18n } = this.props.parent.props;
    i18n.changeLanguage(lang);
  };

  render() {
    const { t } = this.props.parent.props;
    return (
      <div className="menubar" role="banner">
        <div id="homemenu" className="ui borderless fixed inverted menu" role="menubar">
          <div className="left menu">
            <a href="/" className="header item">
              <img  alt="logo" className="logo" src={logo} style={{height:"50px",width:"auto"}} />
              {/* <span className="ui text landscape only">Yolo:Bit</span> */}
            </a>
          </div>
          <div className="ui item home">
            <Icon icon="home large" />
            <span className="ui text landscape only">{t("Home")}</span>
          </div>
          <div className="right menu">
            <a href="/" title="Tiếng Việt" onClick={() => this.changeLanguage("vi")} className="header item vi-lang">
              <img alt="Tiếng Việt" src={vnFlag} />
            </a>
            <a href="/" title="English" onClick={() => this.changeLanguage("en")} className="header item en-lang">
              <img alt="Tiếng Anh" src={enFlag} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
