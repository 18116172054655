module.exports = {
  status:"hardware",
    //id: "https://ohstem.vn/knowledge-base/yolobit/",
    id:"https://ohshop.vn/product/yolobit-basic/",
    title: "Yolo:Bit",
    detail:
      'Yolo:Bit là một máy tính mini (52x50mm) lập trình được và được tích hợp rất nhiều các tính năng như nút nhấn, đèn led, loa và các cảm biến giúp người mới bắt đầu làm quen với lập trình thật dễ dàng thông qua các bài học thú vị.',
    image: "/assets/tutorials/courses/yolobit.jpg",
    categoryIds: ["hardware"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  