import store from "store";
import * as core from "../utils/core";

const coreExtensions = [
  {
    name: "Bàn phím cảm ứng",
    description: "Mục mở rộng cho bàn phím cảm ứng 12 phím MPR121",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/thienuittc/blocky_keypad/poster.jpg',
    src: "https://github.com/thienuittc/blocky_keypad",
    id: "thienuittc/blocky_keypad"
  },
  {
    name: "Cảm biến DHT",
    description: "Mục mở rộng cho cảm biến nhiệt độ và độ ẩm DHT",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/vanminh0910/yolobit_dht/poster.jpg',
    src: "https://github.com/vanminh0910/yolobit_dht",
    id: "vanminh0910/yolobit_dht"
  },
  {
    name: "Cảm biến khoảng cách",
    description: "Mục mở rộng cho cảm biến siêu âm đo khoảng cách",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/vanminh0910/yolobit_ultrasonic/poster.jpg',
    src: "https://github.com/vanminh0910/yolobit_ultrasonic",
    id: "vanminh0910/yolobit_ultrasonic"
  },
  {
    name: "Màn hình OLED I2C",
    description: "Mục mở rộng cho màn hình OLED I2C",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/vanminh0910/yolobit_oled_i2c/poster.jpg',
    src: "https://github.com/vanminh0910/yolobit_oled_i2c",
    id: "vanminh0910/yolobit_oled_i2c"
  },
  {
    name: "HOME:BIT",
    description: "Mục mở rộng cho bộ sản phẩm nhà thông minh",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/thienuittc/blocky_homebit/smarthome.jpg',
    src: "https://github.com/thienuittc/blocky_homebit.git",
    id: "thienuittc/blocky_homebit"
  },
  {
    name: "CAR:BIT",
    description: "Mục mở rộng cho bộ xe điều khiển",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/thienuittc/blocky_carbit/poster.png',
    src: "https://github.com/thienuittc/blocky_carbit.git",
    id: "thienuittc/blocky_carbit"
  },
  {
    name: "Xe điều khiển V2",
    description: "Mục mở rộng dành cho bộ kit xe điều khiển Car:Bit v2",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/AITT-VN/yolobit_carbit_v2/poster.png',
    src: "https://github.com/AITT-VN/yolobit_carbit_v2.git",
    id: "AITT-VN/yolobit_carbit_v2"
  },
  {
    name: "SOUND:BIT",
    description: "Mục mở rộng cho máy nghe nhạc",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/thienuittc/blocky_soundbit/poster.jpg',
    src: "https://github.com/thienuittc/blocky_soundbit",
    id: "thienuittc/blocky_soundbit"
  },
  {
    name: "ARM:BIT",
    description: "Mục mở rộng cho cánh tay robot",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/thienuittc/armbit/poster.jpg',
    src: "https://github.com/thienuittc/armbit.git",
    id: "thienuittc/armbit"
  },
  {
    name: "PLANT:BIT",
    description: "Mục mở rộng cho bộ tưới nước thông minh",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/thienuittc/blocky_plantbit/poster.png',
    src: "https://github.com/thienuittc/blocky_plantbit",
    id: "thienuittc/blocky_plantbit"
  }  ,
  {
    name: "ROBOT SHIELD",
    description: "Mục mở rộng cho Robot Shield",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/vanminh0910/yolobit_robotshield/poster.jpg',
    src: "https://github.com/vanminh0910/yolobit_robotshield",
    id: "vanminh0910/yolobit_robotshield"
  },
  {
    name: "HOME:BIT V2",
    description: "Mục mở rộng cho Home:Bit V2",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/AITT-VN/yolobit_homebit_v2/homebit.png',
    src: "https://github.com/AITT-VN/yolobit_homebit_v2",
    id: "AITT-VN/yolobit_homebit_v2"
  },
  {
    name: "BLYNK",
    description: "Mục mở rộng cho Blynk",
    poster: 'https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/AITT-VN/yolobit_blynk/poster.png',
    src: "https://github.com/AITT-VN/yolobit_blynk",
    id: "AITT-VN/yolobit_blynk"
  },
];

export function getCoreExtensions() {
  return coreExtensions;
}

export function getExtensions() {
  let installedExtensions = Array.isArray(store.get("extensions")) ? store.get("extensions") : [];
  if (installedExtensions.length) {
    let extensions = coreExtensions.filter(
      coreExt => !installedExtensions.find(installedExt => coreExt.id === installedExt.id)
    );

    return extensions.concat(installedExtensions);
  } else {
    return coreExtensions;
  }
}

export function searchExtensions(name) {
  name = core.cleanAccents(name).toLowerCase();

  if (!name) {
    return coreExtensions;
  }

  return getExtensions().filter(ext =>
    core
      .cleanAccents(ext.name)
      .toLowerCase()
      .includes(name)
  );
}
