module.exports = {
    
    id: "https://ohstem.vn/may-do-khoang-cach/",
    title: "Máy đo khoảng cách",
    detail:
      'Ở phần này chúng ta hãy cùng nhau làm một thiết bị đo khoảng cách và hiển thị lên màn hình oled.',
    image: "/assets/tutorials/courses/maydokhoangcach.jpg",
    categoryIds: ["creation"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  