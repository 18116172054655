module.exports = {
    
    id: "https://ohstem.vn/do-nhip-tim/",
    title: "Đo nhịp tim",
    detail:
      'Nhịp đập của tim là thông số rất quan trọng xây dựng 1 bài tập thể dục thật sự khoa học. Trước đây, cảm biến nhịp tim chỉ thường xuất hiện trên các thiết bị đắt tiền như : máy chạy bộ, máy đo trong bệnh viện, smartphone,..v.v.v…. Giờ đây đã xuất hiện 1 loại cảm biến đo nhịp tim mà chúng ta dễ dàng kết nối với Yolo:Bit',
    image: "/assets/tutorials/courses/donhiptim.jpg",
    categoryIds: ["creation"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  