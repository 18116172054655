module.exports = {
  status : "tutorial",
    id: "hien-thi-nhiet-do",
    title: "Hiển thị nhiệt độ",
    detail: "Bài này hướng dẫn cách dùng cảm biến nhiệt độ có sẵn trên Yolo:Bit. Cứ sau mỗi 2 giây, chúng ta sẽ cập nhật giá trị nhiệt độ (độ C) và hiện ra màn hình led.",
    image: "/assets/tutorials/hien-thi-nhiet-do/hien-thi-nhiet-do.jpg",
    categoryIds: ["tutorial"],
    toolbox: [{
            category: "basic",
            blocks: ["yolobit_basic_forever", "yolobit_basic_scroll_number", "yolobit_basic_sleep"]
        },
        {
            category: "input",
            blocks: ["yolobit_input_get_temperature"]
        }
    ],
    steps: [{
            title: "Giới thiệu",
            content: `<div>
        <p>Bài này hướng dẫn cách dùng cảm biến nhiệt độ có sẵn trên Yolo:Bit. Cứ sau mỗi 2 giây, chúng ta sẽ cập nhật giá trị nhiệt độ (độ C) và hiện ra màn hình led.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-nhiet-do/hien-thi-nhiet-do.jpg">
        </div>
      </div>`
        },
        {
            title: "Bước 1",
            content: `<div>
        <p>Kéo khối <block class="basic">hiện ra số</block> trong mục <block class="basic">CƠ BẢN</block> ra và gắn vào khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-nhiet-do/step1.png">
        </div>
      </div>`
        },
        {
            title: "Bước 2",
            content: `<div>
        <p>Sử dụng khối <block class="input">nhiệt độ</block> trong mục <block class="input">NGÕ VÀO</block> và gắn vào khối <block class="basic">hiện ra số</block> thay cho số 0:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-nhiet-do/step2.png">
        </div>
      </div>`
        },
        {
            title: "Bước 3",
            content: `<div>
        <p>Sử dụng khối <block class="basic">tạm dừng 1000 milli giây</block> trong mục <block class="basic">CƠ BẢN</block> để tạm dừng chương trình giúp dễ dàng nhìn thấy số hiện ra trên màn hình Led. Thay đổi số 1000 thành 2000 milli giây, tương đương với 2 giây:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-nhiet-do/step3.png">
        </div>
      </div>`
        },
        {
            title: "Bước 4",
            content: `<div>
        <p>Cuối cùng nhấn vào nút <b>Chạy</b> để chạy chương trình cho Yolo:Bit và quan sát kết quả khi để gần Yolo:Bit những chỗ nóng hoặc máy lạnh hay quạt.</p>
      </div>`
        }
    ]
};