import React from "react";

const Footer = () => {
  return (
    <div className="ui horizontal small divided link list homefooter">
      <a className="item" href="/">
        Copyright © {new Date().getFullYear()} Ohstem Team. All rights reserved.
      </a>
    </div>
  );
};

export default Footer;
