module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/tim-hieu-dien-tu-co-ban-cung-physbit/",
    title: "Tìm hiểu Điện tử cơ bản cùng Phys:Bit",
    detail:
      'Khóa học hướng dẫn làm quen với Điện tử cơ bản với Yolo:Bit và bộ kit mở rộng Phys:Bit.',
    image: "/assets/tutorials/courses/DIENTU.jpg",
    categoryIds: ["courses"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  