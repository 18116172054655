module.exports = {
  status : "tutorial",
  id: "happy-birthday",
  title: "Happy Birthday",
  detail: "Hãy cho Yolo:Bit chơi một bài nhạc có sẵn rất quen thuộc là bài Happy birthday.",
  image: "/assets/tutorials/happy-birthday/happy-birthday.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_forever"]
    },
    {
      category: "music",
      blocks: ["yolobit_music_play_built_in"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Hãy cho Yolo:Bit chơi một bài nhạc có sẵn rất quen thuộc là bài Happy birthday.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/happy-birthday/happy-birthday.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Trong mục <block class="music">ÂM NHẠC</block>, kéo khối <block class="music">phát bài nhạc ...</block>  ra ngoài và gắn vào trong khối <block>băt đầu ...</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/happy-birthday/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Nhấn vào chữ DADADADUM để thay đổi thành BIRTHDAY:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/happy-birthday/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Kết nối board rồi nhấn vào nút <b>Chạy</b> để để chạy chương trình và nghe thử giai điệu bài hát Happy Birthday được phát ra từ Yolo:Bit của bạn.</p>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
        <p>Hãy lập trình để Yolo:Bit phát ra lần lượt các bài hát theo thứ tự sau:</p>
        <p>1. Birthday</p>
        <p>2. Ringtone</p>
        <p>3. Wedding</p>
        <p>Sau đó lặp lại chương trình</p>
      </div>`
    }
  ]
};
