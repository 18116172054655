import { SERVER_URL } from "../config.json";

const apiEndpoint = SERVER_URL + "/shares";

export function shareProject(project) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(project),
  };
  return fetch(apiEndpoint, requestOptions);
}

export function getSharedProject(id) {
  return fetch(apiEndpoint + `/${id}`);
}

export default {
  shareProject,
  getSharedProject,
};
