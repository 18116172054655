module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-nang-cao/lap-trinh-micropython-voi-yolobit/",
    title: "Lập Trình MicroPython Với Yolo:Bit",
    detail:
      'Ngoài lập trình dạng khối lệnh kéo thả phù hợp cho người mới bắt đầu làm quen với lập trình, Yolo:Bit còn hỗ trợ lập trình dạng text với ngôn ngữ MicroPython rất thân thiện với người dùng.',
    image: "/assets/tutorials/courses/micropython.jpg",
    categoryIds: ["courses"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  