const devices = [
  {
    id: "yolobit_v1_2",
    title: "Yolo:Bit V1/V2",
    detail: "",
    image: "/assets/devices/yolobit_v12.jpg",
    connection: 0,
    installationGuideUrl: "https://ohstem.vn/knowledge-base/huong-dan-cap-nhat-driver-cho-windows-7/",
    downloadFirmwareUrl: "https://s3-ap-southeast-1.amazonaws.com/yolobit-firmware.ohstem.vn/"
  },
  {
    id: "yolobit_v3",
    title: "Yolo:Bit V3",
    detail: "",
    image: "/assets/devices/yolobit_v3.png",
    connection: 0,
    installationGuideUrl: "https://ohstem.vn/knowledge-base/huong-dan-cap-nhat-driver-cho-windows-7/",
    downloadFirmwareUrl: "https://s3-ap-southeast-1.amazonaws.com/yolobit-firmware.ohstem.vn/"
  },
  {
    id: "nodewifi32",
    title: "Node WiFi32",
    detail: "",
    image: "/assets/devices/nodewifi32.jpg",
    connection: 1,
    installationGuideUrl: "https://docs.micropython.org/en/latest/esp32/tutorial/intro.html",
    downloadFirmwareUrl: "https://s3-ap-southeast-1.amazonaws.com/yolobit-firmware.ohstem.vn/"
  },
  {
    id: "nodewifi",
    title: "Node WiFi",
    detail: "",
    image: "/assets/devices/nodewifi.jpg",
    connection: 1,
    installationGuideUrl: "https://docs.micropython.org/en/latest/esp8266/tutorial/intro.html",
    downloadFirmwareUrl: ""
  },
  {
    id: "esp32_nodemcu",
    title: "NodeMCU ESP32",
    detail: "",
    image: "/assets/devices/esp32_nodemcu.jpg",
    connection: 1,
    installationGuideUrl: "https://docs.micropython.org/en/latest/esp32/tutorial/intro.html",
    downloadFirmwareUrl: ""
  },
  {
    id: "esp8266_wemos_d1_mini",
    title: "Wemos D1 Mini",
    detail: "",
    image: "/assets/devices/esp8266_wemos_d1_mini.jpg",
    connection: 1,
    installationGuideUrl: "https://docs.micropython.org/en/latest/esp8266/tutorial/intro.html",
    downloadFirmwareUrl: ""
  }
];

export function getDeviceList() {
  return devices;
}

export function getDevice(id) {
  return devices.find((t) => t.id === id);
}
