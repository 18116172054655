module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/lap-trinh-tro-choi-voi-yolobit/lessons/gioi-thieu-3/",
    title: "Ai nhanh tay hơn",
    detail:
      'Trong phần này, chúng ta sẽ làm một trò chơi cho 2 người chơi thi xem ai phản ứng nhanh nhất. Sau khi bắt đầu, Yolo:Bit sẽ hiện ra một hình trên màn hình LED sau 1 thời gian ngẫu nhiên không biết trước. Hai người chơi sẽ thi xem ai nhấn nút nhanh hơn thì sẽ chiến thắng.',
    image: "/assets/tutorials/courses/ainhanh.jpg",
    categoryIds: ["games"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  