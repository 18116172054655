import React, { Component, Fragment } from "react";
import $ from "jquery";
import AceEditor from "react-ace";
import md5 from "md5";
import { withTranslation } from "react-i18next";
import scriptjs from "scriptjs";
import uuid from "uuid";
import moment from "moment";
import "brace/mode/python";
import "brace/theme/twilight";
import "brace/ext/language_tools";
import "brace/snippets/python";
import TutorialMenu from "./TutorialMenu";
import EditorMenu from "./EditorMenu";
import SharedMenu from "./SharedMenu";
import EditorToolbar from "./EditorToolbar";
import { getToolbox } from "../../services/toolboxService";
import * as dialogs from "../../utils/dialogs";
import * as core from "../../utils/core";
import { t } from "../../utils/core";
import Workspace from "./Workspace";
import { getTutorial } from "../../services/tutorialService";
import { getDevice } from "../../services/devicesList";
import { TutorialHint } from "../../components/TutorialHint";
import { Extensions } from "../../components/Extensions";
import { ShareProject } from "../../components/ShareProject";
import Loader from "../../components/Loader";
import ExitAndSaveDialog from "./ExitAndSaveDialog";
import "./index.scss";
import * as browser from "../../utils/browser";
import { shareProject, getSharedProject } from "../../services/shareService";
import storeService from "../../services/storeService";
import * as extUtils from "../../utils/extUtils";
import ListDevice from "./ListDevice";
import { NO_LABLE_ARRAY } from "../../utils/constants";

let workspace = undefined;
let deviceConnection = undefined;
let Blockly = undefined;
let isBlockDefault = true;

const initState = {
  id: "",
  type: "",
  tutorialOptions: { tutorialStep: 0 },
  mode: "block",
  python: "",
  connectStatus: false,
  serial: "",
  name: "",
  isFinished: false,
  sharedUrl: "",
  openDevicePage: false,
};
class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
    window.t = this.props.t;
    this.state.loading = true;
    this.projectService = new storeService("projects");
    this.extService = new storeService("extensions");
    this.currentDevice = getDevice(core.getCurrentDevice());
    this.dataChartSerial = {};
  }

  openNewTab = () => {
    window.open(this.currentDevice.installationGuideUrl, "_blank");
  };

  openBlocks() {
    if (this.isPythonActive()) {
      let codeFromBlocks = Blockly.Python.workspaceToCode(this.workspace);
      let codeFromAce = this.state.python;
      codeFromBlocks = codeFromBlocks.replace(/[\r\n\t]/g, "").replace(/\s+/g, "");
      codeFromAce = codeFromAce.replace(/[\r\n\t]/g, "").replace(/\s+/g, "");

      if (md5(codeFromBlocks) !== md5(codeFromAce)) {
        dialogs.showConvertToBlocksDialogAsync().done((res) => {
          if (res) {
            this.setState({ mode: "block" });
            $("#pythonArea").hide();
            $("#blocklyArea").show("fast", () => {
              this.loadBlocks(this.state.xmlText);
              isBlockDefault = true;
            });
          }
        });
      } else {
        this.setState({ mode: "block" });
        $("#pythonArea").hide();
        if (isBlockDefault) {
          $("#blocklyArea").show();
          this.onResize();
        } else {
          $("#blocklyArea").show("fast", () => {
            this.loadBlocks(this.state.xmlText);
            isBlockDefault = true;
          });
        }
      }
    }
  }

  openPython() {
    if (this.isBlocksActive()) {
      const code = Blockly.Python.workspaceToCode(this.workspace);
      this.setState({ mode: "python", python: code });
      $("#blocklyArea").hide();
      $("#pythonArea").show();
      //this.onResize();
    }
  }

  isBlocksActive = () => {
    return this.state.mode === "block";
  };

  isPythonActive = () => {
    return this.state.mode === "python";
  };

  toggleConnect = () => {
    if (this.state.connectStatus) {
      deviceConnection.disconnect().then(
        () => {
          this.setState({ connectStatus: false });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      this.connectDevice();
    }
  };

  setDataChartSerial = (dataSerial) => {
    let defaultDataSerial = {
      colors: [`#${Math.floor(Math.random() * 16777215).toString(16)}`],
      data: [],
    };
    if (dataSerial.trim() !== "") {
      if (!isNaN(dataSerial)) {
        this.dataChartSerial[NO_LABLE_ARRAY] = this.dataChartSerial[NO_LABLE_ARRAY] || defaultDataSerial;
        this.dataChartSerial[NO_LABLE_ARRAY].data.push({ x: new Date().getTime(), y: Number(dataSerial) });
        this.updateChartSerial(this.dataChartSerial);
      } else if (/\w: [-,+]?\d/.test(dataSerial)) {
        let dataSplit = dataSerial.split(":");
        this.dataChartSerial[dataSplit[0]] = this.dataChartSerial[dataSplit[0]] || defaultDataSerial;
        this.dataChartSerial[dataSplit[0]].data.push({ x: new Date().getTime(), y: Number(dataSplit[1]) });
        this.updateChartSerial(this.dataChartSerial);
      }
    }
  };

  updateChartSerial = (dataChartSerial) => {
    if (this.chartSerialRef) this.chartSerialRef.setDataSeries(dataChartSerial);
  };

  connectDevice = (func, variable) => {
    deviceConnection.connect().then(
      () => {
        this.setState({ connectStatus: true });
        deviceConnection.onReceive = (data) => {
          let serial = this.state.serial + data.toString() + "\n";
          if (serial.length > 5000) {
            // cut off serial text when too long
            serial = serial.substr(1000);
          }
          this.setState({ serial });
          this.updateSerialDialog(serial);
          this.setDataChartSerial(data);
        };
        deviceConnection.onReceiveError = (error) => {
          console.error("onReceiveError:", error);
          let serial = this.state.serial + error.toString() + "\n";
          this.setState({ serial });
          this.updateSerialDialog(serial);
          core.errorNotification(t("yolobitDisconnected"));
          this.setState({ connectStatus: false });
        };
        core.infoNotification(t("yolobitConnected"));
        if (func) func(variable);
      },
      (error) => {
        core.errorNotification(t("cannotConnectYolobit"));
        console.error("Lỗi kết nối:", error);
      }
    );
  };

  download = () => {
    if (Object.keys(this.dataChartSerial).length) {
      this.dataChartSerial = {};
    }

    if (!this.state.connectStatus) {
      core.errorNotification(t("yolobitNotConnected"));
      return;
    }

    this.setState({loading: true});
    let code;
    if (this.isBlocksActive()) {
      code = Blockly.Python.workspaceToCode(this.workspace);
    } else code = this.state.python;
    code = core.cleanAccents(code);

    deviceConnection.sendFile(code).then(
      () => {
        core.infoNotification(t("The program was downloaded successfully"));
        this.setState({loading: false});
      },
      (error) => {
        console.error(error);
        core.errorNotification(t("There was an error downloading your file") + error);
        this.setState({loading: false});
      }
    );
  };

  serial = () => {
    dialogs.showSerialDialogAsync(this.state.serial).done((res) => {
      if (res) {
        this.setState({ serial: "" });
      }
    });
  };

  getRefChartSerial = (ref) => {
    this.chartSerialRef = ref;
  };

  convertArrayOfObjectsToCSV = (array) => {
    let result = "";

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = { time: "x", value: "y" };

    let lengthArray = 0;
    Object.keys(array).forEach((item) => {
      if (array[item].data.length > lengthArray) lengthArray = array[item].data.length;
      Object.keys(keys).forEach((header) => {
        result += `${item} (${header})${columnDelimiter}`;
      });
    });
    result += lineDelimiter;

    for (let i = 0; i < lengthArray; i++) {
      Object.keys(array).forEach((item) => {
        if (array[item].data[i]) {
          Object.values(keys).forEach((elm) => {
            let dataItem = array[item].data[i][elm];
            if (elm === keys.time) {
              dataItem = `${new Date(dataItem).toLocaleDateString()} ${new Date(dataItem).toLocaleTimeString()}`;
            }
            result += `${dataItem} ${columnDelimiter}`;
          });
        }
      });
      result += lineDelimiter;
    }
    return result;
  };

  downloadDataSerialCSV = () => {
    const link = document.createElement("a");
    let csv = this.convertArrayOfObjectsToCSV(this.dataChartSerial);
    if (csv == null) return;

    const filename = "serial_chart.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  chartSerial = () => {
    dialogs.showChartSerialDialogAsync(this.getRefChartSerial).done((res) => {
      if (res) {
        this.downloadDataSerialCSV();
      }
    });
  };

  toggleSelect = () => {
    this.setState({ openDevicePage: true });
  };

  onCloseDevicePage = () => {
    this.setState({ openDevicePage: false });
  };

  onSelectDevice = (deviceId) => {
    if (deviceId !== core.getCurrentDevice()) {
      core.setCurrentDevice(deviceId);
      core.forceReload();
    }
  };

  onAceChange = (code) => {
    this.setState({ python: code });
  };

  updateSerialDialog() {
    const $textarea = $("#serial");
    if ($textarea.length) {
      $textarea.val(this.state.serial);
      $textarea.scrollTop($textarea[0].scrollHeight);
    }
  }

  onResize = () => {
    const blocklyArea = document.getElementById("blocklyArea");
    const blocksEditor = document.getElementById("blocksEditor");
    // Compute the absolute coordinates and dimensions of blocklyArea.
    let element = blocklyArea;
    let x = 0;
    let y = 0;
    do {
      x += element.offsetLeft;
      y += element.offsetTop;
      element = element.offsetParent;
    } while (element);
    // Position blocklyDiv over blocklyArea.
    blocksEditor.style.left = x + "px";
    blocksEditor.style.top = y + "px";
    blocksEditor.style.width = blocklyArea.offsetWidth + "px";
    blocksEditor.style.height = blocklyArea.offsetHeight + "px";
    Blockly.svgResize(workspace);
  };

  showTutorialHint() {
    const th = this.refs["tutorialhint"];
    th.showHint();
  }

  showExtensions() {
    const ex = this.refs["extensions"];
    ex.show();
  }

  hideExtensions() {
    const ex = this.refs["extensions"];
    ex.hide();
  }

  showSharedProject() {
    const sp = this.refs["shared-project"];
    sp.show();
  }

  setTutorialStep(step) {
    if (step > -1) {
      let tutorialOptions = this.state.tutorialOptions;
      tutorialOptions.tutorialStep = step;
      this.setState({ tutorialOptions: tutorialOptions });
      this.showTutorialHint();
    }
  }

  completeTutorial() {
    this.setState({ isFinished: true }, () => {
      this.saveTutorialProject();
      window.removeEventListener("resize", this.onResize);
      this.props.history.push("/");
    });
  }

  saveEditorProject() {
    if (this.state.id !== "sandbox" && this.state.name.length) {
      this.saveProject();
    } else {
      this.exitAndSaveDialog.show();
    }
  }

  saveTutorialProject() {
    this.saveProject();
  }

  goHome() {
    window.removeEventListener("resize", this.onResize);
    if (this.state.name.length) {
      this.saveProject();
      this.props.history.push("/");
    } else {
      this.exitAndSaveDialog.show(true);
    }
  }

  saveNewProject(name, goHome = false) {
    this.removeProject("sandbox");
    const id = uuid.v1();
    this.setState(
      {
        name,
        id,
      },
      () => {
        this.saveProject();
        if (goHome) {
          this.props.history.push("/");
        } else if (this.state.type === "editor") {
          this.props.history.push(`/editor/${id}`);
        }
      }
    );
  }

  saveProject() {
    let xmlText = "";
    let step = 0;
    let savedProject = this.projectService.find(this.state.id);
    if (savedProject) {
      step = savedProject.step;
    }

    if (this.state.tutorialOptions.tutorialStep > step) {
      step = this.state.tutorialOptions.tutorialStep;
    }

    if (workspace) {
      const xml = Blockly.Xml.workspaceToDom(workspace);
      xmlText = Blockly.Xml.domToText(xml);
    }
    const project = {
      mode: this.state.mode,
      id: this.state.id,
      type: this.state.type,
      xmlText,
      python: this.state.python,
      step,
      isFinished: this.state.isFinished,
      updatedDate: new Date().getTime(),
      name: this.state.name,
    };
    this.projectService.updateOrCreate(project);
  }

  removeProjectAndGoHome() {
    dialogs.showDeleteProjectDialogAsync().done((res) => {
      if (res) {
        window.removeEventListener("resize", this.onResize);
        this.removeProject(this.state.id);
        this.props.history.push("/");
      }
    });
  }

  removeProject(id) {
    this.projectService.delete(id);
  }

  getExportedProject() {
    let xmlText = "";
    if (workspace) {
      const xml = Blockly.Xml.workspaceToDom(workspace);
      xmlText = Blockly.Xml.domToText(xml);
    }

    // Get used blocks
    let usedBlocks = [];
    $(xmlText)
      .find("block")
      .each(function () {
        const type = $(this).attr("type");
        if (!usedBlocks.includes(type)) {
          usedBlocks.push(type);
        }
      });

    // Get used extensions blocks
    let usedExtensions = [];
    let usedExtSources = [];
    const extensions = this.extService.all();
    extensions.forEach((ext) => {
      const extXmlDoc = $.parseXML(ext.xmlToolbox);
      $(extXmlDoc)
        .find("block")
        .each(function () {
          const type = $(this).attr("type");
          const { id, src, name, description } = ext;
          if (usedBlocks.includes(type) && !usedExtSources.includes(ext.src)) {
            usedExtensions.push({ id, src, name, description });
          }
        });
    });

    const project = {
      mode: this.state.mode,
      id: this.state.id,
      type: "editor",
      xmlText,
      python: this.state.python,
      step: 0,
      isFinished: this.state.isFinished,
      updatedDate: new Date().getTime(),
      name: this.state.name || "Download" + moment().format("_YYMMDDhhmmss"),
      extensions: usedExtensions,
    };

    return project;
  }

  exportProject() {
    const project = this.getExportedProject();
    core.exportToPc(project, project.name + ".json");
  }

  shareProject = async () => {
    const project = this.getExportedProject();
    const response = await shareProject(project);
    const sharedProject = await response.json();
    console.log(sharedProject);

    const url = `${window.location.origin}/share/${sharedProject.id}`;
    this.setState({ sharedUrl: url });
    this.showSharedProject();
  };

  loadBlocks(xmlText) {
    /* Load blocks to workspace. */
    workspace.clear();
    let xml = "";
    try {
      if (xmlText) {
        xml = Blockly.Xml.textToDom(xmlText);
      } else {
        xml = document.getElementById("workspaceBlocks");
      }
      Blockly.Xml.domToWorkspace(xml, workspace);
    } catch (err) {
      core.errorNotification(err.message);
    } finally {
      window.addEventListener("resize", this.onResize, false);
      this.onResize();
    }
  }

  loadWorkspace(toolbox, xmlText = "") {
    if (xmlText === "") {
      try {
        const projects = this.projectService.all();
        const project = projects.find((p) => p.id === this.state.id);

        if (project) {
          xmlText = project.xmlText;
          if (project.step) {
            this.setTutorialStep(project.step);
          }
        }
      } catch (err) {
        console.error("Load project failed", err);
      }
    }
    // Construct the toolbox XML, replacing translated variable names.
    toolbox = toolbox.replace(/{{\w+}}/g, function (m) {
      return Blockly.Msg[m.replace(/{{|}}/gi, "")];
    });

    const xmlDoc = $.parseXML(toolbox);

    workspace = Blockly.inject("blocksEditor", {
      grid: {
        spacing: 25,
        length: 3,
        colour: "#ccc",
        snap: true,
      },
      toolbox: toolbox,
      zoom: {
        controls: true,
        wheel: false,
      },
    });

    this.loadBlocks(xmlText);

    // Change background color and add icon for category
    $(xmlDoc)
      .find("category")
      .each(function () {
        let name = $(this).attr("name");
        let color = $(this).attr("colour");
        let icon = $(this).attr("icon");

        let label = $(".blocklyTreeLabel").filter(function () {
          return $(this).text() === name;
        });

        let blocklyCat = label.parent().parent();

        if (["advanced", "nâng cao"].includes(name.toLowerCase())) {
          blocklyCat.addClass("toolbox-cat-advanced");
        }

        blocklyCat.css({ "background-color": color, "border-radius": "4px" });
        blocklyCat
          .find(".blocklyTreeIcon")
          .first()
          .append('<i class="' + icon + ' icon"></i>');
      });

    $(".toolbox-cat-advanced")
      .next()
      .click(() => {
        if (this.state.type === "editor") {
          this.showExtensions();
        }
      });
  }

  handleExitAndSaveDialogRef = (c) => {
    this.exitAndSaveDialog = c;
  };

  showRenameProjectDialogAsync() {
    const opts = {
      header: t("Rename your project"),
      agreeLbl: t("Save"),
      agreeClass: "green",
      placeholder: t("Enter your project name here"),
    };
    return core.promptAsync(opts).then((res) => {
      if (res === null) return Promise.resolve(false);

      return new Promise((resolve, reject) => {
        this.setState({ name: res }, () => resolve());
      })
        .then(() => this.saveProject())
        .then(() => true);
    });
  }

  renameProject() {
    this.showRenameProjectDialogAsync();
  }

  showLoading() {
    this.setState({ loading: true });
  }

  hideLoading() {
    this.setState({ loading: false });
  }

  updateFirmware = async function () {
    dialogs.showUpdateFirmwareDialogAsync().done(async (res) => {
      if (res) {
        if (!this.state.connectStatus) {
          this.connectDevice(this.sendFileFirmware);
          return;
        }
        await this.sendFileFirmware();
      }
    });
  };

  sendFileFirmware = async () => {
    console.log("Now updating firmware");
    this.showLoading();
    const baseUrl = this.currentDevice.downloadFirmwareUrl;
    let getList = await fetch(`${baseUrl}list.txt`).then((res) => res.blob());
    let list = await core.fileReadAsTextAsync(getList).then((data) => data);
    let files = JSON.parse(list);

    Promise.all(files.map((f) => fetch(baseUrl + f).then((resp) => resp.blob())))
      .then((blobs) => {
        Promise.all(blobs.map((blob) => core.fileReadAsTextAsync(blob))).then(async (contents) => {
          let failed = false;
          //clear main.py file
          await deviceConnection.sendFile("", "main.py", !contents.length).then(
            () => {
              console.log("Done clearing main.py");
            },
            (error) => {
              failed = true;
              console.error("Failed", error);
            }
          );
          for (var i = 0; i < contents.length; i++) {
            console.log("Updating file " + files[i]);
            await deviceConnection.sendFile(contents[i], files[i], i === contents.length - 1).then(
              () => {
                console.log("Done");
              },
              (error) => {
                failed = true;
                console.error("Failed", error);
                //core.errorNotification(t("There was an error updating the file") + error);
              }
            );
          }
          this.hideLoading();
          if (!failed) core.infoNotification(t("Update firmware successfully"));
          else core.errorNotification(t("Failed to update firmware"));
        });
      })
      .catch((error) => {
        this.hideLoading();
        console.error("There has been a problem with your fetch operation: ", error.message);
      });
  };

  updateExtensionLibs = async function (ext, reload = true) {
    dialogs.showUpdateLibsDialogAsync().done(async (res) => {
      if (res) {
        this.hideExtensions();
        if (!this.state.connectStatus) {
          this.connectDevice(this.updateExtension, ext);
          return;
        }
        await this.updateExtension(ext);
      } else {
        if (reload) {
          core.forceReload();
        }
      }
    });
  };

  updateExtension = async (ext) => {
    console.log("Now updating extension firmware");
    this.showLoading();
    let failed = false;
    let { libs } = ext;
    //clear main.py file
    await deviceConnection.sendFile("", "main.py", !libs.length).then(
      () => {
        console.log("Done clearing main.py");
      },
      (error) => {
        failed = true;
        console.error("Failed", error);
      }
    );

    for (let i = 0; i < libs.length; i++) {
      console.log("Updating file " + libs[i].name);
      await deviceConnection.sendFile(libs[i].content, libs[i].name, i === libs.length - 1).then(
        () => {
          console.log("Done");
        },
        (error) => {
          failed = true;
          console.error("Failed", error);
        }
      );
    }

    this.hideLoading();
    if (!failed) {
      core.infoNotification(t("Update libraries successfully"));
    } else {
      core.errorNotification(t("Failed to update libraries"));
    }
    core.forceReload(3);
  };

  loadExtensions = () => {
    const extensions = this.extService.all();
    if (extensions.length) {
      extensions.forEach((ext) => {
        // load blocks
        ext.blocks.forEach(async (block) => {
          eval(block.content);
        });

        // load language
        const selectedLang = this.getSelectedLang();
        eval(ext.languages[selectedLang]);
      });
    } else {
      console.log("no extensions");
    }
  };

  getSelectedLang() {
    const { i18n } = this.props;
    let selectedLang = i18n.language || "vi";
    selectedLang = selectedLang.substring(0, 2).toLowerCase();

    return selectedLang;
  }

  async componentDidMount() {
    Blockly = window.Blockly;
    this.hideLoading();
    switch (core.getCurrentDevice()) {
      case "yolobit_v1_2":
        deviceConnection = window.webusb;
        break;
      case "yolobit_v3":
      case "nodewifi32":
      case "nodewifi":
      case "esp32_nodemcu":
      case "esp8266_wemos_d1_mini":
        deviceConnection = window.webserial;
        break;
      default:
        deviceConnection = window.webusb;
    }

    if (deviceConnection.port) {
      this.setState({ connectStatus: true });
    }

    let toolbox = getToolbox();
    try {
      const params = this.props.match.params;
      if ("tutorialId" in params) {
        let id = this.props.match.params.tutorialId;
        const tutorial = getTutorial(id);
        this.setState({
          id: id,
          type: "tutorial",
          tutorialOptions: {
            tutorial: tutorial,
            tutorialStep: 0,
          },
          name: tutorial.title,
        });
        toolbox = getToolbox(tutorial.toolbox);
      } else {
        this.loadExtensions();
        let id = "sandbox";
        let project = {};

        if ("sharedId" in params) {
          const sharedId = params.sharedId;
          if (!sharedId) {
            throw new Error("Shared project is empty");
          }
          if (sharedId.length > 27) {
            project = JSON.parse(atob(decodeURIComponent(sharedId)));
          } else {
            const response = await getSharedProject(sharedId);
            const sharedProject = await response.json();
            project = sharedProject.data;
          }
          project.id = "sharedId";

          const extensions = this.extService.all();
          if (project.extensions) {
            let needInstallExts = false;
            const sharedExts = project.extensions.map((ext) => {
              const foundExt = extensions.find((e) => e.id === ext.id);
              if (foundExt && foundExt.installed === true) {
                ext.installed = true;
              } else {
                ext.installed = false;
                needInstallExts = true;
              }
              return ext;
            });
            if (needInstallExts) {
              await this.importSharedExtensions(sharedExts);
              return;
            }
          }
        } else {
          id = this.props.match.params.editorId || "sandbox";
          project = this.projectService.find(id);
        }

        if (project) {
          const mode = project.mode || "block";
          this.setState(
            {
              name: project.name,
              mode,
              python: project.python,
              xmlText: project.xmlText,
            },
            this.saveProject()
          );
          if (mode === "python") {
            isBlockDefault = false;
            $("#blocklyArea").hide();
            $("#pythonArea").show();
          }
        }
        this.setState({ id, type: "editor" });
      }
    } catch (err) {
      console.error("componentDidMount Error", err);
      this.setState({ id: "sandbox", type: "editor" });
    }

    const selectedLang = this.getSelectedLang();

    scriptjs(
      [`/assets/blockly/msg/js/${selectedLang}.js`, `/assets/yolobit_blocks/messages/${selectedLang}/messages.js`],
      () => {
        this.loadWorkspace(toolbox, this.state.xmlText);
      }
    );

    window.onbeforeunload = () => {
      this.saveProject();
    };
  }

  importSharedExtensions = async (sharedExts) => {
    this.showLoading();
    let extLibs = [];
    for (let ext of sharedExts) {
      let githubExt = await extUtils.getGithubExtension(ext.src);
      if (githubExt) {
        githubExt.installed = true;
        this.extService.updateOrCreate(githubExt);
        if (githubExt.libs.length) {
          extLibs = extLibs.concat(githubExt.libs);
        }
      }
    }
    console.log("Project Ext Libs", extLibs);

    if (!extLibs.length) {
      core.forceReload();
      return;
    }

    this.hideLoading();

    dialogs.showUpdateProjectLibsDialogAsync().done(async (res) => {
      if (res) {
        if (!this.state.connectStatus) {
          core.errorNotification(t("yolobitNotConnected"));
          core.forceReload(3);
          return;
        }

        this.showLoading();
        let failed = false;
        //clear main.py file
        await deviceConnection.sendFile("", "main.py", !extLibs.length).then(
          () => {
            console.log("Done clearing main.py");
          },
          (error) => {
            failed = true;
            console.error("Failed", error);
          }
        );

        for (let i = 0; i < extLibs.length; i++) {
          console.log("Updating file " + extLibs[i].name);
          await deviceConnection.sendFile(extLibs[i].content, extLibs[i].name, i === extLibs.length - 1).then(
            () => {
              console.log("Done");
            },
            (error) => {
              failed = true;
              console.error("Failed", error);
            }
          );
        }

        this.hideLoading();
        if (!failed) {
          core.infoNotification(t("Update libraries successfully"));
        } else {
          core.errorNotification(t("Failed to update libraries"));
        }
        core.forceReload(3);
      } else {
        core.forceReload();
      }
    });
  };

  updateInstalledExtLibraries = async () => {
    let extLibs = [];
    const extensions = this.extService.all();
    for (let ext of extensions) {
      if (ext.libs.length) {
        extLibs = extLibs.concat(ext.libs);
      }
    }
    console.log("All Ext Libs", extLibs);
    if (!extLibs.length) {
      core.infoNotification(t("No libraries found"));
      return;
    }

    dialogs.showUpdateInstalledExtLibsDialogAsync().done(async (res) => {
      if (res) {
        if (!this.state.connectStatus) {
          // core.errorNotification(t("yolobitNotConnected"));
          this.connectDevice(this.updateLibraries, extLibs);
          return;
        }
        await this.updateLibraries(extLibs);
      }
    });
  };

  updateLibraries = async (extLibs) => {
    console.log("Now update libraries");
    this.showLoading();
    let failed = false;
    //clear main.py file
    await deviceConnection.sendFile("", "main.py", !extLibs.length).then(
      () => {
        console.log("Done clearing main.py");
      },
      (error) => {
        failed = true;
        console.error("Failed", error);
      }
    );

    for (let i = 0; i < extLibs.length; i++) {
      console.log("Updating file " + extLibs[i].name);
      await deviceConnection.sendFile(extLibs[i].content, extLibs[i].name, i === extLibs.length - 1).then(
        () => {
          console.log("Done");
        },
        (error) => {
          failed = true;
          console.error("Failed", error);
        }
      );
    }

    this.hideLoading();
    if (!failed) {
      core.infoNotification(t("Update libraries successfully"));
    } else {
      core.errorNotification(t("Failed to update libraries"));
    }
  };

  render() {
    let iFrameStyle = {};
    if (browser.isIFrame()) {
      iFrameStyle = { bottom: 0 };
    }
    return (
      <Fragment>
        {this.state.loading && <Loader />}
        {!core.inIframe() && this.state.type === "editor" && <EditorMenu parent={this} />}
        {core.inIframe() && this.state.type === "editor" && <SharedMenu projectName={this.state.name} />}
        {this.state.type === "tutorial" && <TutorialMenu parent={this} />}
        <div id="maineditor" role="main" style={iFrameStyle}>
          <div className="full-abs" id="pythonArea" style={{ display: "none" }}>
            <AceEditor
              placeholder=""
              mode="python"
              theme="twilight"
              name="pythonEditor"
              onLoad={this.onLoad}
              onChange={this.onAceChange}
              fontSize={24}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={this.state.python}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </div>
          <div className="full-abs" id="blocklyArea">
            <div id="blocksEditor" />
          </div>
          <Workspace />
        </div>
        {this.state.type === "tutorial" && <TutorialHint ref="tutorialhint" parent={this} />}
        {!core.inIframe() && (
          <div id="editortools" role="complementary" aria-label="Editor toolbar">
            <EditorToolbar
              ref="editortools"
              parent={this}
              connectStatus={this.state.connectStatus}
              currentDevice={this.currentDevice}
            />
          </div>
        )}
        {this.state.type === "editor" && <ExitAndSaveDialog parent={this} ref={this.handleExitAndSaveDialogRef} />}
        {this.state.type === "editor" && <Extensions ref="extensions" parent={this} />}
        <ShareProject ref="shared-project" sharedUrl={this.state.sharedUrl} />
        {this.state.openDevicePage && (
          <ListDevice
            isOpen={this.state.openDevicePage}
            onClose={this.onCloseDevicePage}
            onSelect={this.onSelectDevice}
          />
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(Editor);
