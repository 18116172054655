module.exports = {
    status : "tutorial",
    id: "up-ngua",
    title: "Úp ngửa",
    detail: "Bài này hướng dẫn dùng cảm biến cử chỉ để phát hiện ra Yolo:Bit đang úp hay ngửa.",
    image: "/assets/tutorials/up-ngua/up-ngua.png",
    categoryIds: ["tutorial"],
    toolbox: [{
            category: "basic",
            blocks: ["yolobit_basic_forever"]
        },
        {
            category: "input",
            blocks: ["yolobit_input_gesture_detected"]
        },
        {
            category: "led",
            blocks: ["yolobit_led_set_all"]
        },
        {
            category: "logic",
            blocks: ["controls_if"]
        },
        {
            category: "music",
            blocks: ["yolobit_music_play_built_in"]
        }
    ],
    steps: [{
            title: "Giới thiệu",
            content: `<div>
        <p>Bài này hướng dẫn dùng cảm biến cử chỉ để phát hiện ra Yolo:Bit đang úp hay ngửa.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/up-ngua/up-ngua.png">
        </div>
      </div>`
        },
        {
            title: "Bước 1",
            content: `<div>
        <p>Kéo khối <block class="logic">nếu ... thực hiện</block> trong mục <block class="logic">LÔGIC</block> ra và gắn vào khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/up-ngua/step1.png">
        </div>
      </div>`
        },
        {
            title: "Bước 2",
            content: `<div>
        <p>Kéo khối <block class="input">trạng thái đang là</block> trong mục <block class="input">NGÕ VÀO</block> ra và gắn vào khối <block>nếu ... thực hiện</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/up-ngua/step2.png">
        </div>
      </div>`
        },
        {
            title: "Bước 3",
            content: `<div>
        <p>Nhấn vào chữ <block class="input">lắc</block> và đổi thành <block class="input">ngửa lên</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/up-ngua/step3.png">
        </div>
      </div>`
        },
        {
            title: "Bước 4",
            content: `<div>
        <p>Kéo khối <block class="led">đổi màu tất cả các led thành màu ...</block> trong mục <block class="led">LED</block> và gắn vào trong khối <block class="logic">nếu ... thực hiện</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/up-ngua/step4.png">
        </div>
      </div>`
        },
        {
            title: "Bước 5",
            content: `<div>
        <p>Làm lại giống như các bước trên cho trạng thái <block class="input">úp xuống</block> và đổi thành màu xanh lá cây:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/up-ngua/step5.png">
        </div>
      </div>`
        },
        {
            title: "Bước 6",
            content: `<div>
        <p>Cuối cùng nhấn vào <b>Chạy</b> để chạy chương trình cho Yolo:Bit và quan sát màu đèn led thay đổi mỗi khi lật Yolo:Bit.</p>
      </div>`
        },
        {
            title: "Bài tập mở rộng",
            content: `<div>
        <p>Khi board nằm úp thì việc quan sát màn hình sẽ gặp khó khăn, hãy thử thay đổi chương trình để nhận biết board đang úp hay ngửa bằng việc phát ra tiếng nhạc.</p>
        <p>Khi board nằm úp, hãy phát nhạc BIRTHDAY. Khi board nằm ngửa thì phát bài WEDDING.</p>
      </div>`
        }
    ]
};