module.exports = {
    status : "tutorial",
    id: "trai-phai",
    title: "Trái phải",
    detail: "Bài hướng dẫn hiện mũi tên qua trái nếu nghiêng Yolo:Bit qua trái và hiển thị mũi tên qua phải nêu nghiêng qua phải.",
    image: "/assets/tutorials/trai-phai/trai-phai.jpg",
    categoryIds: ["tutorial"],
    toolbox: [{
            category: "basic",
            blocks: ["yolobit_basic_forever", "yolobit_basic_show_image", "yolobit_basic_create_image"]
        },
        {
            category: "input",
            blocks: ["yolobit_input_gesture_detected"]
        },
        {
            category: "led",
            blocks: ["yolobit_led_set_all"]
        },
        {
            category: "logic",
            blocks: ["controls_if"]
        }
    ],
    steps: [{
            title: "Giới thiệu",
            content: `<div>
        <p>Bài hướng dẫn hiện mũi tên qua trái nếu nghiêng Yolo:Bit qua trái và hiển thị mũi tên qua phải nêu nghiêng qua phải.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/trai-phai/trai-phai.jpg">
        </div>
      </div>`
        },
        {
            title: "Bước 1",
            content: `<div>
        <p>Kéo khối <block class="logic">nếu ... thực hiện</block> trong mục <block class="logic">LÔGIC</block> ra và gắn vào khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/trai-phai/step1.png">
        </div>
      </div>`
        },
        {
            title: "Bước 2",
            content: `<div>
        <p>Kéo khối <block class="input">trạng thái đang là</block> trong mục <block class="input">NGÕ VÀO</block> ra và gắn vào khối <block>nếu ... thực hiện</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/trai-phai/step2.png">
        </div>
      </div>`
        },
        {
            title: "Bước 3",
            content: `<div>
        <p>Nhấn vào chữ <block class="input">lắc</block> và đổi thành <block class="input">nghiêng qua trái</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/trai-phai/step3.png">
        </div>
      </div>`
        },
        {
            title: "Bước 4",
            content: `<div>
        <p>Kéo khối <block class="basic">hiện hình ảnh</block> trong mục <block class="basic">CƠ BẢN</block> và gắn vào trong khối <block class="logic">nếu ... thực hiện</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/trai-phai/step4.png">
        </div>
      </div>`
        },
        {
            title: "Bước 5",
            content: `<div>
        <p>Vẽ hình mũi tên hướng về bên trái:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/trai-phai/step5.png">
        </div>
      </div>`
        },
        {
            title: "Bước 6",
            content: `<div>
        <p>Làm lại giống như các bước trên cho trạng thái <block class="input">nghiêng bên phải</block> và đổi thành mũi tên hướng qua phải:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/trai-phai/step6.png">
        </div>
      </div>`
        },
        {
            title: "Bước 7",
            content: `<div>
        <p>Cuối cùng nhấn vào <b>Chạy</b> để chạy chương trình cho Yolo:Bit và quan sát hình hiện ra trên đèn led thay đổi mỗi khi nghiêng Yolo:Bit qua 2 bên.</p>
      </div>`
        },
        {
            title: "Bài tập mở rộng",
            content: `<div>
        <p>Hãy làm một bài tập tương tự cho 2 trường hợp board ghiêng tới trước và ra sau nhé. Khi board nghiêng tới trước, hiển thị hình mũi tên hướng lên. Khi board nghiêng ra sau, hiển thị mũi tên hướng xuống.</p>
      </div>`
        }
    ]
};