module.exports = {
  status : "tutorial",
  id: "lac",
  title: "Lắc",
  detail:
    "Bài này hướng dẫn cách phát hiện xem Yolo:Bit có bị lắc không bằng cảm biến cử chỉ. Cứ mỗi lần lắc thì ta sẽ hiện ra một hình ảnh khác nhau.",
  image: "/assets/tutorials/lac/lac.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_forever", "yolobit_basic_show_image", "yolobit_basic_image_builtins"]
    },
    {
      category: "input",
      blocks: ["yolobit_input_gesture_detected"]
    },
    {
      category: "math",
      blocks: ["math_number", "math_random_int"]
    },
    {
      category: "variables",
      blocks: ["variables_set"]
    },
    {
      category: "logic",
      blocks: ["controls_if", "logic_compare"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Bài này hướng dẫn cách phát hiện xem Yolo:Bit có bị lắc không bằng cảm biến cử chỉ. Cứ mỗi lần lắc thì ta sẽ hiện ra một hình ảnh khác nhau.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/lac/lac.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
      <p>Kéo khối <block class="logic">nếu ... thực hiện</block> trong mục <block class="logic">LÔGIC</block> ra và gắn vào khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/lac/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
      <p>Kéo khối <block class="input">trạng thái đang là</block> trong mục <block class="input">NGÕ VÀO</block> ra và gắn vào khối <block>nếu ... thực hiện</block>:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/lac/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Nếu phát hiện cử chỉ lắc, ta sẽ cho Yolo:Bit tự tạo ra một số ngẫu nhiên bất kỳ và lưu vào một biến. Để tạo biến, nhấn vào block <block class="gray">Tạo biến...</block> trong mục <block class="variables">BIẾN</block> và đặt tên là i:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/lac/step3.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Kéo khối <block class="variables">cho i bằng</block> trong mục <block class="variables">BIẾN</block> ra và gắn vào khối <block class="logic">nếu ... thực hiện</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/lac/step4.png">
        </div>
      </div>`
    },
    {
      title: "Bước 5",
      content: `<div>
        <p>Kéo khối <block class="math">một số nguyên bất kỳ từ 1 đến 100</block> trong mục <block class="math">TÍNH TOÁN</block> ra, gắn vào khối <block class="variables">cho i bằng</block>. Nhấn vào số 100 và đổi thành số 3. Lệnh này yêu cầu Yolo:Bit tạo ra 1 số ngẫu nhiên trong khoảng từ 1 đến 3.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/lac/step5.png">
        </div>
      </div>`
    },
    {
      title: "Bước 6",
      content: `<div>
        <p>Kéo khối <block class="logic">nếu ... thực hiện</block> và khối so sánh bằng cùng trong mục <block class="logic">LÔGIC</block> và gắn với nhau.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/lac/step6.png">
        </div>
      </div>`
    },
    {
      title: "Bước 7",
      content: `<div>
        <p>Kéo khối <block class="variables">i</block> trong mục <block class="variables">BIẾN</block> và block <block class="math">0</block> trong mục <block class="math">TÍNH TOÁN</block> gắn vào hai bên của phép so sánh bằng. Đổi số 0 thành số 1.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/lac/step7.png">
        </div>
      </div>`
    },
    {
      title: "Bước 8",
      content: `<div>
        <p>Kéo khối <block class="basic">hiện hình ảnh</block> trong mục <block class="basic">CƠ BẢN</block> và gắn vào như hình.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/lac/step8.png">
        </div>
      </div>`
    },
    {
      title: "Bước 9",
      content: `<div>
        <p>Làm tương tự cho 2 trường hợp số ngẫu nhiên khác là số 2 và số 3 với các hình khác nhau.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/lac/step9.png">
        </div>
      </div>`
    },
    {
      title: "Bước 10",
      content: `<div>
        <p>Cuối cùng nhấn vào <b>Chạy</b> để chạy chương trình cho Yolo:Bit và quan sát kết quả mỗi khi lắc.</p>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
        <p>Hãy mở rộng chương trình để Yolo:Bit hiển thị 6 hình ảnh khác nhau. Mỗi lần lắc sẽ hiển thị 1 hình ngẫu nhiên trong 6 hình đó.</p>
      </div>`
    }
  ]
};
