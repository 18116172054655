import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import { withRouter, Link } from "react-router-dom";
import ProjectCard from "./ProjectCard";
import _ from "lodash";
import Carousel from "./../../components/Carousel";
import { Icon } from "./../../components/Common";
import storeService from "../../services/storeService";

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.projectService = new storeService("projects");
  }
  
  handleProjectClick = (id) => {
    this.props.history.push(`/editor/${id}`);
  };

  getCarouselDOM() {
    let carouselDom = ReactDOM.findDOMNode(this.refs["carousel"]);
    return carouselDom;
  }

  newProject = () => {
    this.projectService.delete("sandbox");
    this.props.history.push(`/editor/`);
  };

  render() {
    const { t } = this.props;
    const { projects, selectedIndex } = this.props;
    let editorProjects = projects.filter((p) => p.type === "editor");
    editorProjects = _.sortBy(editorProjects, ["updatedDate"]).reverse();
    return (
      <div className="ui segment gallerysegment mystuff-segment">
        <div className="ui grid equal width padded heading">
          <div className="column" style={{ width: "20%" }}>
            <h2 className="ui header">{t("My Projects")}</h2>
          </div>
          <div className="column right aligned">
            <button
              onClick={this.props.onImportProject}
              className="ui button icon icon-and-text mini import-dialog-btn"
              title="Import a project"
              tabIndex="0"
            >
              <Icon icon="upload icon-and-text" />
              <span className="ui text landscape only">{t("Import")}</span>
            </button>
          </div>
        </div>
        <div className="content">
          <div>
            <Carousel bleedPercent={20} selectedIndex={selectedIndex} ref="carousel">
              <div className="carouselitem " onClick={this.newProject}>
                <div
                  role="button"
                  className="ui card link newprojectcard"
                  title="Creates a new empty project"
                  tabIndex="0"
                >
                  <div className="content">
                    <Icon icon="huge add circle" />
                    <span className="header">{t("New Project")}</span>
                  </div>
                </div>
              </div>
              {editorProjects.map((project, index) => (
                <ProjectCard
                  project={project}
                  key={project.id}
                  onProjectClick={this.handleProjectClick}
                  index={index}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectList);
