module.exports = {
  status : "tutorial",
  id: "lam-nhac-si",
  title: "Làm nhạc sĩ",
  detail: "Bài này hướng dẫn dùng Yolo:Bit để chơi một bài nhạc từ các nốt nhạc.",
  image: "/assets/tutorials/lam-nhac-si/lam-nhac-si.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_forever"]
    },
    {
      category: "music",
      blocks: ["yolobit_music_play_note", "yolobit_music_rest", "yolobit_music_set_tempo"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
      <p>Bài này hướng dẫn dùng Yolo:Bit để chơi một bài nhạc từ các nốt nhạc.</p>
      <div class="ui segment raised">
        <img src="/assets/tutorials/lam-nhac-si/lam-nhac-si.jpg">
      </div>
    </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
    <p>Đầu tiên, gắn block <block class="music">chỉnh tốc độ phát nhạc: 1 nhịp là ... tick, 1 phút là ... nhịp</block> trong mục <block class="music">ÂM NHẠC</block> vào khối <block>bắt đầu</block>. Block này giúp điều chỉnh tốc đố phát nhạc:</p>
    <div class="ui segment raised">
      <img src="/assets/tutorials/lam-nhac-si/step1.png">
    </div>
  </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
      <p>Sửa 120 thành 50. Đây là tốc độ phát nhạc vừa phải. Điều chỉnh số này càng lớn thì tốc độ phát nhạc sẽ càng nhanh.</p>
      <div class="ui segment raised">
        <img src="/assets/tutorials/lam-nhac-si/step2.png">
      </div>
    </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
    <p>Để chơi một nốt nhạc, ta dùng khối <block class="music">phát ra nốt nhạc ... với độ dài ...</block> trong mục <block class="music">ÂM NHẠC</block>:</p>
    <div class="ui segment raised">
      <img src="/assets/tutorials/lam-nhac-si/step3.png">
    </div>
  </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
    <p>Thay đổi nốt nhạc bằng cách nhấp vào <block class="music">C5</block> và chọn nốt nhạc khác mà bạn muốn:</p>
    <div class="ui segment raised">
    <img src="/assets/tutorials/lam-nhac-si/step4.png">
    </div>
  </div>`
    },
    {
      title: "Bước 5",
      content: `<div>
  <p>Thay đổi trường độ của nốt bằng cách nhấp vào hình nốt nhac và chọn loại nốt:</p>
  <div class="ui segment raised">
  <img src="/assets/tutorials/lam-nhac-si/step5.png">
  </div>
</div>`
    },
    {
      title: "Bước 6",
      content: `<div>
      <p>Dùng thêm block <block class="music">tạm dừng ...</block> nếu cần nhé:</p>
      <div class="ui segment raised">
      <img src="/assets/tutorials/lam-nhac-si/step6.png">
      </div>
    </div>`
    },
    {
      title: "Bước 7",
      content: `<div>
    <p>Bạn cũng có thể thay đổi thời gian tạm dừng bằng cách nhấp chuột và chọn như hình:</p>
    <div class="ui segment raised">
    <img src="/assets/tutorials/lam-nhac-si/step7.png">
    </div>
  </div>`
    },
    {
      title: "Bước 8",
      content: `<div>
    <p>Cuối cùng kết nối board rồi chạy chương trình và nghe thử âm thanh phát ra từ Yolo:Bit.</p>
    </div>`
    },
    {
      title: "Bài tập mở rộng 1",
      content: `<div>
          <p>Cho Yolo:Bit thực hiện đoạn chương trình sau. Lắng nghe giai điệu được phát ra và đoán xem giai điệu đó của bài hát nào nhé.</p>
          <div class="ui segment raised">
          <img src="/assets/tutorials/lam-nhac-si/BT1.png">
          </div>
    </div>`
    },
    {
      title: "Bài tập mở rộng 2",
      content: `<div>
          <p>Với cách làm tương tự, bạn có thể tạo bất kì giai điệu nào mà bạn muốn. Hãy thử tạo ra vài giai điệu vui nhộn khác nhé.</p>
    </div>`
    }
  ]
};
