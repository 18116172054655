import React, { Component } from "react";
import { Modal, Icon, Input, Field } from "./Common";
import { t } from "../utils/core";
import { popupWindow } from "../utils/browser";
export class ShareProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  show() {
    this.setState({ visible: true });
  }

  hide = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const url = this.props.sharedUrl;
    const embed = `<div style="position:relative;height:calc(300px + 5em);width:100%;overflow:hidden;"><iframe style="position:absolute;top:0;left:0;width:100%;height:100%;" src="${url}" allowfullscreen="allowfullscreen" frameborder="0" sandbox="allow-scripts allow-same-origin"></iframe></div>`;
    return (
      <Modal
        isOpen={visible}
        className="sharedialog"
        onClose={this.hide}
        header={t("Share Project")}
        closeIcon={true}
        closeOnDocumentClick
        closeOnEscape
      >
        <div className={`ui form`}>
          <div>
            <p>{t("Your project is ready! Use the address below to share your projects")}</p>
            <Input
              id="projectUri"
              class="mini"
              readOnly={true}
              lines={1}
              value={url}
              copy={true}
              selectOnClick={true}
              aria-describedby="projectUriLabel"
              autoComplete={false}
            />
            { false && <div className="social-icons">
              <SocialButton url={url} ariaLabel="Facebook" type="facebook f" heading={t("Share on Facebook")} />
              <SocialButton url={url} ariaLabel="Twitter" type="twitter" heading={t("Share on Twitter")} />
            </div> }
          </div>
          <div>
            <div className="ui divider"></div>
            <p>{t("Embed")}</p>
            <Field>
              <Input
                id="embedCode"
                class="mini"
                readOnly={true}
                lines={4}
                value={embed}
                copy={true}
                selectOnClick={true}
                autoComplete={false}
              />
            </Field>
          </div>
        </div>
      </Modal>
    );
  }
}

class SocialButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = e => {
    const { type, url: shareUrl, heading } = this.props;
    let url = "";
    switch (type) {
      case "facebook": {
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      }
      case "twitter": {
        let twitterText = t("Check out what I made with") + " @yolostem!";
        url =
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}` +
          `&text=${encodeURIComponent(twitterText)}`;
        break;
      }
      default:
        break;
    }
    popupWindow(url, heading, 600, 600);
    e.preventDefault();
  };

  render() {
    const { type, label, ariaLabel, icon } = this.props;
    return (
      <a
        href="#"
        role="button"
        className={`ui button large ${label ? "labeled" : ""} icon ${type}`}
        tabIndex={0}
        aria-label={ariaLabel}
        onClick={this.handleClick}
      >
        <Icon icon={icon || type} />
        {label}
      </a>
    );
  }
}
