export default `<xml id="toolbox">
<category id="basic" name="{{YOLOBIT_CAT_BASIC}}" colour="#1e90ff" icon="th">
  <block type="esp32_basic_sleep">
    <value name="duration">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
  </block>
  <block type="esp32_basic_timer"><value name="input"><shadow type="math_number"><field name="NUM">1</field></shadow></value></block>
  <block type="yolobit_basic_forever" />
</category>
<category id="input" name="{{YOLOBIT_CAT_INPUT}}" colour="#d400d4" icon="sign-in">
  <block type="esp32_input_button_init" />
  <block type="esp32_input_button_is_pressed" />
  <block type="esp32_input_pin_touched" />
  <block type="esp32_input_read_analog" />
  <block type="esp32_input_read_digital" />
  <block type="esp32_input_on_button_pressed" />
  <block type="esp32_input_running_time" />
</category>
<category id="music" name="{{YOLOBIT_CAT_MUSIC}}" colour="#e63022" icon="music">
  <block type="esp32_music_init" />
  <block type="esp32_music_play_built_in" />
  <block type="esp32_music_note">
    <field name="PITCH">7</field>
  </block>
  <block type="esp32_music_play_note">
    <field name="DURATION">1</field>
    <value name="PITCH">
      <shadow type="esp32_music_note">
        <field name="PITCH">7</field>
      </shadow>
    </value>
  </block>
  <block type="esp32_music_rest">
    <field name="DURATION">1</field>
  </block>
  <block type="esp32_music_stop" />
  <block type="esp32_music_pitch">
    <value name="pitch">
      <shadow type="math_number">
        <field name="NUM">440</field>
      </shadow>
    </value>
    <value name="duration">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
  </block>
  <block type="esp32_music_set_tempo">
    <value name="ticks">
      <shadow type="math_number">
        <field name="NUM">4</field>
      </shadow>
    </value>
    <value name="bpm">
      <shadow type="math_number">
        <field name="NUM">120</field>
      </shadow>
    </value>
  </block>
  <block type="esp32_music_reset" />
  <block type="esp32_music_get_tempo" />
</category>
<category id="led" name="{{YOLOBIT_CAT_LED}}" colour="#5d2c91" icon="lightbulb">
  <block type="esp32_led_init" />
  <block type="esp32_led_set_pos">
    <value name="POS">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
    <value name="COLOR">
      <shadow type="colour_picker">
        <field name="COLOUR">#ff0000</field>
      </shadow>
    </value>
  </block>
  <block type="esp32_led_clear_pos">
    <value name="POS">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="esp32_led_set_all">
    <value name="COLOR">
      <shadow type="colour_picker">
        <field name="COLOUR">#ff0000</field>
      </shadow>
    </value>
  </block>
  <block type="esp32_led_colour_rgb">
    <value name="RED">
      <shadow type="math_number">
        <field name="NUM">50</field>
      </shadow>
    </value>
    <value name="GREEN">
      <shadow type="math_number">
        <field name="NUM">50</field>
      </shadow>
    </value>
    <value name="BLUE">
      <shadow type="math_number">
        <field name="NUM">50</field>
      </shadow>
    </value>
  </block>
</category>
<category id="wifi" name="{{YOLOBIT_CAT_WIFI}}" colour="#e3008c" icon="wifi">
  <block type="esp32_wifi_connect"><value name="WIFI"><shadow type="text"><field name="TEXT">wifi</field></shadow></value><value name="PASSWORD"><shadow type="text"><field name="TEXT">password</field></shadow></value><value name="NAME"><shadow type="text"><field name="TEXT">my_esp32_123</field></shadow></value></block>
  <block type="esp32_wifi_check_message"></block>
  <block type="esp32_wifi_send_message"><value name="MESSAGE"><shadow type="text"><field name="TEXT">Hello</field></shadow></value><value name="NAME"><shadow type="text"><field name="TEXT">esp32_456</field></shadow></value></block>
  <block type="esp32_wifi_on_receive_message"></block>
  <block type="esp32_wifi_log_data"><value name="DATA"><shadow type="text"><field name="TEXT">data</field></shadow></value></block>
  <block type="esp32_wifi_on_receive_message_from_dashboard"></block>
</category>
<category id="loops" name="{{YOLOBIT_CAT_LOOPS}}" colour="#42bf42" icon="sync">
  <block type="controls_repeat_ext">
    <value name="TIMES">
      <shadow type="math_number">
        <field name="NUM">10</field>
      </shadow>
    </value>
  </block>
  <block type="controls_whileUntil" />
  <block type="controls_for">
    <value name="FROM">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="math_number">
        <field name="NUM">10</field>
      </shadow>
    </value>
    <value name="BY">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="controls_forEach" />
  <block type="controls_flow_statements" />
</category>
<category id="logic" name="{{YOLOBIT_CAT_LOGIC}}" colour="#CC5500" icon="random">
  <block type="controls_if" />
  <block type="controls_if">
    <mutation else="1" />
  </block>
  <block type="logic_compare" />
  <block type="logic_operation" />
  <block type="logic_negate" />
  <block type="logic_boolean" />
</category>
<category id="variables" name="{{YOLOBIT_CAT_VARIABLES}}" colour="#FFa500" icon="save" custom="VARIABLE" />
<category id="math" name="{{YOLOBIT_CAT_MATH}}" colour="#4257bf" icon="calculator">
  <block type="math_number" gap="32" />
  <block type="math_tonum"> </block>
  <block type="math_arithmetic">
    <value name="A">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
    <value name="B">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="math_single">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">9</field>
      </shadow>
    </value>
  </block>
  <block type="math_trig">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">45</field>
      </shadow>
    </value>
  </block>
  <block type="math_constant" />
  <block type="math_number_property">
    <value name="NUMBER_TO_CHECK">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="math_round">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">3.1</field>
      </shadow>
    </value>
  </block>
  <block type="math_on_list" />
  <block type="math_modulo">
    <value name="DIVIDEND">
      <shadow type="math_number">
        <field name="NUM">64</field>
      </shadow>
    </value>
    <value name="DIVISOR">
      <shadow type="math_number">
        <field name="NUM">10</field>
      </shadow>
    </value>
  </block>
  <block type="math_constrain">
    <value name="VALUE">
      <shadow type="math_number">
        <field name="NUM">50</field>
      </shadow>
    </value>
    <value name="LOW">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
    <value name="HIGH">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
  <block type="math_random_int">
    <value name="FROM">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
  <block type="math_random_float" />
  <block type="esp32_math_map"><value name="VALUE"><shadow type="math_number"><field name="NUM">0</field></shadow></value><value name="FROM_LOW"><shadow type="math_number"><field name="NUM">0</field></shadow></value><value name="FROM_HIGH"><shadow type="math_number"><field name="NUM">1023</field></shadow></value><value name="TO_LOW"><shadow type="math_number"><field name="NUM">0</field></shadow></value><value name="TO_HIGH"><shadow type="math_number"><field name="NUM">100</field></shadow></value></block>
</category>
<sep />
<category id="advanced" name="{{YOLOBIT_CAT_ADVANCED}}" colour="#675ba5" icon="angle down">
  <category id="functions" name="{{YOLOBIT_CAT_FUNCTIONS}}" colour="#aa42bf" custom="PROCEDURE" icon="cog" />
  <category id="lists" name="{{YOLOBIT_CAT_LISTS}}" colour="#6c42bf" icon="list">
    <block type="lists_create_with">
      <mutation items="0" />
    </block>
    <block type="lists_create_with" />
    <block type="lists_repeat">
      <value name="NUM">
        <shadow type="math_number">
          <field name="NUM">5</field>
        </shadow>
      </value>
    </block>
    <block type="lists_length" />
    <block type="lists_isEmpty" />
    <block type="lists_indexOf">
      <value name="VALUE">
        <block type="variables_get">
          <field name="VAR">list</field>
        </block>
      </value>
    </block>
    <block type="lists_getIndex">
      <value name="VALUE">
        <block type="variables_get">
          <field name="VAR">list</field>
        </block>
      </value>
    </block>
    <block type="lists_setIndex">
      <value name="LIST">
        <block type="variables_get">
          <field name="VAR">list</field>
        </block>
      </value>
    </block>
    <block type="lists_getSublist">
      <value name="LIST">
        <block type="variables_get">
          <field name="VAR">list</field>
        </block>
      </value>
    </block>
    <block type="lists_split">
      <value name="DELIM">
        <shadow type="text">
          <field name="TEXT">,</field>
        </shadow>
      </value>
    </block>
    <block type="lists_sort" />
  </category>
  <category id="text" name="{{YOLOBIT_CAT_TEXT}}" colour="#42bf95" icon="font">
    <block type="text" />
    <block type="text_join" />
    <block type="text_append">
      <value name="TEXT">
        <shadow type="text" />
      </value>
    </block>
    <block type="text_length">
      <value name="VALUE">
        <shadow type="text">
          <field name="TEXT">abc</field>
        </shadow>
      </value>
    </block>
    <block type="text_isEmpty">
      <value name="VALUE">
        <shadow type="text">
          <field name="TEXT" />
        </shadow>
      </value>
    </block>
    <block type="text_indexOf">
      <value name="VALUE">
        <block type="variables_get">
          <field name="VAR">text</field>
        </block>
      </value>
      <value name="FIND">
        <shadow type="text">
          <field name="TEXT">abc</field>
        </shadow>
      </value>
    </block>
    <block type="text_charAt">
      <value name="VALUE">
        <block type="variables_get">
          <field name="VAR">text</field>
        </block>
      </value>
    </block>
    <block type="text_getSubstring">
      <value name="STRING">
        <block type="variables_get">
          <field name="VAR">text</field>
        </block>
      </value>
    </block>
    <block type="text_changeCase">
      <value name="TEXT">
        <shadow type="text">
          <field name="TEXT">abc</field>
        </shadow>
      </value>
    </block>
    <block type="text_trim">
      <value name="TEXT">
        <shadow type="text">
          <field name="TEXT">abc</field>
        </shadow>
      </value>
    </block>
    <block type="text_print">
      <value name="TEXT">
        <shadow type="text">
          <field name="TEXT">abc</field>
        </shadow>
      </value>
    </block>
  </category>
  <category id="pins" name="{{YOLOBIT_CAT_PINS}}" colour="#b22222" icon="microchip">
    <block type="esp32_pin_touched" />
    <block type="esp32_pin_read_analog" />
    <block type="esp32_pin_write_analog">
      <value name="output">
        <shadow type="math_number">
          <field name="NUM">0</field>
        </shadow>
      </value>
    </block>
    <block type="esp32_pin_read_digital" />
    <block type="esp32_pin_write_digital">
      <value name="output">
        <shadow type="math_number">
          <field name="NUM">0</field>
        </shadow>
        <block type="esp32_pin_onoff_value">
          <field name="value">1</field>
        </block>
      </value>
    </block>
    <block type="esp32_pin_set_pull" />
    <block type="esp32_pin_onoff_value" />
  </category>
  
  <category id="serial" name="{{YOLOBIT_CAT_SERIAL}}" colour="#002050" icon="usb">
  <block type="yolobit_serial_write_line">
    <value name="line">
      <shadow type="text">
        <field name="TEXT"> </field>
      </shadow>
    </value>
  </block>
  <block type="yolobit_serial_write_number">
    <value name="number">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="yolobit_serial_write_value">
    <value name="name">
      <shadow type="text">
        <field name="TEXT">x</field>
      </shadow>
    </value> 
    <value name="value">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="yolobit_serial_write_string">
    <value name="string">
      <shadow type="text">
        <field name="TEXT"> </field>
      </shadow>
    </value>
  </block>
  <block type="yolobit_serial_write_timer">
    <value name="print">
      <shadow type="text">
        <field name="TEXT">abc</field>
      </shadow>
    </value> 
    <value name="time">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
</category>
</category>
<category id="extensions" name="{{YOLOBIT_CAT_EXTENSIONS}}" colour="#717171" icon="plus circle">
</category>
</xml>`;
