
const categories = [
    { id: "tutorial", name: "Tutorial" },
    //{ id: "man-hinh-led", name: "MÀN HÌNH LED" },
   // { id: "tutorial", name: "NÚT NHẤN" },
  //  { id: "am-nhac", name: "ÂM NHẠC" },
  //  { id: "cam-bien", name: "CẢM BIẾN" },
   // { id: "chuyen-dong", name: "CHUYỂN ĐỘNG" },
  //  { id: "input-output", name: "INPUT/OUTPUT" }
  { id: "courses", name: "Courses" },
  { id: "games", name:"Games" },
  { id: "creation", name: "Creation" },
  { id: "hardware", name: "Hardware" }
];

export function getCategories() {
    return categories;
}