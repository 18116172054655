module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/lap-trinh-tro-choi-voi-yolobit/lessons/gioi-thieu-6/",
    title: "Rắn săn mồi",
    detail:
      'Đây là một trò chơi cực kì nổi tiếng hồi xưa và rất quen thuộc với những ai đã từng dùng qua các điện thoại của hãng Nokia. Trong trò chơi này, người chơi sẽ điều khiển con rắn sao cho ăn được hết các con mồi trên màn hình và không để con rắn đụng vào các cạnh của màn hình led.',
    image: "/assets/tutorials/courses/ransanmoi.jpg",
    categoryIds: ["games"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  