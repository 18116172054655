module.exports = {
  status : "tutorial",
  id: "cam-bien-cham",
  title: "Cảm biến chạm",
  detail:
    "Trong phần này chúng ta sẽ tìm hiểu cách lập trình cho các chân cắm mở rộng của Yolo:Bit. Các chân cắm này có thể được sử dụng làm input hoặc output, giúp vi điều khiển giao tiếp với các thiết bị bên ngoài.",
  image: "/assets/tutorials/cam-bien-cham/cam-bien-cham.png",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_show_image", "yolobit_basic_image_builtins", "yolobit_basic_forever"]
    },
    {
      category: "led",
      blocks: ["yolobit_led_set_all"]
    },
    {
      category: "logic",
      blocks: ["controls_if"]
    },
    {
      category: "advanced",
      blocks: ["yolobit_pin_touched"]
    },
    {
      category: "pins",
      blocks: ["yolobit_pin_touched"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
      <p>Bài này hướng dẫn cách kiểm tra chân cắm mở rộng P0 có được chạm vào hay không để bật tắt màn hình led.</p>
      <div class="ui segment raised">
        <img src="/assets/tutorials/cam-bien-cham/pin-P0.png">
      </div>
    </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
      <p>Kéo khối <block class="logic">nếu...thực hiện… nếu không</block> trong mục LÔGIC và gắn vào khối <block class="basic">lặp lại mãi</block>:</p>
      <div class="ui segment raised">
        <img src="/assets/tutorials/cam-bien-cham/step1.png">
      </div>
    </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
<p>Để sử dụng pin, trong mục <block class="advanced">NÂNG CAO</block> chọn mục <block class="pins">CHÂN CẮM</block>:</p>
<div class="ui segment raised">
  <img src="/assets/tutorials/cam-bien-cham/step2.png">
</div>
</div>`
    },
    {
      title: "Bước 3",
      content: `<div>
      <p>Gắn khối <block class="pins">chân P0 được chạm vào</block> vào <block class="logic">nếu ...</block> để kiểm tra chân P0 có được chạm hay không:</p>
      <div class="ui segment raised">
        <img src="/assets/tutorials/cam-bien-cham/step3.png">
      </div>
    </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
      <p>Khi điều kiện đúng, thực hiện <block class="led">đổi màu tất cả các led thành màu đỏ</block>:</p>
      <div class="ui segment raised">
        <img src="/assets/tutorials/cam-bien-cham/step4.png">
      </div>
    </div>`
    },
    {
      title: "Bước 5",
      content: `<div>
      <p>Ngược lại thì tắt led bằng khối <block class="led">đổi màu tất cả các led thành màu đen</block>:</p>
      <div class="ui segment raised">
        <img src="/assets/tutorials/cam-bien-cham/step5.png">
      </div>
    </div>`
    },
    {
      title: "Bước 6",
      content: `<div>
      <p>Cuối cùng hãy kết nối Yolo:Bit với máy tính rồi <b>Chạy</b> chương trình, thử chạm tay vào pin P0 và quan sát kết quả.</p>
      <div class="ui segment raised">
          <img src="/assets/tutorials/cam-bien-cham/pin-P0.png">
        </div>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
      <p>Hãy lập trình chương trình điều khiển màn hình led như sau: Khi chạm vào pin P0 màn hình hiển thị mặt cười, chạm vào pin P1 thì màn hình hiển thị mặt mếu.</p>
    
      </div>`
    }
  ]
};
