import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import JSZip from "jszip";
import * as core from "../utils/core";
import { Modal } from "./Common";
import { SearchInput } from "./SearchInput";
import ExtensionCard from "./ExtensionCard";
import { getExtensions, searchExtensions } from "../services/extensionService";
import { t } from "../utils/core";
import Loader from "./Loader";
import { Icon } from "./Common";
import * as dialogs from "../utils/dialogs";
import * as extUtils from "../utils/extUtils";
import storeService from "../services/storeService";

const initState = {
  visible: false,
  searchFor: "",
  isEmpty: false,
  extensions: getExtensions(),
  needReload: false,
  loading: false,
};

export class Extensions extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
    this.extService = new storeService("extensions");
  }

  show() {
    this.setState({ visible: true, searchFor: "", extensions: getExtensions() });
  }

  hide = () => {
    this.setState(initState);
  };

  hideAndReload = () => {
    if (this.state.needReload) {
      core.forceReload();
    }
    this.hide();
  };

  showLoading() {
    this.setState({ loading: true });
  }

  hideLoading() {
    this.setState({ loading: false });
  }

  componentDidUpdate() {
    const searchInput = ReactDOM.findDOMNode(this.refs["searchInput"]);
    if (searchInput) {
      searchInput.focus();
    }
  }

  handleSearch = async (str) => {
    this.setState({ searchFor: str });
    if (/^(github:|https:\/\/github.com\/)/.test(str)) {
      this.showLoading();
      const ext = await extUtils.getGithubExtension(str);
      this.hideLoading();
      if (ext) {
        this.setState({ extensions: [ext], isEmpty: false });
      } else {
        this.setState({ isEmpty: true, extensions: [] });
      }
    } else {
      const extensions = searchExtensions(str);
      if (extensions.length) {
        this.setState({ extensions, isEmpty: false });
      } else {
        this.setState({ isEmpty: true, extensions: [] });
      }
    }
  };

  importExtension = async (src, id) => {
    let ext = {};
    if (src.length) {
      this.showLoading();
      ext = await extUtils.getGithubExtension(src);
      this.hideLoading();
      if (!ext) return;
      this.hide();
      this.saveExtension(ext);
    } else {
      let extensions = this.extService.all();
      ext = extensions.find((e) => e.id === id);
    }
    this.updateExtensionLibs(ext);
  };

  updateExtensionLibs = (ext, reload = true) => {
    if (ext.libs && ext.libs.length) {
      this.setState({ needReload: false });
      this.props.parent.updateExtensionLibs(ext, reload);
    } else {
      core.forceReload();
    }
  };

  removeExtension = async (event, id) => {
    event.stopPropagation();
    this.extService.delete(id);
    this.setState({ extensions: getExtensions() });

    this.setState({ needReload: true });
  };

  saveExtension(ext) {
    ext.installed = true;
    const extensions = this.extService.updateOrCreate(ext);
    this.setState({ extensions, needReload: true });
  }

  getZipBlocks = (ext, newZip, basePath) => {
    return new Promise((resolve, reject) => {
      let files = ext.blocks;
      let blocks = [];
      files.forEach(async (file) => {
        blocks.push({ name: file, content: await newZip.file(basePath + file).async("string") });
      });

      resolve(blocks);
    });
  };

  getZipLanguages = (ext, newZip, basePath) => {
    return new Promise((resolve, reject) => {
      let languages = {};
      Object.keys(ext.languages).forEach(async (lang) => {
        languages[lang] = await newZip.file(basePath + ext.languages[lang]).async("string");
      });

      resolve(languages);
    });
  };

  getZipLibs = (ext, newZip, basePath) => {
    return new Promise((resolve, reject) => {
      let files = ext.libs;
      let libs = [];
      files.forEach(async (file) => {
        libs.push({ name: file, content: await newZip.file(basePath + file).async("string") });
      });

      resolve(libs);
    });
  };

  handleImportZipExtension = () => {
    dialogs.showImportExtensionDialogAsync().done((res) => {
      if (res) {
        core.fileReadAsBufferAsync(res).then(async (content) => {
          try {
            let newZip = new JSZip();
            const zip = await newZip.loadAsync(content);
            let config = newZip.file(/config.json/)[0];
            if (!config) {
              console.error("The config.json file not found");
              return;
            }
            const basePath = config.name.replace("config.json", "");
            config = await config.async("string");
            let ext = JSON.parse(config);
            const extToolbox = await newZip.file(basePath + ext.toolbox).async("string");
            ext.xmlToolbox = extToolbox;
            ext.src = "";
            ext.id = basePath;
            const posterPromise = newZip.file(basePath + ext.poster).async("base64");
            const blocksPromise = this.getZipBlocks(ext, newZip, basePath);
            const libsPromise = this.getZipLibs(ext, newZip, basePath);
            const languagesPromise = this.getZipLanguages(ext, newZip, basePath);
            ext.poster = "data:image/png;base64," + (await posterPromise);
            ext.blocks = await blocksPromise;
            ext.libs = await libsPromise;
            ext.languages = await languagesPromise;

            this.saveExtension(ext);
            this.updateExtensionLibs(ext, false);
          } catch (error) {
            console.error("Import failed", error.message);
          }
        });
      }
    });
  };

  render() {
    const { visible } = this.state;
    const header = t("Extensions");

    return (
      <Modal
        isOpen={visible}
        className="searchdialog"
        size="fullscreen"
        closeIcon={true}
        header={header}
        onClose={this.hideAndReload}
        closeOnDimmerClick
        closeOnEscape
      >
        <div className="ui">
          {this.state.loading && <Loader />}
          <div className="ui grid equal width padded heading">
            <SearchInput
              key="search"
              placeholder={t("Search or enter project URL")}
              searchHandler={this.handleSearch}
              searchOnChange={false}
              inputClassName="fluid"
              autoFocus={true}
            />
            <div className="right aligned">
              <button
                onClick={this.handleImportZipExtension}
                className="ui button icon icon-and-text mini import-dialog-btn"
                title="Import an extension"
                tabIndex="0"
              >
                <Icon icon="upload icon-and-text" />
                <span className="ui text landscape only">{t("Upload zip file")}</span>
              </button>
            </div>
          </div>
          <div className="ui cards centered" role="listbox">
            {this.state.extensions.map((extension, index) => (
              <ExtensionCard key={index} extension={extension} parent={this} />
            ))}
          </div>
          {this.state.isEmpty ? (
            <div className="ui items">
              <div className="ui item">{t("ExtensionNotFound") + this.state.searchFor}</div>
            </div>
          ) : undefined}
        </div>
      </Modal>
    );
  }
}
