import React from "react";
import * as core from "./core";
import { t } from "./core";
import ChartSerial from "../pages/editor/ChartSerial";

export function showImportFileDialogAsync() {
  let input;

  return core
    .confirmAsync({
      header: t("ImportJson"),
      onLoaded: el => {
        input = el.querySelectorAll("input")[0];
      },
      jsx: (
        <div className="ui form">
          <div className="ui field">
            <label id="selectFileToOpenLabel">{t("SelectJson")}</label>
            <input
              type="file"
              accept=".json"
              tabIndex={0}
              autoFocus
              aria-describedby="selectFileToOpenLabel"
              className="ui blue fluid"
            />
          </div>
        </div>
      )
    })
    .then(res => {
      if (res) {
        return input.files[0];
      }
      return undefined;
    });
}

export function showImportExtensionDialogAsync() {
  let input;

  return core
    .confirmAsync({
      header: t("ImportExtension"),
      onLoaded: el => {
        input = el.querySelectorAll("input")[0];
      },
      jsx: (
        <div className="ui form">
          <div className="ui field">
            <label id="selectFileToOpenLabel">{t("SelectZip")}</label>
            <input
              type="file"
              accept=".zip"
              tabIndex={0}
              autoFocus
              aria-describedby="selectFileToOpenLabel"
              className="ui blue fluid"
            />
          </div>
        </div>
      )
    })
    .then(res => {
      if (res) {
        return input.files[0];
      }
      return undefined;
    });
}

export function showConvertToBlocksDialogAsync() {
  return core
    .confirmAsync({
      header: t("errorConvertModeTitle"),
      agreeLbl: t("Blocks"),
      agreeClass: "negative",
      disagreeLbl: t("Python"),
      jsx: <div className="ui form">{t("errorConvertModeMessage")}</div>
    })
    .then(res => {
      return res;
    });
}

export function showDeleteProjectDialogAsync() {
  return core
    .confirmAsync({
      header: t("Delete project"),
      agreeLbl: t("Delete"),
      agreeClass: "negative",
      disagreeLbl: t("Cancel"),
      jsx: <div className="ui form">{t("confirmDeleteProject")}</div>
    })
    .then(res => {
      return res;
    });
}

export function showUpdateFirmwareDialogAsync() {
  return core
    .confirmAsync({
      header: t("Update Firmware"),
      agreeLbl: t("Update"),
      disagreeLbl: t("Cancel"),
      jsx: <div className="ui form">{t("confirmUpdateFirmware")}</div>
    })
    .then(res => {
      return res;
    });
}

export function showUpdateLibsDialogAsync(content = "") {
  return core
    .confirmAsync({
      header: t("Update Libraries"),
      agreeLbl: t("Update Libraries"),
      disagreeLbl: t("Skip"),
      jsx: <div className="ui form">{t("You need to update the libraries of the extension")}</div>
    })
    .then(res => {
      return res;
    });
}

export function showUpdateProjectLibsDialogAsync(exts) {
  return core
    .confirmAsync({
      header: t("Update Libraries"),
      agreeLbl: t("Update Libraries"),
      disagreeLbl: t("Skip"),
      jsx: <div className="ui form">{t("You need to update the libraries of the project")}</div>
    })
    .then(res => {
      return res;
    });
}

export function showUpdateInstalledExtLibsDialogAsync() {
  return core
    .confirmAsync({
      header: t("Update Libraries"),
      agreeLbl: t("Update Libraries"),
      disagreeLbl: t("Skip"),
      jsx: <div className="ui form">{t("confirmUpdateExtLibraries")}</div>
    })
    .then(res => {
      return res;
    });
}

export function showSerialDialogAsync(serial) {
  return core
    .confirmAsync({
      header: "Serial",
      agreeLbl: t("Clear"),
      agreeClass: "red",
      agreeIcon: "trash",
      disagreeLbl: t("Close"),
      onLoaded: el => {
        const serialArea = document.getElementById("serial");
        serialArea.scrollTop = serialArea.scrollHeight;
      },
      jsx: (
        <div className="ui form">
          <div className="ui field">
            <textarea id="serial" readOnly value={serial} />
          </div>
        </div>
      )
    })
    .then(res => {
      if (res) {
        return Promise.resolve(res);
      }
      // Close
      return Promise.resolve(undefined);
    });
}

export function showChartSerialDialogAsync(getRefChartSerial) {
  return core
    .confirmAsync({
      header: t("Chart Serial"),
      agreeLbl: t("Download"),
      agreeIcon: "download",
      disagreeLbl: t("Close"),
      jsx: <ChartSerial ref={getRefChartSerial} />,
    })
    .then((res) => {
      return res;
    });
}
