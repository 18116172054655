module.exports = {
  status:"hardware",
    //id: "https://ohstem.vn/knowledge-base/armbit/",
    id:"https://ohshop.vn/product/armbit-canh-tay-robot/",
    title: "Arm:Bit",
    detail:
      'Arm:Bit bao gồm 1 cánh tay rô bốt cùng tay cầm điều khiển bằng Yolo:Bit giúp bạn thỏa sức sáng tạo.',
    image: "/assets/tutorials/courses/arm.jpg",
    categoryIds: ["hardware"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  