module.exports = {
    status : "tutorial",
    id: "nhan-nhan-nhan",
    title: "Nhấn, nhấn, nhấn",
    detail: "Bài này hướng dẫn cách đọc số lần nhấn nút sau mỗi 3 giây và hiện lên màn hình led.",
    image: "/assets/tutorials/nhan-nhan-nhan/nhan-nhan-nhan.gif",
    categoryIds: ["tutorial"],
    toolbox: [{
            category: "basic",
            blocks: ["yolobit_basic_scroll_number", "yolobit_basic_sleep", "yolobit_basic_forever"]
        },
        {
            category: "input",
            blocks: ["yolobit_input_button_get_presses"]
        },
        {
            category: "math",
            blocks: ["math_arithmetic"]
        }
    ],
    steps: [{
            title: "Giới thiệu",
            content: `<div>
          <p>Bài này hướng dẫn cách đọc số lần nhấn nút sau mỗi 3 giây và hiện lên màn hình led.</p>
          <div class="ui segment raised">
            <img src="/assets/tutorials/nhan-nhan-nhan/nhan-nhan-nhan.gif">
          </div>
        </div>`
        },
        {
            title: "Bước 1",
            content: `<div>
          <p>Đầu tiên kéo khối <block class="basic">tạm dừng ... mili giây</block> trong mục <block class="basic">CƠ BẢN</block> ra ngoài và gắn vào trong khối <block>lặp lại mãi</block>:</p>
          <div class="ui segment raised">
            <img src="/assets/tutorials/nhan-nhan-nhan/step1.png">
          </div>
        </div>`
        },
        {
            title: "Bước 2",
            content: `<div>
        <p>Thay đổi thời gian thành 3000 mili giây, đây là thời gian tạm dừng, Yolo:Bit sẽ đếm số lần nút nhấn được nhấn trong thời gian này.</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/nhan-nhan-nhan/step2.png">
        </div>
      </div>`
        },
        {
            title: "Bước 3",
            content: `<div>
          <p>Dùng khối <block class="basic">hiện ra số</block> trong mục <block class="basic">CƠ BẢN</block> để hiển thị số:</p>
          <div class="ui segment raised">
          <img src="/assets/tutorials/nhan-nhan-nhan/step3.png">
          </div>
        </div>`
        },
        {
            title: "Bước 4",
            content: `<div>
          <p>Để đếm số lần nhấn nút, ta sử dụng khối <block class="input">số lần nhấn nút (A)</block> trong mục <block class="input">NGÕ VÀO</block>, gắn khối này vào khối <block class="basic">hiện ra số</block> để hiển thị số này ra màn hình:</p>
          <div class="ui segment raised">
            <img src="/assets/tutorials/nhan-nhan-nhan/step4.png">
          </div>
        </div>`
        },
        {
            title: "Bước 5",
            content: `<div>
        <p>Kết nối board rồi chạy chương trình cho Yolo:Bit. Sau đó nhấn nút (A) trên Yolo:Bit nhiều lần và quan sát kết quả.</p>
      </div>`
        },
        {
            title: "Bài tập mở rộng",
            content: `<div>
        <p>Hãy vận dụng kiến thức trong bài học này để thực hiện một chương trình hiển thị tổng số lần nhấn của cả 2 nút nhấn A và B trong 5 giây.</p>
      </div>`
        }
    ]
};