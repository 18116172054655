import $ from "jquery";
import store from "store";
import * as core from "../utils/core";
import yolobitToolbox from "../services/toolboxes/yolobit";
import esp32Toolbox from "./toolboxes/esp32";
import esp8266Toolbox from "./toolboxes/esp8266";

export function getToolbox(filter = null) {
  let toolbox = "";

  switch (core.getCurrentDevice()) {
    case "yolobit_v1_2":
    case "yolobit_v3":
      toolbox = yolobitToolbox;
      break;
    case "nodewifi32":
    case "esp32_nodemcu":
      toolbox = esp32Toolbox;
      break;
      case "nodewifi":
    case "esp8266_wemos_d1_mini":
      toolbox = esp8266Toolbox;
      break;
    default:
      toolbox = yolobitToolbox;
  }

  /**
  Filter example:
    filter = [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_number", "yolobit_basic_scroll_text"]
      },
      {
        category: "input",
        blocks: ["yolobit_input_button_is_pressed", "yolobit_input_button_get_presses"]
      }
    ];
   */
  if (!filter) {
    let toolboxWithExts = toolbox;
    const extensions = Array.isArray(store.get("extensions")) ? store.get("extensions") : [];
    if (extensions.length && core.getCurrentDevice().includes("yolobit")) { // TODO: support extension for other devices
      extensions.forEach((ext) => {
        let xml = ext.xmlToolbox.replace(/<xml.*>/gi, "");
        toolboxWithExts = toolboxWithExts.replace(`</xml>`, xml);
      });
      toolboxWithExts.replace(`</xml>`, "abc");
    }

    if (core.validateXml(toolboxWithExts)) {
      return toolboxWithExts;
    }

    return toolbox;
  }

  const xmlDoc = $.parseXML(toolbox);
  $(xmlDoc)
    .find("category")
    .each(function () {
      const catName = $(this).attr("id");
      const isCatFound = filter.find((item) => item.category === catName);
      if (isCatFound) {
        $(this)
          .find("block")
          .each(function () {
            const blockName = $(this).attr("type");
            if (!isCatFound.blocks.includes(blockName)) {
              $(this).remove();
            }

            const self = this;
            // If child removed then remove parent as well
            const parent = $(xmlDoc).find(`block[type="${blockName}"]`);
            if (parent.length > 1) {
              $(this)
                .find("block")
                .each(function () {
                  const childBlockName = $(this).attr("type");
                  if (!isCatFound.blocks.includes(childBlockName)) {
                    $(self).remove();
                  }
                });
            }
          });
      } else {
        $(this).remove();
      }
    });
  $(xmlDoc).find("sep").remove();

  return new XMLSerializer().serializeToString(xmlDoc);
}
