module.exports = {
  status : "tutorial",
  id: "digital-output",
  title: "Digital output",
  detail: "Bài này hướng dẫn dùng digital output điều khiển led nối với với pin 13.",
  image: "/assets/tutorials/digital-output/digital-output.png",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_sleep", "yolobit_basic_forever"]
    },
    {
      category: "advanced",
      blocks: ["yolobit_pin_write_digital", "yolobit_pin_onoff_value"]
    },
    {
      category: "pins",
      blocks: ["yolobit_pin_write_digital", "yolobit_pin_onoff_value"]
    },
    {
      category: "input",
      blocks: ["yolobit_input_button_is_pressed"]
    },
    {
      category: "logic",
      blocks: ["controls_if"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
  <p>Bài này hướng dẫn dùng digital output điều khiển led nối với với pin 13.</p>
  <div class="ui segment raised">
    <img src="/assets/tutorials/digital-output/digital-output.png">
  </div>
</div>`
    },
    {
      title: "Bước 1",
      content: `<div>
  <p>Trong mục <block class="advanced">NÂNG CAO</block> chọn mục <block class="pins">CHÂN CẮM</block>, dùng khối <block class="pins">đổi trạng thái bật tắt của chân P13 thành BẬT</block> để bật led:</p>
  <div class="ui segment raised">
    <img src="/assets/tutorials/digital-output/step1.png">
  </div>
</div>`
    },
    {
      title: "Bước 2",
      content: `<div>
  <p>Tương tự nếu muốn tắt led thì dùng khối <block class="pins">đổi trạng thái bật tắt của chân P13 thành TẮT</block>:</p>
  <div class="ui segment raised">
    <img src="/assets/tutorials/digital-output/step2.png">
  </div>
</div>`
    },
    {
      title: "Bước 3",
      content: `<div>
  <p>Có thể lồng ghép các khối <block class="basic">tạm dừng … mili giây</block> vào chương trình để tạo thành chương trình chớp tắt led như sau:</p>
  <div class="ui segment raised">
    <img src="/assets/tutorials/digital-output/step3.png">
  </div>
</div>`
    },
    {
      title: "Bước 4",
      content: `<div>
  <p>Cuối cùng nhấn vào <b> Chạy </b> để nạp chương trình cho Yolo:Bit và quan sát màu đèn led thay đổi mỗi khi lật Yolo:Bit.</p>
</div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
  <p>Thông qua bài học vừa rồi bạn đã biết cách sử dùng digital output, giờ hãy ứng dụng nó để điều khiển đèn led thông qua nút nhất. Nhấn nút A đèn led bật, nhấn nút B đèn led tắt.</p>
</div>`
    }
  ]
};
