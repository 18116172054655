module.exports = {
    
    id: "https://ohstem.vn/lp-courses/uncategorized/lap-trinh-chan-io-voi-yolobit/",
    title: "Lập trình chân I/O với Yolo:Bit",
    detail:
      'Trên Yolo:Bit có các chân I/O cho phép thiết bị có thể giao tiếp với bên ngoài. Chúng có thể được sử dụng để chạy servo, đọc trạng thái nút nhấn, switch, bật đèn LED, giao tiếp với cảm biến và nhiều thứ khác.',
    image: "/assets/tutorials/courses/Pin.jpg",
    categoryIds: ["courses"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  