module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/lap-trinh-tro-choi-voi-yolobit/",
    title: "Lập trình Trò Chơi với Yolo:Bit",
    detail:
      'Việc tự mình tạo ra trò chơi và làm chủ luật chơi luôn có một sức hút mãnh liệt với bất kỳ ai, nhất là các bạn nhỏ đang trong độ tuổi khám phá và sáng tạo.',
    image: "/assets/tutorials/courses/trochoi.jpg",
    categoryIds: ["courses"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  