module.exports = {
  status : "tutorial",
  id: "hello-yolobit",
  title: "Hello Yolo:Bit",
  detail:
    'Hãy bắt đầu làm quen với Yolo:Bit bằng việc hiển thị tên "Yolo:Bit" ra màn hình led. Các chữ cái sẽ được hiện ra và chạy từ phải sang trái.',
  image: "/assets/tutorials/hello-yolobit/hello-yolobit.jpg",
  categoryIds: ["tutorial"],
  label: "Mới bắt đầu?",
  labelClass: "green ribbon huge",
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Hãy bắt đầu làm quen với Yolo:Bit bằng việc hiển thị tên "Yolo:Bit" ra màn hình led. Các chữ cái sẽ được hiện ra và chạy từ phải sang trái.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hello-yolobit/hello-yolobit.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Đầu tiên kéo khối <block class="basic">hiện chữ</block> trong mục <block class="basic">CƠ BẢN</block> ra ngoài và gắn vào trong khối <block>lặp lại mãi</block>, sau đó nhấn vào mũi tên bên phải để xem bước tiếp theo:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hello-yolobit/step1.gif">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Nhấn vào chữ "Hello, World" và sửa thành "Yolo:Bit":</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hello-yolobit/step2.gif">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Nối Yolo:Bit với máy tính => Nhấn nút <b>Kết nối</b> => chọn "Yolo:Bit Board" để kết nối rồi nhấn <b>Chạy</b> và quan sát kết quả. (kéo chuột xuống dưới để xem toàn bộ hình ảnh)</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hello-yolobit/step3.gif">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Chúc mừng bạn đã hoàn thành bài học đầu tiên. Bấm vào kí hiệu như hình dưới để đánh dấu hoàn thành bài học và trở về danh sách các bài học nhé.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hello-yolobit/step4.png">
        </div>
      </div>`
    }
  ]
};
