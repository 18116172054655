import helloYolobit from "./tutorials/hello-yolobit";
import hienThiMatCuoi from "./tutorials/hien-thi-mat-cuoi";
import hienThiSo from "./tutorials/hien-thi-so";
import hinhTuyYMuon from "./tutorials/hinh-tuy-y-muon";
import nhanNhanNhan from "./tutorials/nhan-nhan-nhan";
import matCuoiMatMeu from "./tutorials/mat-cuoi-mat-meu";
import motNutHaiNut from "./tutorials/mot-nut-hai-nut";
import happyBirthday from "./tutorials/happy-birthday";
import baoDong from "./tutorials/bao-dong";
import lamNhacSi from "./tutorials/lam-nhac-si";
import hienThiNhietDo from "./tutorials/hien-thi-nhiet-do";
import baoDongNhietDoCao from "./tutorials/bao-dong-nhiet-do-cao";
import hienThiDoSang from "./tutorials/hien-thi-do-sang";
import denThongMinh from "./tutorials/den-thong-minh";
import lac from "./tutorials/lac";
import traiPhai from "./tutorials/trai-phai";
import upNgua from "./tutorials/up-ngua";
import analogOutput from "./tutorials/analog-output";
import camBienCham from "./tutorials/cam-bien-cham";
import digitalOutput from "./tutorials/digital-output";

import sangche from "./courses/sang-che";
import chanio from "./courses/chan_io";
import ketnoi from "./courses/ket_noi";
import lamquen from "./courses/lam_quen";
import micropython from "./courses/micropython";
import trochoi from "./courses/tro_choi";
import physbit from "./courses/physbit";

import ainhanhtay from "./games/ai_nhanh_tay";
import bantau from "./games/ban_tau_vt";
import flappybird from "./games/flappy_bird";
import giotnuoc from "./games/giot_nuoc_la_khoai";
import ransanmoi from "./games/ran_san_moi";
import ttkiennhan from "./games/tt_kien_nhan";

import hopvodung from "./creation/hop_vo_dung";
import khoangcach from "./creation/khoang_cach";
import nhiptim from "./creation/nhip_tim";
import thungrac from "./creation/thung_rac";
import phanloaikeo from "./creation/phan_loai_keo";
import vuoncay from "./creation/vuon_cay";
import more from "./creation/more";

import armbit from "./hardware/arm_bit";
import arduinokit from "./hardware/arduino_kit";
import carbit from "./hardware/car_bit";
import gamekit from "./hardware/game_kit";
import homebit from "./hardware/home_bit";
import plantbit from "./hardware/plant_bit";
import phys_bit from "./hardware/phys_bit";
import soundbit from "./hardware/sound_bit";
import yolobit from "./hardware/yolo_bit";





const tutorials = [
  helloYolobit,
  hienThiSo,
  hienThiMatCuoi,
  hinhTuyYMuon,
  matCuoiMatMeu,
  motNutHaiNut,
  nhanNhanNhan,
  happyBirthday,
  baoDong,
  lamNhacSi,
  hienThiNhietDo,
  baoDongNhietDoCao,
  hienThiDoSang,
  denThongMinh,
  lac,
  traiPhai,
  upNgua,
  camBienCham,
  analogOutput,
  digitalOutput,

  lamquen,
  sangche,
  chanio,
  trochoi,
  ketnoi,  
  physbit,
  micropython,
  

  ainhanhtay,
  bantau,
  flappybird,
  giotnuoc,
  ransanmoi,
  ttkiennhan,

  thungrac,
  hopvodung,
  phanloaikeo,
  nhiptim,
  vuoncay,
  khoangcach,
  more,
  

  yolobit,
  phys_bit,
  homebit,
  soundbit,
  plantbit,
  gamekit,
  arduinokit,
  carbit,
  armbit



];

export function getTutorials() {
  return tutorials;
}

export function getTutorialsByCategory(id) {
  return tutorials.filter(t => t.categoryIds.includes(id));
}

export function getTutorial(id) {
  return tutorials.find(t => t.id === id);
}
