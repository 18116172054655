module.exports = {
  status : "tutorial",
  id: "bao-dong",
  title: "Báo động",
  detail: "Chúng ta hãy làm cho Yolo:Bit phát ra âm thanh báo động giống còi hú của cảnh sát khi bắt cướp.",
  image: "/assets/tutorials/bao-dong/bao-dong.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_forever"]
    },
    {
      category: "music",
      blocks: ["yolobit_music_pitch"]
    },
    {
      category: "loops",
      blocks: ["controls_for"]
    },
    {
      category: "variables",
      blocks: ["variables_set"]
    },
    {
      category: "input",
      blocks: ["yolobit_input_button_is_pressed"]
    },
    {
      category: "logic",
      blocks: ["controls_if"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Chúng ta hãy làm cho Yolo:Bit phát ra âm thanh báo động giống còi hú của cảnh sát khi bắt cướp.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/bao-dong/bao-dong.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Dùng một vòng lặp <block class="loops">đếm theo ...</block> trong mục <block class="loops">VÒNG LẶP</block> để đếm một số và gắn vào khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/bao-dong/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Thay đổi số bắt đầu thành 880, số kết thúc 1760 và tăng thêm 16 sau mỗi lần đếm. Đây là tần số âm thanh giống với còi hú báo động của cảnh sát:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/bao-dong/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Dùng khối <block class="music">phát ra âm thanh với tần số ...</block> trong mục <block class="music">ÂM NHẠC</block> để phát ra âm thanh:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/bao-dong/step3.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Gắn khối <block class="variables">i</block> trong <block class="variables">BIẾN</block> vào khối <block class="music">phát ra âm thanh với tần số ...</block> để phát ra âm thanh theo tần số đang chạy và thay đổi thời gian thành 50 milli giây:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/bao-dong/step4.png">
        </div>
      </div>`
    },
    {
      title: "Bước 5",
      content: `<div>
        <p>Tạo thêm một vòng lặp đếm giống vậy nhưng là đếm ngược từ 1760 đến 880. Để đếm ngược thì bước đếm là -16, thay vì 16.:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/bao-dong/step5.png">
        </div>
      </div>`
    },
    {
      title: "Bước 6",
      content: `<div>
        <p>Cuối cùng kết nối board rồi nhấn vào nút <b>Chạy</b> để chạy chương trình và nghe thử âm thanh báo động phát ra từ Yolo:Bit.</p>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
        <p>Hãy thử lập trình để Yolo:Bit phát âm thanh báo động khi nút nhấn A được nhấn.</p>
      </div>`
    }
  ]
};
