import React, { Component } from "react";
import { Icon, fireClickOnEnter } from "./Common";
import { isTouchEnabled } from "../utils/browser";

const OUT_OF_BOUND_MARGIN = 300;
const DRAG_THRESHOLD = 5;
const DraggingDirection = {
  None: 0,
  X: 1,
  Y: 2
};

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.dragSurface = {};
    this.container = {};
    this.arrows = [];
    this.isDragging = false;
    this.definitelyDragging = DraggingDirection.None;

    this.childWidth = 0;
    this.containerWidth = 0;
    this.arrowWidth = 0;
    this.actualPageLength = 0;

    this.currentOffset = 0;
    this.index = 0;

    this.dragStartX = 0;
    this.dragStartY = 0;
    this.dragStartOffset = 0;
    this.dragOffset = 0;

    this.animation = {};

    this.animationId = 0;
    this.childrenElements = [];
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedIndex !== undefined) {
      this.setIndex(nextProps.selectedIndex);
    }
  }

  handleContainerRef = c => {
    this.container = c;
  };

  handleDragSurfaceRef = c => {
    this.dragSurface = c;
  };

  handleArrowRefs = c => {
    this.arrows.push(c);
  };

  handleChildRefs = c => {
    if (c) this.childrenElements.push(c);
  };

  render() {
    const { rightDisabled, leftDisabled } = this.state;

    return (
      <div className="ui carouselouter">
        <span
          role="button"
          className={"carouselarrow left aligned" + (leftDisabled ? " arrowdisabled" : "")}
          aria-label="See previous"
          tabIndex={leftDisabled ? -1 : 0}
          onClick={this.onLeftArrowClick}
          onKeyDown={fireClickOnEnter}
          ref={this.handleArrowRefs}
        >
          <Icon icon="circle angle left" />
        </span>
        <div className="carouselcontainer" ref={this.handleContainerRef}>
          <div className="carouselbody" ref={this.handleDragSurfaceRef}>
            {React.Children.map(this.props.children, (child, index) =>
              child ? (
                <div
                  className={`carouselitem ${this.props.selectedIndex === index ? "selected" : ""}`}
                  ref={this.handleChildRefs}
                >
                  {React.cloneElement(child, {
                    tabIndex: this.isVisible(index) ? 0 : -1
                  })}
                </div>
              ) : (
                undefined
              )
            )}
          </div>
        </div>
        <span
          role="button"
          className={"carouselarrow right aligned" + (rightDisabled ? " arrowdisabled" : "")}
          aria-label="See more"
          tabIndex={rightDisabled ? -1 : 0}
          onClick={this.onRightArrowClick}
          onKeyDown={fireClickOnEnter}
          ref={this.handleArrowRefs}
        >
          <Icon icon="circle angle right" />
        </span>
      </div>
    );
  }

  onLeftArrowClick = () => {
    this.onArrowClick(true);
  };

  onRightArrowClick = () => {
    this.onArrowClick(false);
  };

  onArrowClick(left) {
    const prevIndex = this.index;
    this.setIndex(left ? this.index - this.actualPageLength : this.index + this.actualPageLength);
    if (left) {
      // Focus right most
      const prevElement =
        this.index + this.actualPageLength < prevIndex ? this.index + this.actualPageLength : prevIndex - 1;
      if (this.childrenElements[prevElement]) this.childrenElements[prevElement].firstChild.focus();
    } else {
      // Focus left most
      const nextElement =
        this.index > prevIndex + this.actualPageLength ? this.index : prevIndex + this.actualPageLength;
      if (this.childrenElements[nextElement]) this.childrenElements[nextElement].firstChild.focus();
    }
  }

  componentDidMount() {
    this.initDragSurface();
    this.updateDimensions();
    window.addEventListener("resize", e => {
      this.updateDimensions();
    });
  }

  componentDidUpdate() {
    this.updateDimensions();
  }

  updateDimensions() {
    if (this.container) {
      let shouldReposition = false;
      this.containerWidth = this.container.getBoundingClientRect().width;
      this.getArrowWidth();
      if (this.childrenElements.length) {
        const newWidth = this.childrenElements[0].getBoundingClientRect().width;
        if (newWidth !== this.childWidth) {
          this.childWidth = newWidth;
          shouldReposition = true;
        }
        this.actualPageLength = Math.floor(this.containerWidth / this.childWidth);
      }
      this.dragSurface.style.width = this.totalLength() + "px";
      this.updateArrows();

      if (this.index >= this.maxIndex()) {
        shouldReposition = true;
        this.index = this.maxIndex();
      }

      if (shouldReposition) {
        this.setPosition(this.indexToOffset(this.index));
      }
    }
  }

  initDragSurface() {
    let down = event => {
      this.definitelyDragging = DraggingDirection.None;
      this.dragStart(getX(event), getY(event));
    };

    let up = event => {
      if (this.isDragging) {
        this.dragEnd();
        if (this.definitelyDragging) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    };

    let leave = event => {
      if (this.isDragging) {
        this.dragEnd();
      }
    };

    let move = event => {
      if (this.isDragging) {
        let x = getX(event);
        if (!this.definitelyDragging) {
          // lock direction
          let y = getY(event);
          if (Math.abs(x - this.dragStartX) > DRAG_THRESHOLD) {
            this.definitelyDragging = DraggingDirection.X;
          } else if (Math.abs(y - this.dragStartY) > DRAG_THRESHOLD) {
            this.definitelyDragging = DraggingDirection.Y;
          }
        }

        if (this.definitelyDragging === DraggingDirection.X) {
          event.stopPropagation();
          event.preventDefault();
          window.requestAnimationFrame(() => {
            this.dragMove(x);
          });
        }
      }
    };

    this.dragSurface.addEventListener("click", event => {
      if (this.definitelyDragging) {
        event.stopPropagation();
        event.preventDefault();
      }
    });

    if (window.PointerEvent) {
      this.dragSurface.addEventListener("pointerdown", down);
      this.dragSurface.addEventListener("pointerup", up);
      this.dragSurface.addEventListener("pointerleave", leave);
      this.dragSurface.addEventListener("pointermove", move);
    } else {
      this.dragSurface.addEventListener("mousedown", down);
      this.dragSurface.addEventListener("mouseup", up);
      this.dragSurface.addEventListener("mouseleave", leave);
      this.dragSurface.addEventListener("mousemove", move);

      if (isTouchEnabled()) {
        this.dragSurface.addEventListener("touchstart", down);
        this.dragSurface.addEventListener("touchend", up);
        this.dragSurface.addEventListener("touchcancel", leave);
        this.dragSurface.addEventListener("touchmove", move);
      }
    }
  }

  dragStart(startX, startY) {
    this.isDragging = true;
    this.dragStartX = startX;
    this.dragStartY = startY;
    this.dragStartOffset = this.currentOffset;
    if (this.animationId) {
      window.cancelAnimationFrame(this.animationId);
      this.animationId = 0;
    }
  }

  dragEnd() {
    this.isDragging = false;
    this.calculateIndex();
  }

  dragMove(x) {
    this.dragOffset = x - this.dragStartX;
    const newOffset = this.dragStartOffset - this.dragOffset;
    this.setPosition(newOffset);
  }

  setPosition(offset) {
    if (this.dragSurface) {
      offset = Math.min(Math.max(offset, -OUT_OF_BOUND_MARGIN), this.maxScrollOffset());
      this.currentOffset = offset;
      this.dragSurface.style.marginLeft = -offset + "px";
    }
  }

  calculateIndex() {
    if (this.dragSurface) {
      const bucketIndex = Math.round(Math.max(this.currentOffset, 0) / this.childWidth);
      let index;
      if (this.currentOffset > this.dragStartOffset) {
        index = bucketIndex;
      } else {
        index = bucketIndex - 1;
      }

      this.setIndex(index, 200);
    }
  }

  setIndex(index, millis) {
    const newIndex = Math.max(Math.min(index, this.maxIndex()), 0);

    if (!millis) {
      millis = Math.abs(newIndex - this.index) * 100;
    }

    this.index = newIndex;
    this.updateArrows();

    this.animation = new AnimationState(this.currentOffset, this.indexToOffset(this.index), millis);
    if (!this.animationId) {
      this.animationId = window.requestAnimationFrame(this.easeTowardsIndex.bind(this));
    }
  }

  isVisible(index) {
    return index >= this.index && index < this.index + (this.actualPageLength || 4);
  }

  easeTowardsIndex(time) {
    if (this.dragSurface) {
      this.setPosition(this.animation.getPosition(time));
      if (this.animation.isComplete) {
        this.animation = undefined;
        this.animationId = 0;
      } else {
        this.animationId = window.requestAnimationFrame(this.easeTowardsIndex.bind(this));
      }
    }
  }

  indexToOffset(index) {
    if (index <= 0) {
      return 0;
    }
    if (index === this.maxIndex()) {
      return this.totalLength() - this.containerWidth - OUT_OF_BOUND_MARGIN + this.arrowWidth * 2;
    }
    return index * this.childWidth - (this.childWidth * this.props.bleedPercent) / 100;
  }

  totalLength() {
    return React.Children.count(this.props.children) * this.childWidth + OUT_OF_BOUND_MARGIN;
  }

  getArrowWidth() {
    if (this.arrows.length) {
      this.arrowWidth = 0;
      this.arrows.forEach(a => {
        if (a) {
          this.arrowWidth = Math.max(a.getBoundingClientRect().width, this.arrowWidth);
        }
      });
    }
  }

  maxScrollOffset() {
    return Math.max(this.totalLength() - this.actualPageLength * this.childWidth + OUT_OF_BOUND_MARGIN, 0);
  }

  maxIndex() {
    return Math.max(this.childrenElements.length - this.actualPageLength, 0);
  }

  updateArrows() {
    const { rightDisabled, leftDisabled } = this.state || {};
    const newRightDisabled = this.index === this.maxIndex();
    const newLeftDisabled = this.index === 0;

    if (newRightDisabled !== rightDisabled || newLeftDisabled !== leftDisabled) {
      this.setState({
        leftDisabled: newLeftDisabled,
        rightDisabled: newRightDisabled
      });
    }
  }
}
export default Carousel;

class AnimationState {
  constructor(start, end, millis) {
    this.slope = (end - start) / millis;
    this.isComplete = false;
    this.start = start;
    this.end = end;
    this.millis = millis;
  }

  getPosition(time) {
    if (this.isComplete) return this.end;
    if (this.startTime === undefined) {
      this.startTime = time;
      return this.start;
    }
    const diff = time - this.startTime;
    if (diff > this.millis) {
      this.isComplete = true;
      return this.end;
    }
    return this.start + Math.floor(this.slope * diff);
  }
}

function getX(event) {
  if ("screenX" in event) {
    return event.screenX;
  } else {
    return event.changedTouches[0].screenX;
  }
}

function getY(event) {
  if ("screenY" in event) {
    return event.screenX;
  } else {
    return event.changedTouches[0].screenY;
  }
}
