module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/lap-trinh-tro-choi-voi-yolobit/lessons/gioi-thieu-7/",
    title: "Chú chim Flappy",
    detail:
      'Chú chim Flappy là một trò chơi rất nổi tiếng toàn thế giới cách đây một thời gian và điều tự hào là trò chơi này được viết bởi một lập trình viên người Việt. Trong trò chơi này, người chơi cố gắng điều khiển chú chim Flappy bay qua các vật cản là các ống màu xanh lá mà không bị đụng vào chúng.',
    image: "/assets/tutorials/courses/flappy.jpg",
    categoryIds: ["games"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  