module.exports = {
  status : "tutorial",
  id: "hien-thi-mat-cuoi",
  title: "Hiển thị mặt cười",
  detail: "Bài học này hướng dẫn chúng ta hiển thị hình mặt cười ra màn hình led.",
  image: "/assets/tutorials/hien-thi-mat-cuoi/hien-thi-mat-cuoi.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_show_image", "yolobit_basic_image_builtins", "yolobit_basic_forever"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Bài học này hướng dẫn chúng ta hiển thị hình mặt cười ra màn hình led.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-mat-cuoi/hien-thi-mat-cuoi.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Kéo khối <block class="basic">hiện hình ảnh ...</block> trong mục <block class="basic">CƠ BẢN</block> ra và gắn vào khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-mat-cuoi/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Nhấn vào chữ HEART (hình trái tim) và đổi thành SMILE (hình mặt cười):</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-mat-cuoi/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Kết nối board rồi nhấn vào nút <b>Chạy</b> để chạy chương trình cho Yolo:Bit và quan sát kết quả.</p>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
        <p>Bạn đã biết cách hiển thị hiện mặt cười ra màn hình. Giờ hãy thử hiển thị các hình ảnh khác như hình mặt mếu, hình trái tim ra màn hình nữa nhé.</p>
      </div>`
    }
  ]
};
