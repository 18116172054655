import store from "store";
import _ from "lodash";

import * as core from "../utils/core";

export default class storeService {
  constructor(table) {
    this.table = table;
  }

  create(item) {
    let allItems = this.all();
    item.createdDate = core.getTime();
    item.updatedDate = core.getTime();
    store.set(this.table, allItems.push(item));

    return allItems;
  }

  update(item) {
    let allItems = this.all();
    const itemIndex = allItems.findIndex((i) => i.id === item.id);
    if (itemIndex >= 0) {
      item.updatedDate = core.getTime();
      allItems[itemIndex] = item;
      store.set(this.table, allItems);

      return allItems;
    } else {
      return undefined;
    }
  }

  merge(item) {
    item.updatedDate = core.getTime();

    let allItems = this.all();
    const itemIndex = allItems.findIndex((i) => i.id === item.id);
    if (itemIndex >= 0) {
      allItems[itemIndex] = { ...allItems[itemIndex], ...item };
      store.set(this.table, allItems);
    } else {
      item.createdDate = core.getTime();
      allItems.push(item);
      store.set(this.table, allItems);
    }

    return allItems;
  }

  updateOrCreate(item) {
    item.updatedDate = core.getTime();

    let allItems = this.all();
    const itemIndex = allItems.findIndex((i) => i.id === item.id);
    if (itemIndex >= 0) {
      allItems[itemIndex] = item;
      store.set(this.table, allItems);
    } else {
      item.createdDate = core.getTime();
      allItems.push(item);
      store.set(this.table, allItems);
    }

    return allItems;
  }

  recent() {
    return _.maxBy(this.all(), "updatedDate");
  }

  size() {
    return this.all().length;
  }

  find(id) {
    return _.find(this.all(), { id });
  }

  findBy(condition) {
    return _.find(this.all(), condition);
  }

  first() {
    return _.first(this.all());
  }

  last() {
    return _.last(this.all());
  }

  all() {
    return _.orderBy(store.get(this.table) || [], ["updatedDate"], ["desc"]);
  }

  query(condition) {
    return _.filter(this.all(), condition);
  }

  delete(id) {
    let allItems = this.all();
    _.remove(allItems, (item) => {
      return item.id === id;
    });
    store.set(this.table, allItems);

    return allItems;
  }

  deleteAll() {
    store.set(this.table, []);
  }
}
