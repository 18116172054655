module.exports = {
  status : "tutorial",
  id: "mot-nut-hai-nut",
  title: "Một nút, hai nút",
  detail:
    "Bài này hướng dẫn cách làm cho khi nhấn nút A, màn hình led hiển thị số 1, nhấn nút B sẽ hiển thị số 2 và khi nhấn cả 2 nút một lúc thì màn hình led sẽ tắt.",
  image: "/assets/tutorials/mot-nut-hai-nut/mot-nut-hai-nut.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: [
        "yolobit_basic_scroll_number",
        "yolobit_basic_clear_display",
        "yolobit_basic_sleep",
        "yolobit_basic_forever"
      ]
    },
    {
      category: "input",
      blocks: ["yolobit_input_button_is_pressed"]
    },
    {
      category: "logic",
      blocks: ["controls_if", "logic_operation"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Bài này hướng dẫn cách làm cho khi nhấn nút (A), màn hình led hiển thị số 1, nhấn nút (B) sẽ hiển thị số 2 và khi nhấn cả 2 nút một lúc thì màn hình led sẽ tắt.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/mot-nut-hai-nut/mot-nut-hai-nut.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Dùng khối <block class="logic">nếu ... thực hiện ... nếu không</block> trong mục <block class="logic">LÔGIC</block> và gắn vào trong khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/mot-nut-hai-nut/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Chọn khối <block class="logic">... và ...</block> và gắn vào khối <block class="logic">nếu ...</block>:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mot-nut-hai-nut/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Gắn thêm các khối <block class="input">nút (A) được nhấn</block> và khối <block class="input">nút (B) được nhấn</block> trong mục trong mục <block class="input">NGÕ VÀO</block> vào trong khối <block class="logic">... và ...</block>:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mot-nut-hai-nut/step3.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Gắn khối <block class="basic">xoá màn hình</block> vào khối <block class="if">thực hiện ...</block> để tắt màn hình led khi cả 2 nút đều được nhấn và <block class="basic">tạm dừng 300 milli giây</block> để chờ màn hình được xóa xong:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mot-nut-hai-nut/step4.png">
        </div>
      </div>`
    },
    {
      title: "Bước 5",
      content: `<div>
        <p>Dùng thêm khối <block class="logic">nếu ... thực hiện</block> trong mục <block class="logic">LÔGIC</block> và gắn vào trong khối <block class="logic">nếu không ...</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/mot-nut-hai-nut/step5.png">
        </div>
      </div>`
    },
    {
      title: "Bước 6",
      content: `<div>
        <p>Kéo khối <block class="input">nút (A) được nhấn</block> trong mục <block class="input">NGÕ VÀO</block> gắn vào khối <block class="logic">nếu ...</block>:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mot-nut-hai-nut/step6.png">
        </div>
      </div>`
    },
    {
      title: "Bước 7",
      content: `<div>
        <p>Kéo khối <block class="basic">hiện ra số ...</block> trong mục <block class="basic">CƠ BẢN</block> và gắn vào trong khối <block class="logic">thực hiện ...</block> sau đó đổi số 0 thành 1. Khi nút (A) được nhấn thì lệnh này sẽ hiển thị số 1 ra màn hình:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mot-nut-hai-nut/step7.png">
        </div>
      </div>`
    },
    {
      title: "Bước 8",
      content: `<div>
        <p>Nhấn chuột phải vào khối <block class="logic">nếu ... thực hiện</block> rồi chọn "Tạo Bản Sao" để tạo ra khối tương tự:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mot-nut-hai-nut/step8.png">
        </div>
      </div>`
    },
    {
      title: "Bước 9",
      content: `<div>
        <p>Đổi nút (A) thành (B) và số 1 thành 2:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mot-nut-hai-nut/step9.png">
        </div>
      </div>`
    },
    {
      title: "Bước 10",
      content: `<div>
        <p>Kết nối board rồi nhấn vào nút <b>Chạy</b> để chạy chương trình cho Yolo:Bit. Sau đó nhấn từng nút (A), (B) hoặc đồng thời trên Yolo:Bit và quan sát kết quả.</p>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
        <p>Dựa vào bài học vừa rồi, hãy hoàn thành chương trình sau:</p>
        <p>Khi nhấn nút A, màn hình hiển thị hình trái tim sáng 300 mili giây rồi tắt 300 mili giây. Khi nhấn nút B, màn hình hiển thị hình mặt cười sáng 300 mili giây rồi tắt 300 mili giây. </p>
      </div>`
    }
  ]
};
