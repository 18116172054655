import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { DropdownMenu, Item } from "../../components/Common";

class SettingsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renameProject = () => {
    this.props.parent.renameProject();
  };

  saveProject = () => {
    this.props.parent.saveProject();
  };

  removeProject = () => {
    this.props.parent.removeProject();
  };

  changeLanguage = lang => {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    if (navigator.onLine) {
      window.location.reload();
    }
  };

  render() {
    const { t } = this.props;
    return (
      <DropdownMenu
        role="menuitem"
        icon={"setting large"}
        text={t("Setting")}
        textClass="landscape only"
        className="item icon more-dropdown-menuitem"
      >
        {this.props.type !== "tutorial" && (
          <Fragment>
            {this.props.projectName.length > 0 && (
              <Item role="menuitem" icon="pencil" text={t("Rename")} onClick={this.renameProject} />
            )}
            <Item role="menuitem" icon="save" text={t("Save")} onClick={this.saveProject} />
            <Item
              className="remove-project"
              role="menuitem"
              icon="trash"
              text={t("Delete")}
              onClick={this.removeProject}
            />
            <div className="ui divider" />
          </Fragment>
        )}
        <Item role="menuitem" icon="share" text={t("Export")} onClick={() => this.props.parent.exportProject()} />
        <Item role="menuitem" icon="share alternate" text={t("Share")} onClick={() => this.props.parent.shareProject()} />
        <div className="ui divider" />
        <Item
          role="menuitem"
          icon="microchip"
          text={t("Update Firmware")}
          onClick={() => this.props.parent.updateFirmware()}
        />
        <Item
          role="menuitem"
          icon="microchip"
          text={t("Update Libraries")}
          onClick={() => this.props.parent.updateInstalledExtLibraries()}
        />
        <div className="ui divider" />
        <Item role="menuitem" icon="language" text="English" onClick={() => this.changeLanguage("en")} />
        <Item role="menuitem" icon="language" text="Tiếng Việt" onClick={() => this.changeLanguage("vi")} />
      </DropdownMenu>
    );
  }
}

export default withTranslation()(SettingsMenu);
