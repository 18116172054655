import React, { Component } from "react";
import * as core from "../../utils/core";
import * as browserUtils from "../../utils/browser";
import { Modal, Input } from "../../components/Common";
import { t } from "../../utils/core";

class ExitAndSaveDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      emoji: "😞"
    };
  }

  componentWillReceiveProps(newProps) {
    this.handleChange(newProps.parent.state.projectName);
  }

  hide = () => {
    this.setState({ visible: false });
  };

  show(goHome) {
    this.setState({ visible: true, goHome });
  }

  modalDidOpen(ref) {
    // Save on enter typed
    let dialogInput = document.getElementById("projectNameInput");
    if (dialogInput) {
      if (!browserUtils.isMobile()) dialogInput.setSelectionRange(0, 9999);
      dialogInput.onkeydown = e => {
        const charCode = core.keyCodeFromEvent(e);
        if (charCode === core.ENTER_KEY) {
          e.preventDefault();
          const approveButton = ref.getElementsByClassName("approve positive").item(0);
          if (approveButton) approveButton.click();
        }
      };
    }
  }

  handleChange = name => {
    this.setState({ projectName: name });
    name = name || ""; // gard against null/undefined
    if (!name) {
      this.setState({ emoji: "😞" });
    } else {
      const emojis = ["😌", "😄", "😃", "😍"];
      let emoji = emojis[Math.min(name.length, emojis.length) - 1];
      const n = name.length >> 1;
      if (n > emojis.length)
        for (let i = 0; i < Math.min(2, n - emojis.length); ++i) emoji += emojis[emojis.length - 1];
      this.setState({ emoji });
    }
  };

  skip = () => {
    this.hide();
    if (this.state.goHome) {
      this.props.parent.removeProject("sandbox");
      this.props.parent.props.history.push("/");
    }
  };

  save = () => {
    const { projectName: newName } = this.state;
    // save project name if valid change
    if (newName) {
      this.props.parent.saveNewProject(newName, this.state.goHome);
      this.hide();
    }
  };

  render() {
    const { visible, emoji, projectName } = this.state;

    const actions = [
      {
        label: t("Save"),
        onclick: this.save,
        icon: "check",
        className: "approve positive"
      },
      {
        label: t("Skip"),
        onclick: this.skip
      }
    ];

    return (
      <Modal
        ref="exitandsave"
        isOpen={visible}
        className="exitandsave"
        size="tiny"
        onClose={this.hide}
        dimmer={true}
        buttons={actions}
        closeIcon={true}
        header={t("Project has no name") + emoji}
        closeOnDimmerClick
        closeOnDocumentClick
        closeOnEscape
        modalDidOpen={this.modalDidOpen}
      >
        <div>
          <p>{t("Give your project a name")}</p>
          <div className="ui form">
            <Input
              ref="filenameinput"
              autoFocus={!browserUtils.isMobile()}
              id={"projectNameInput"}
              ariaLabel="Type a name for your project"
              autoComplete={false}
              value={projectName || ""}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default ExitAndSaveDialog;
