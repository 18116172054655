module.exports = {
  status:"hardware",
    //id: "https://ohstem.vn/knowledge-base/game-kit/",
    id:"https://ohshop.vn/product/game-kit/",
    title: "Game Kit",
    detail:
      'Game Kit là máy chơi game cẩm tay có thể lập trình. Bạn có thể tự viết các trò chơi độc đáo cho riêng mình bằng cách kéo thả khối lệnh trên Makecode hoặc tự viết mã bằng JavaScript.',
    image: "/assets/tutorials/courses/gamekit.jpg",
    categoryIds: ["hardware"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  