module.exports = {
  status : "tutorial",
  id: "hien-thi-so",
  title: "Hiển thị số",
  detail: "Bài học này hướng dẫn chúng ta hiển thị các số tự nhiên ra màn hình led.",
  image: "/assets/tutorials/hien-thi-so/hien-thi-so.png",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_scroll_number", "yolobit_basic_sleep", "yolobit_basic_forever"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Bài học này hướng dẫn chúng ta hiển thị các số tự nhiên ra màn hình led.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-so/hien-thi-so.png">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Kéo khối <block class="basic">hiện ra số ...</block> trong mục <block class="basic">CƠ BẢN</block> ra và gắn vào khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-so/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Nhấp chuột vào số 0 và đổi thành số mà bạn muốn Yolo:Bit hiển thị, ví dụ số 5:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-so/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Kết nối Yolo:Bit với máy tính rồi chạy chương trình và quan sát kết quả.</p>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
        <p>Bạn đã biết cách hiển thị số ra màn hình. Hãy lập trình để Yolo:Bit lần lượt hiển thị các số tự nhiên từ 1 đến 5, cứ hiển thị 1 số sau đó tạm dừng 1 giây rồi hiển thị số tiếp theo.</p>
      </div>`
    }
  ]
};
