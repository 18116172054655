import React, { Component } from "react";

export class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ""
    };
  }

  handleSearchKeyUpdate = ev => {
    // Call search when a user presses Enter
    if (ev.keyCode === 13) this.handleSearch(this.state.inputValue);
  };

  handleSearch(inputValue) {
    const { searchHandler } = this.props;
    searchHandler(inputValue);
  }

  handleClick = () => {
    this.handleSearch(this.state.inputValue);
  };

  updateInputValue = ev => {
    const { searchOnChange } = this.props;
    const inputValue = ev.target.value;
    this.setState({ inputValue });

    // If we search on change, call search
    if (searchOnChange) this.handleSearch(inputValue);
  };

  render() {
    const {
      ariaMessage,
      disabled,
      loading,
      placeholder,
      autoFocus,
      className,
      inputClassName,
      searchOnChange,
      searchHandler,
      ...rest
    } = this.props;
    const { inputValue } = this.state;

    return (
      <div className={`ui search ${className || ""}`} {...rest}>
        <div className={`ui icon input ${inputClassName || ""}`}>
          <div aria-live="polite" className="accessible-hidden" id="searchInputBoxLabel">
            {ariaMessage}
          </div>
          <input
            role="search"
            autoFocus={autoFocus}
            ref="searchInput"
            aria-labelledby="searchInputBoxLabel"
            className="prompt"
            type="text"
            placeholder={placeholder}
            onChange={this.updateInputValue}
            value={inputValue}
            onKeyUp={this.handleSearchKeyUpdate}
            disabled={disabled}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
          />
          <i
            role="button"
            onClick={this.handleClick}
            title={"Search"}
            style={{ cursor: "pointer" }}
            className={`search link icon ${disabled ? "disabled" : ""} ${loading ? "loading" : ""}`}
          ></i>
        </div>
      </div>
    );
  }
}
