import React, { Component } from "react";
import { fireClickOnEnter } from "./Common";

class ExtensionCard extends Component {
  render() {
    const { extension } = this.props;
    let styleForInstalledExt = {};
    if (extension.installed) {
      styleForInstalledExt = {
        backgroundColor: "#0083b7"
      }
    }
    return (
      <div
        className="ui card link "
        role="link"
        tabIndex="0"
        onClick={() => this.props.parent.importExtension(extension.src, extension.id)}
        onKeyDown={fireClickOnEnter}
        style={styleForInstalledExt}
      >
        <div className="ui image">
          <div className="ui imagewrapper">
            <div
              className="ui cardimage"
              data-src={extension.src}
              style={{ backgroundImage: `url("${extension.poster}")` }}
            ></div>
          </div>
        </div>
        <div className="content">
          <div className="header">{extension.name}</div>
          <div className="description tall ">{extension.description}</div>
        </div>
        { extension.installed && <div onClick={(event) => this.props.parent.removeExtension(event, extension.id)} className="meta"><span className="red ui remove-extension label"><i className="close icon"></i></span></div> }
      </div>
    );
  }
}

export default ExtensionCard;
