import React, { Component } from "react";
import { Modal } from "./Common";
import { t } from "../utils/core";
export class TutorialHint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
  }

  showHint() {
    this.setState({ visible: true });
  }

  render() {
    const { visible } = this.state;
    const options = this.props.parent.state.tutorialOptions;
    const { tutorialStep, tutorial } = options;

    const header = tutorial.steps[tutorialStep].title || tutorial.title;
    const content = tutorial.steps[tutorialStep].content;

    const hide = () => this.setState({ visible: false });

    const startFirstStep = () => {
      hide();
      this.props.parent.setTutorialStep(1);
    };

    const actions = [
      {
        label: tutorialStep === 0 ? t("Start") : "Ok",
        onclick: tutorialStep === 0 ? startFirstStep : hide,
        icon: "check",
        className: "green"
      }
    ];

    return (
      <Modal
        isOpen={visible}
        className="hintdialog"
        closeIcon={true}
        header={header}
        buttons={actions}
        onClose={hide}
        dimmer={true}
        longer={true}
        closeOnDimmerClick
        closeOnDocumentClick
        closeOnEscape
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Modal>
    );
  }
}
