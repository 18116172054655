import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import uuid from "uuid";
import store from "store";
import { withTranslation } from "react-i18next";
import Menu from "./Menu";
import Banner from "./Banner";
import TutorialGallery from "./TutorialGallery";
import ProjectList from "./ProjectList";
import Footer from "./Footer";
import { getCategories } from "../../services/categoryService";
import * as dialogs from "../../utils/dialogs";
import * as core from "../../utils/core";
import "./index.scss";

class Home extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      projects: Array.isArray(store.get("projects")) ? store.get("projects") : []
    };
    window.t = this.props.t;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.visible !== nextState.visible ||
      this.state.selectedCategory !== nextState.selectedCategory ||
      this.state.selectedIndex !== nextState.selectedIndex
    );
  }

  setSelected = (category, index) => {
    if (index === undefined || (this.state.selectedCategory === category && this.state.selectedIndex === index)) {
      this.setState({ selectedCategory: undefined, selectedIndex: undefined });
    } else {
      this.setState({ selectedCategory: category, selectedIndex: index });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedCategory !== prevState.selectedCategory) {
      this.ensureSelectedItemVisible();
    }
  }

  ensureSelectedItemVisible() {
    let activeCarousel = this.refs["activeCarousel"];
    if (activeCarousel) {
      let domNode = activeCarousel.getCarouselDOM();
      this.scrollElementIntoViewIfNeeded(domNode);
    }
  }

  scrollElementIntoViewIfNeeded(domNode) {
    let containerDomNode = ReactDOM.findDOMNode(this.refs["homeContainer"]);
    // Determine if `domNode` fully fits inside `containerDomNode`.
    // If not, set the container's scrollTop appropriately.
    const domTop = domNode.getBoundingClientRect().top;
    const delta = domTop;
    const offset = 30;
    containerDomNode.parentElement.scrollTop = containerDomNode.parentElement.scrollTop + delta - offset;
  }

  handleImportProject = () => {
    dialogs.showImportFileDialogAsync().done(res => {
      if (res) {
        core.fileReadAsTextAsync(res).then(content => {
          try {
            let project = JSON.parse(content);
            project.id = uuid.v1();
            project.updatedDate = new Date().getTime();

            let projects = [...this.state.projects, project];
            store.set("projects", projects);
            this.setState({ projects }, () => this.forceUpdate());
          } catch (error) {
            console.error("Import failed", error.message);
          }
        });
      }
    });
  };

  render() {
    const { selectedCategory, selectedIndex } = this.state;
    const validProjects = this.state.projects.filter(p => p.name.length > 0);
    return (
      <div id="homescreen" className="full-abs" role="main">
        <div className="ui home projectsdialog">
          <Menu parent={this} />
          <div ref="homeContainer" className="ui segment bottom attached tab active tabsegment">
            <Banner />
            <ProjectList t={this.props.t} projects={validProjects} onImportProject={this.handleImportProject} />
            {getCategories(this.props.t).map(cat => (
              <TutorialGallery
                category={cat}
                key={cat.id}
                ref={`${selectedCategory === cat.id ? "activeCarousel" : ""}`}
                name={cat.name}
                id={cat.id}
                setSelected={this.setSelected}
                selectedIndex={selectedCategory === cat.id ? selectedIndex : undefined}
              />
            ))}

            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Home);
