module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/lap-trinh-tro-choi-voi-yolobit/lessons/gioi-thieu-5/",
    title: "Thử thách kiên nhẫn",
    detail:
      'Đây là một trò chơi mà lúc trước tác giả thường được chơi khi tham gia các hội chợ ngày tết. Trong trò chơi này, người chơi sẽ cầm 1 cây sắt ngắn móc vòng qua 1 dây sắt dài và cố gắng di chuyển cây sắt ngắn đi từ đầu dây đến cuối dây sắt dài mà không làm cây sắt ngắn chạm vào sợi dây sắt dài. Nó đòi hỏi sự kiên nhẫn và khéo tay của người chơi.',
    image: "/assets/tutorials/courses/kiennhan.jpg",
    categoryIds: ["games"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  