import React, { Component } from "react";
import { Icon, fireClickOnEnter } from "../../components/Common";

class TutorialCard extends Component {
  handleClick = e => {
    this.props.onTutorialClick(e, this.props.catName, this.props.index);
  };

  render() {
    const { tutorial, label, labelClass, labelIcon } = this.props;
    const progressText = tutorial.progress + "/" + (tutorial.steps.length - 1);
    let statusClass = "orange";
    if (tutorial.isFinished) {
      statusClass = "green";
    }

    return (
      <div className="ui card  link example" tabIndex="0" onClick={this.handleClick} onKeyDown={fireClickOnEnter}>
        <div className="ui image">
          {label || labelIcon ? (
            <label className={`ui ${labelClass ? labelClass : "orange right ribbon"} label`}>
              {labelIcon ? <Icon icon={labelIcon} /> : label}
            </label>
          ) : (
            undefined
          )}
          <div className="ui imagewrapper">
            <div
              className="ui cardimage"
              data-src={tutorial.image}
              style={{
                backgroundImage: "url(" + tutorial.image + ")"
              }}
            />
          </div>
        </div>
        <div className="content">
          <div className="header">{tutorial.title}</div>
        </div>
        {tutorial.progress && (
          <div className="meta">
            <span className={statusClass + " ui tutorial-progress left floated label"}>
              <i className="circle icon" />
              &nbsp;{progressText}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default TutorialCard;
