module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/lap-trinh-tro-choi-voi-yolobit/lessons/gioi-thieu-4/",
    title: "Giọi nước lá khoai",
    detail:
      'Đây là một trò chơi dân gian mà lúc nhỏ tác giả thường hay chơi ngoài đồng cùng các bạn. Cách chơi rất đơn giản là mỗi bạn sẽ hái một lá khoai và nhỏ 1 giọt nước vào giữa lá. Lá khoai là loại lá không dính nước, nên giọt nước sẽ dễ dàng chạy qua chạy lại khi lá bị nghiêng. Tất cả cùng nhau cầm lá khoai có giọt nước chạy một đoạn, ai đến đích trước mà không bị đổ nước sẽ là người chiến thắng.',
    image: "/assets/tutorials/courses/lakhoai.jpg",
    categoryIds: ["games"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  