module.exports = {
    status:"hardware",
    id: "https://ohstem.vn/ideas/",
    title: "Và còn nhiều hơn nữa!",
    detail:
      'Còn rất nhiều sản phẩm sáng tạo nữa!!!',
    image: "/assets/tutorials/courses/creative.jpg",
    categoryIds: ["creation"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  