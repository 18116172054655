module.exports = {
  status : "tutorial",
  id: "bao-dong-nhiet-do-cao",
  title: "Báo động nhiệt độ cao",
  detail: "Bài này sẽ hướng dẫn cách đọc nhiệt độ và phát ra âm thanh báo động khi quá nóng.",
  image: "/assets/tutorials/bao-dong-nhiet-do-cao/bao-dong-nhiet-do-cao.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_forever"]
    },
    {
      category: "input",
      blocks: ["yolobit_input_get_temperature"]
    },
    {
      category: "music",
      blocks: ["yolobit_music_pitch"]
    },
    {
      category: "logic",
      blocks: ["controls_if", "logic_compare"]
    },
    {
      category: "math",
      blocks: ["math_number"]
    },
    {
      category: "variables",
      blocks: ["variables_set"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Bài này sẽ hướng dẫn cách đọc nhiệt độ và phát ra âm thanh báo động khi quá nóng.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/bao-dong-nhiet-do-cao/bao-dong-nhiet-do-cao.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
            <p>Để lưu giá trị nhiệt độ, ta sẽ tạo ra một biến bằng cách nhấn vào block <block class="gray">Tạo biến...</block> trong mục <block class="variables">BIẾN</block> và đặt tên là i:</p>
            <div class="ui segment raised">
              <img src="/assets/tutorials/bao-dong-nhiet-do-cao/step1.png">
            </div>
          </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Kéo khối <block class="variables">cho i bằng</block> trong mục <block class="variables">BIẾN</block> ra và gắn vào khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/bao-dong-nhiet-do-cao/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Dùng khối <block class="input">nhiệt độ</block> trong mục <block class="input">NGÕ VÀO</block> để đọc nhiệt độ hiện tại và gắn vào khối <block class="variables">cho i bằng ...</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/bao-dong-nhiet-do-cao/step3.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Để kiểm tra giá trị nhiệt độ có quá nóng hay không ta dùng khối <block class="logic">nếu ... thực hiện</block> và khối <block class="logic">so sánh lớn hơn</block> trong cùng mục <block class="logic">LÔGIC</block> để kiểm tra:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/bao-dong-nhiet-do-cao/step4.png">
        </div>
      </div>`
    },
    {
      title: "Bước 5",
      content: `<div>
        <p>Kéo khối <block class="variables">i</block> trong mục <block class="variables">BIẾN</block> và khối <block class="math">0</block> trong mục <block class="math">TÍNH TOÁN</block> gắn vào hai bên của phép so sánh bằng. Đổi số 0 thành số 35. Như vậy mỗi khi nhiệt độ vượt quá 35 độ C thì sẽ báo động.</p>
        <p>Bạn có thể thay đổi giá trị nhiệt độ dùng để so sánh dựa vào bài <b>Hiển thị nhiệt độ</b> đã làm trước đó.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/bao-dong-nhiet-do-cao/step5.png">
        </div>
      </div>`
    },
    {
      title: "Bước 6",
      content: `<div>
        <p>Kéo khối <block class="music">phát ra âm thanh với tần số ...</block> trong mục <block class="music">ÂM NHẠC</block> để phát ra âm thanh báo động. Thay 1000 milli giây thành 2000 milli giây để âm thanh phát ra lâu hơn.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/bao-dong-nhiet-do-cao/step6.png">
        </div>
      </div>`
    },
    {
      title: "Bước 7",
      content: `<div>
        <p>Cuối cùng nhấn vào nút <b>Chạy</b> để chạy chương trình cho Yolo:Bit và quan sát xem có báo động không khi để Yolo:Bit gần chỗ nào hơi nóng một chút nhé.</p>
      </div>`
    }
  ]
};
