module.exports = {
  status : "tutorial",
  id: "mat-cuoi-mat-meu",
  title: "Mặt cười, mặt mếu",
  detail:
    "Bài này hướng dẫn cách làm cho khi nhấn nút A, màn hình led sẽ hiển thị mặt cười nhưng khi nhấn nút B sẽ hiển thị mặt mếu.",
  image: "/assets/tutorials/mat-cuoi-mat-meu/mat-cuoi-mat-meu.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: [
        "yolobit_basic_show_image",
        "yolobit_basic_image_builtins",
        "yolobit_basic_scroll_text",
        "yolobit_basic_forever"
      ]
    },
    {
      category: "input",
      blocks: ["yolobit_input_button_is_pressed"]
    },
    {
      category: "logic",
      blocks: ["controls_if"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Bài này hướng dẫn cách làm cho khi nhấn nút A, màn hình led sẽ hiển thị mặt cười nhưng khi nhấn nút B sẽ hiển thị mặt mếu.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/mat-cuoi-mat-meu/mat-cuoi-mat-meu.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Dùng khối <block class="logic">nếu ... thực hiện</block> trong mục <block class="logic">LÔGIC</block> và gắn vào trong khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/mat-cuoi-mat-meu/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Kéo khối <block class="input">nút (A) được nhấn</block> trong mục <block class="input">NGÕ VÀO</block> gắn vào khối <block class="logic">nếu ...</block>:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mat-cuoi-mat-meu/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Kéo khối <block class="basic">hiện hình ảnh ...</block> trong mục <block class="basic">CƠ BẢN</block> và gắn vào trong khối <block class="logic">thực hiện ...</block> sau đó đổi chữ HEART thành SMILE. Khi nút (A) được nhấn thì lệnh này sẽ hiển thị hình mặt cười ra màn hình:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mat-cuoi-mat-meu/step3.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Nhấn chuột phải vào khối <block class="logic">nếu ... thực hiện</block> rồi chọn "Tạo Bản Sao" để tạo ra khối tương tự:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mat-cuoi-mat-meu/step4.png">
        </div>
      </div>`
    },
    {
      title: "Bước 5",
      content: `<div>
        <p>Đổi nút (A) thành (B) và chữ SMILE thành SAD:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/mat-cuoi-mat-meu/step5.png">
        </div>
      </div>`
    },
    {
      title: "Bước 6",
      content: `<div>
        <p>Kết nối board rồi nhấn vào nút <b>Chạy</b> để chạy chương trình cho Yolo:Bit. Sau đó lần lượt nhấn nút (A), (B) trên Yolo:Bit và quan sát kết quả.</p>
      </div>`
    },
    {
      title: "Bài học mở rộng",
      content: `<div>
        <p>Hãy luyện tập thêm phần sử dụng nút nhấn với bài học sau:</p>
        <p>Khi nhấn nút A màn hình hiển thị chữ A, khi nhấn nút B màn hình hiển thị chữ B.</p>
      </div>`
    }
  ]
};
