import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { t } from "../../utils/core";
import { NO_LABLE_ARRAY } from "../../utils/constants";

const options = {
  chart: {
    type: "line",
    animations: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    width: 5,
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    labels: {
      formatter: function (value, timestamp, opts) {
        return new Date(value).toLocaleTimeString();
      },
    },
  },
  yaxis: {
    show: true,
  },
  tooltip: {
    enabled: false,
  },
};

class ApexChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChartSerial: {},
    };
  }

  setDataSeries = (dataChartSerial) => {
    let keyData = Object.keys(dataChartSerial);
    if (keyData.length) {
      let newData = keyData.map((item) => {
        let { data } = dataChartSerial[item];
        if (data && data.length) {
          return {
            colors: dataChartSerial[item].colors,
            title: {
              text: item === NO_LABLE_ARRAY ? t(item) : item,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Open Sans, Helvetica Neue, Arial, Helvetica, sans-serif",
                color: "#0083b7",
              },
            },
            series: [
              {
                name: t("Value"),
                data: data.slice(Math.max(data.length - 24, 0)),
              },
            ],
          };
        }
      });
      this.setState({
        dataChartSerial: newData,
      });
    }
  };

  render() {
    const { dataChartSerial } = this.state;
    const keyData = Object.keys(dataChartSerial);
    return (
      <div id="chart-serial">
        {keyData.length
          ? keyData.map((item) => {
              let dataItem = dataChartSerial[item];
              if (dataItem.series.length) {
                return (
                  <ReactApexChart
                    key={item}
                    options={{
                      ...options,
                      colors: dataItem.colors,
                      title: dataItem.title,
                    }}
                    series={dataItem.series}
                    type="line"
                    height={250}
                  />
                );
              }
            })
          : null}
      </div>
    );
  }
}

export default ApexChart;
