module.exports = {
    
    id: "https://ohstem.vn/thung-rac-thong-minh/",
    title: "Thùng rác thông minh",
    detail:
      'Nếu bạn cảm thấy thùng rác của mình quá tẻ nhạt và vô dụng, thì hãy cùng nhau làm 1 thùng rác thông minh có thể tự "há mỏ xin rác" mỗi khi bạn lại gần. ^^',
    image: "/assets/tutorials/courses/thungracthongminh.jpg",
    categoryIds: ["creation"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  