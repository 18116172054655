module.exports = {
  status : "tutorial",
  id: "hinh-tuy-y-muon",
  title: "Hình tùy ý muốn",
  detail: "Bài này hướng dẫn cách tạo ra một hình vuông và nhấp nháy trên màn hình led.",
  image: "/assets/tutorials/hinh-tuy-y-muon/hinh-tuy-y-muon.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: [
        "yolobit_basic_show_image",
        "yolobit_basic_create_image",
        "yolobit_basic_sleep",
        "yolobit_basic_clear_display",
        "yolobit_basic_forever"
      ]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Bài này hướng dẫn cách tạo ra một hình vuông và nhấp nháy trên màn hình led.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hinh-tuy-y-muon/hinh-tuy-y-muon.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Kéo khối <block class="basic">hiện hình ảnh ...</block> trong mục <block class="basic">CƠ BẢN</block> ra và gắn vào khối <block>lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hinh-tuy-y-muon/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Nhấn vào các ô đen và đổi màu các ô để thành một hình vuông:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/hinh-tuy-y-muon/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Chọn tiếp khối <block class="basic">tạm dừng ... mili giây</block> trong mục <block class="basic">CƠ BẢN</block> để màn hình led sáng khoảng 1 giây (=1000 mili giây):</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/hinh-tuy-y-muon/step3.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Chọn khối <block class="basic">xoá màn hình</block> trong mục <block class="basic">CƠ BẢN</block> để tắt màn hình led và tiếp tục <block class="basic">tạm dừng 1000 mili giây</block>:</p>
        <div class="ui segment raised">
        <img src="/assets/tutorials/hinh-tuy-y-muon/step4.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Kết nối board rồi nhấn vào nút <b>Chạy</b> để chạy chương trình cho Yolo:Bit và quan sát kết quả.</p>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
        <p>Bạn đã biết cách hiển thị một hình ảnh bất kì ra màn hình led. Giờ hãy thử tạo ta một hình chữ nhật và điều khiển cho hình chữ nhật đó cứ sáng 1 giây rồi lại tắt 1 giây nhé.</p>
      </div>`
    }
  ]
};
