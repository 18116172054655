module.exports = {
  status:"hardware",
    //id: "https://ohstem.vn/knowledge-base/soundbit/",
    id:"https://ohshop.vn/product/soundbit-bo-kit-lam-may-nghe-nhac/",
    title: "Sound:Bit",
    detail:
      'Sound:Bit là 1 trong các bộ kit mở rông của Yolo:Bit. Bằng cách sử dụng thêm Shield mở rộng cho Yolo:Bit, ta có thể kết nối Yolo:Bit với module phát nhạc MP3 Player và các nút nhấn để để lập trình các chức năng của một chiếc máy nghe nhạc với Yolo:Bit.',
    image: "/assets/tutorials/courses/sound.jpg",
    categoryIds: ["hardware"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  