import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { t } from "../../utils/core";
import Loader from "../../components/Loader";
import { Modal, Tooltip } from "../../components/Common";
import { getDeviceList } from "../../services/devicesList";
import * as core from "../../utils/core";

class ListDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  onSelect = (id) => {
    this.props.onSelect(id);
    this.props.onClose();
  };

  render() {
    const listDevice = getDeviceList();
    return (
      <Fragment>
        {this.state.loading && <Loader />}
        <Modal
          isOpen={this.props.isOpen}
          className="searchdialog"
          size="fullscreen"
          closeIcon={true}
          header={t("Devices")}
          onClose={this.props.onClose}
          closeOnDimmerClick
          closeOnEscape
        >
          <div className="ui">
            {/* {this.state.loading && <Loader />} */}
            <div className="ui grid equal width padded heading device-card-list">
              {listDevice.map((item) => {
                let styleForSelectedDevice = {};
                if (item.id === core.getCurrentDevice()) {
                  styleForSelectedDevice = {
                    backgroundColor: "#0083b7",
                  };
                }

                return (
                  <div
                    key={item.id}
                    className="ui card link example device-card-item"
                    tabIndex="0"
                    onClick={() => this.onSelect(item.id)}
                    style={styleForSelectedDevice}
                  >
                    <div className="ui image">
                      <div className="ui imagewrapper">
                        <div
                          className="ui cardimage"
                          data-src={item.image}
                          style={{
                            backgroundImage: "url(" + item.image + ")",
                          }}
                        />
                      </div>
                    </div>
                    <div className="content">
                      <div className="header">{item.title}</div>
                      {/* <Tooltip
                        id={item.id}
                        content={item.detail}
                        place={"bottom"}
                      >
                        <div className="body">{item.detail}</div>
                      </Tooltip> */}
                      <div className="body">{item.detail}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(ListDevice);
