module.exports = {
  status : "tutorial",
  id: "hien-thi-do-sang",
  title: "Hiển thị độ sáng",
  detail: "Bài này hướng dẫn dùng cảm biến ánh sáng để đọc độ sáng và hiện độ sáng ra màn hình led.",
  image: "/assets/tutorials/hien-thi-do-sang/hien-thi-do-sang.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_scroll_number", "yolobit_basic_sleep"]
    },
    {
      category: "input",
      blocks: ["yolobit_input_get_light_level"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Bài này hướng dẫn dùng cảm biến ánh sáng để đọc độ sáng và hiện độ sáng ra màn hình led.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-do-sang/hien-thi-do-sang.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Kéo khối <block class="basic">hiện ra số</block> trong mục <block class="basic">CƠ BẢN</block> ra và gắn vào khối <block class="basic">lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-do-sang/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Kéo khối <block class="input">mức độ sáng</block> trong mục <block class="input">NGÕ VÀO</block> ra và gắn vào khối <block class="basic">hiện ra số</block> thay cho số 0:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-do-sang/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Sử dụng khối <block class="basic">tạm dừng 1000 milli giây</block> trong mục <block class="basic">CƠ BẢN</block> và đổi thành 2000 milli giây (bằng 2 giây) để tạm dừng chương trình trong 2 giây giúp dễ dàng quan sát con số trên màn hình led:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-do-sang/step3.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Cuối cùng nhấn vào nút <b>Chạy</b> để chạy chương trình cho Yolo:Bit và quan sát kết quả khi lấy tay che cảm biến ánh sáng ở góc trên bên phải của Yolo:Bit.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/hien-thi-do-sang/light-sensor.png">
        </div>
        </div>`
    }
  ]
};
