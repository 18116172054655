module.exports = {
  status:"hardware",
    //id: "https://ohstem.vn/knowledge-base/homebit/",
    id:"https://ohshop.vn/product/homebit-bo-kit-hoc-lam-nha-thong-minh/",
    title: "Home:Bit",
    detail:
      'Home:Bit mà bộ kit giúp bạn tự tay tạo nên một ngôi nhà với đầy đủ các tính năng của một smarthome cho riêng mình.',
    image: "/assets/tutorials/courses/homebit.jpg",
    categoryIds: ["hardware"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  