import React from "react";
import { Link } from "react-router-dom";
import { t } from "../../utils/core";

const TutorialDetail = (props) => {
  const { title, detail, image, id,status } = props.tutorial;
  return (
    <div className="ui grid stackable padded">
      <div className="imagewrapper">
        <div
          className="image"
          style={{
            backgroundImage: 'url("' + image + '")',
          }}
        />
      </div>
      <div className="column eight wide">
        <div className="segment">
          <div className="header">{title}</div>
          <p className="detail">{detail}</p>
          <div className="actions">
            {status==='tutorial'?
            <Link to={`/tutorial/${id}`}>
              <button className="ui button approve huge positive" tabIndex="0">
                <span className="ui text">{t("Show Instructions")}</span>
              </button>
            </Link>:
            <a href={`${id}`} target="_blank">
              <button className="ui button approve huge positive" tabIndex="0">
                <span className="ui text">{status==='hardware'?t("More"):t("Show Instructions")}</span>
              </button>
            </a>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TutorialDetail;
