module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/sang-che-cung-yolobit/",
    title: "Sáng chế cùng Yolo:Bit",
    detail:
      'Khóa học này hướng dẫn thực hiện những sáng chế vui nhộn, thú vị với Yolo:Bit. Ngoài nâng cao kỹ năng lập trình, các sáng chế còn giúp rèn luyện tính kiên nhẫn, tỉ mỉ và kích thích tư duy sáng tạo.',
    image: "/assets/tutorials/courses/Sangche.jpg",
    categoryIds: ["courses"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  