import moment from "moment";
import Octokit from "@octokit/rest";
import store from "store";
import * as coreMessages from "./coreMessages";
import { GITHUB_ACCESS_TOKEN } from "./constants";

///////////////////////////////////////////////////////////
////////////       Notification msg           /////////////
///////////////////////////////////////////////////////////

function showNotificationMsg(kind, msg) {
  coreMessages.pushNotificationMessage({ kind: kind, text: msg, hc: true });
}

export function errorNotification(msg) {
  showNotificationMsg("err", msg);
}

export function warningNotification(msg) {
  showNotificationMsg("warn", msg);
}

export function infoNotification(msg) {
  showNotificationMsg("info", msg);
}

///////////////////////////////////////////////////////////
////////////       Dialogs (confirm, prompt)  /////////////
///////////////////////////////////////////////////////////

export function dialogAsync(options) {
  if (!options.type) options.type = "dialog";
  if (!options.hideCancel) {
    if (!options.buttons) options.buttons = [];
    options.buttons.push({
      label: options.disagreeLbl || t("Cancel"),
      className: options.disagreeClass || "cancel",
      icon: options.disagreeIcon || "cancel",
    });
  }
  return coreMessages.renderConfirmDialogAsync(options);
}

export function hideDialog() {
  coreMessages.hideDialog();
}

export function confirmAsync(options) {
  options.type = "confirm";
  if (!options.buttons) options.buttons = [];

  let result = 0;

  if (!options.hideAgree) {
    options.buttons.push({
      label: options.agreeLbl || t("GoAhead"),
      className: options.agreeClass,
      icon: options.agreeIcon || "checkmark",
      approveButton: true,
      onclick: () => {
        result = 1;
      },
    });
  }

  if (options.deleteLbl) {
    options.buttons.push({
      label: options.deleteLbl,
      className: "delete red",
      icon: "trash",
      onclick: () => {
        result = 2;
      },
    });
  }

  return dialogAsync(options).then(() => result);
}

export function confirmDelete(what, cb, multiDelete) {
  confirmAsync({
    header: multiDelete ? `Would you like to delete ${what} projects?` : `Would you like to delete '${what}'?`,
    body: "It will be deleted for good. No undo.",
    agreeLbl: "Delete",
    agreeClass: "red",
    agreeIcon: "trash",
  })
    .then((res) => {
      if (res) {
        cb().done();
      }
    })
    .done();
}

export function promptAsync(options) {
  options.type = "prompt";
  if (!options.buttons) options.buttons = [];

  let result = options.initialValue || "";
  let cancelled = false;

  options.onInputChanged = (v) => {
    result = v;
  };

  if (!options.hideAgree) {
    options.buttons.push({
      label: options.agreeLbl || "Tiến hành!",
      className: options.agreeClass,
      icon: options.agreeIcon || "checkmark",
      approveButton: true,
    });
  }

  if (!options.hideCancel) {
    // Replace the default cancel button with our own
    options.buttons.push({
      label: options.disagreeLbl || "Huỷ",
      className: options.disagreeClass || "cancel",
      icon: options.disagreeIcon || "cancel",
      onclick: () => {
        cancelled = true;
      },
    });
    options.hideCancel = true;
  }

  return dialogAsync(options).then(() => (cancelled ? null : result));
}

///////////////////////////////////////////////////////////
////////////         Accessibility            /////////////
///////////////////////////////////////////////////////////
export const TAB_KEY = 9;
export const ESC_KEY = 27;
export const ENTER_KEY = 13;
export const SPACE_KEY = 32;

export function resetFocus() {
  let content = document.getElementById("content");
  content.tabIndex = 0;
  content.focus();
  content.blur();
  content.tabIndex = -1;
}

export function keyCodeFromEvent(e) {
  return typeof e.which === "number" ? e.which : e.keyCode;
}

function getRandomBuf(buf) {
  if (window.crypto) {
    window.crypto.getRandomValues(buf);
  } else {
    for (let i = 0; i < buf.length; ++i) buf[i] = Math.floor(Math.random() * 255);
  }
}

export function randomUint32() {
  let buf = new Uint8Array(4);
  getRandomBuf(buf);
  return new Uint32Array(buf.buffer)[0];
}

export function guidGen() {
  function f() {
    return (randomUint32() | 0x10000).toString(16).slice(-4);
  }
  return f() + f() + "-" + f() + "-4" + f().slice(-3) + "-" + f() + "-" + f() + f() + f();
}

export function htmlEscape(_input) {
  if (!_input) return _input; // null, undefined, empty string test
  return _input.replace(/([^\w .!?\-$])/g, (c) => "&#" + c.charCodeAt(0) + ";");
}

///////////////////////////////////////////////////////////
////////////         Time since            /////////////
///////////////////////////////////////////////////////////

export function timeSince(unixTime) {
  moment.updateLocale("en", {
    relativeTime: {
      future: "trong %s",
      past: "cách đây %s",
      s: "vài giây",
      ss: "%d giây",
      m: "1 phút",
      mm: "%d phút",
      h: "1 giờ",
      hh: "%d hours",
      d: "1 ngày",
      dd: "%d ngày",
      M: "1 tháng",
      MM: "%d tháng",
      y: "1 năm",
      yy: "%d năm",
    },
  });

  return moment(unixTime).fromNow();
}

///////////////////////////////////////////////////////////
////////////              Translation                //////
///////////////////////////////////////////////////////////

export function t(key) {
  return window.t(key);
}

///////////////////////////////////////////////////////////
////////////         Export to PC             /////////////
///////////////////////////////////////////////////////////

export function exportToPc(data, filename) {
  if (!data) {
    console.error("No data");
    return;
  }

  if (!filename) {
    filename = "download.json";
  }

  if (typeof data === "object" && data.constructor === Object) {
    data = JSON.stringify(data);
  }

  var blob = new Blob([data], { type: "text/json" });

  // FOR IE:

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var e = document.createEvent("MouseEvents"),
      a = document.createElement("a");

    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
    e.initEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
  }
}

///////////////////////////////////////////////////////////
////////////         Read file                /////////////
///////////////////////////////////////////////////////////

export function fileReadAsTextAsync(f) {
  if (!f) return Promise.resolve(null);
  else {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onerror = (ev) => resolve(null);
      reader.onload = (ev) => resolve(reader.result);
      reader.readAsText(f);
    });
  }
}

export function fileReadAsBufferAsync(f) {
  // ArrayBuffer
  if (!f) return Promise.resolve(null);
  else {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onerror = (ev) => resolve(null);
      reader.onload = (ev) => resolve(new Uint8Array(reader.result));
      reader.readAsArrayBuffer(f);
    });
  }
}

///////////////////////////////////////////////////////////
////////////     Clean Vietnamese Accents     /////////////
///////////////////////////////////////////////////////////

export function cleanAccents(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Combining Diacritical Marks
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)

  return str;
}

///////////////////////////////////////////////////////////
////////////         Read Github file                //////
///////////////////////////////////////////////////////////

export function readGithubFileAsync(owner, repo, path) {
  return new Promise((resolve, reject) => {
    const octokit = new Octokit({
      auth: GITHUB_ACCESS_TOKEN,
    });
    octokit.repos
      .getContents({
        owner,
        repo,
        path,
      })
      .then((result) => {
        // content will be base64 encoded
        const content = Buffer.from(result.data.content, "base64").toString();
        resolve(content);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

///////////////////////////////////////////////////////////
////////////         Read S3 file                //////
///////////////////////////////////////////////////////////

export function readS3FileAsync(url) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => resolve(fileReadAsTextAsync(blob)))
      .catch((error) => {
        reject(error);
      });
  });
}

//list = await core.fileReadAsTextAsync(getList).then((data) => data);

///////////////////////////////////////////////////////////
////////////       Get image base64 from Github      //////
///////////////////////////////////////////////////////////

export function getGithubImageBase64Async(owner, repo, path) {
  return new Promise((resolve, reject) => {
    const octokit = new Octokit({
      auth: GITHUB_ACCESS_TOKEN,
    });
    octokit.repos
      .getContents({
        owner,
        repo,
        path,
      })
      .then((result) => {
        // content will be base64 encoded
        const content = Buffer.from(result.data.content, "base64").toString("base64");
        resolve(content);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

///////////////////////////////////////////////////////////
////////////              forceReload                //////
///////////////////////////////////////////////////////////

export function forceReload(inSecond = 0) {
  setTimeout(() => {
    if (navigator.onLine) {
      window.location.reload();
    }
  }, inSecond * 1000);
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function getTime() {
  return new Date().getTime();
}

export function setCurrentDevice(deviceId) {
  return store.set("selected-device", deviceId);
}

export function getCurrentDevice() {
  return store.get("selected-device") || "yolobit_v1_2";
}

export function validateXml(xml) {
  const parser = new window.DOMParser();
  const theDom = parser.parseFromString(xml, "application/xml");
  if (theDom.getElementsByTagName("parsererror").length > 0) {
    console.error("XML validation failed");
    return false;
  }

  return true;
}
