module.exports = {
  status:"hardware",
    //id: "https://ohstem.vn/knowledge-base/carbit/",
    id:"https://ohshop.vn/product/carbit-xe-dieu-khien-thong-minh/",
    title: "Car:Bit",
    detail:
      'Car:Bit là bộ kit xe thông minh được trang bị các cảm biến, động cơ sử dụng Yolo:Bit điều khiển',
    image: "/assets/tutorials/courses/Car.jpg",
    categoryIds: ["hardware"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  