import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import logo from "../../assets/logo.png";

class SharedMenu extends Component {
  tryIt = () => {
    window.top.location.href = window.location.href;
  }
  render() {
    return (
      <div className="menubar" role="banner">
        <div id="mainmenu" className="ui borderless fixed inverted menu" role="menubar">
        <div className="left menu">
            <a href="#" className="header item">
              <img alt="logo" className="logo" src={logo} style={{height:"50px",width:"auto"}} />
              <span className="ui text landscape only">Yolo:Bit</span>
            </a>
          </div>
          <div className="ui item home">{this.props.projectName}</div>
          <div className="right menu"></div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SharedMenu);
