import React from "react";

const Workspace = () => {
  return (
    <xml xmlns="http://www.w3.org/1999/xhtml" id="workspaceBlocks" style={{ display: "none" }}>
      <block type="yolobit_basic_forever" id="w5Bl*-!,Ihc8X2tI)wP*" x="87" y="37" />
    </xml>
  );
};

export default Workspace;
