import React, { Component } from "react";
import SettingsMenu from "./SettingsMenu";
import { withTranslation } from "react-i18next";
import { Item, Button } from "../../components/Common";
import * as core from "../../utils/core";

class TutorialMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  previousTutorialStep = () => {
    let options = this.props.parent.state.tutorialOptions;
    const currentStep = options.tutorialStep;
    const previousStep = currentStep - 1;

    options.tutorialStep = previousStep;
    this.props.parent.setTutorialStep(previousStep);
    this.saveProject();
  };

  nextTutorialStep = () => {
    let options = this.props.parent.state.tutorialOptions;
    const currentStep = options.tutorialStep;
    const nextStep = currentStep + 1;

    options.tutorialStep = nextStep;
    this.props.parent.setTutorialStep(nextStep);
    this.saveProject();
  };

  finishTutorial = () => {
    this.saveProject();
    this.closeLightbox();
    this.props.parent.completeTutorial();
  };

  closeLightboxOnEscape = e => {
    const charCode = core.keyCodeFromEvent(e);
    if (charCode === 27) {
      this.closeLightbox();
    }
  };

  closeLightbox() {
    document.documentElement.removeEventListener("keydown", this.closeLightboxOnEscape);
  }

  componentWillUpdate() {
    document.documentElement.addEventListener("keydown", this.closeLightboxOnEscape);
  }

  showHint = () => {
    this.closeLightbox();
    this.props.parent.showTutorialHint();
  };

  saveProject = () => {
    this.props.parent.saveTutorialProject();
  };

  removeProject = () => {
    this.props.parent.removeProjectAndGoHome();
  };

  exportProject = () => {
    this.props.parent.exportProject();
  };

  shareProject = () => {
    this.props.parent.shareProject();
  };

  goHome = () => {
    this.props.parent.saveTutorialProject();
    this.props.parent.goHome();
  };

  updateFirmware = () => {
    this.props.parent.updateFirmware();
  };

  updateInstalledExtLibraries = () => {
    this.props.parent.updateInstalledExtLibraries();
  };

  render() {
    const options = this.props.parent.state.tutorialOptions;
    const { tutorial, tutorialStep } = options;

    const currentStep = tutorialStep;
    const maxSteps = tutorial.steps.length;
    const hasPrevious = currentStep !== 0;
    const hasNext = currentStep !== maxSteps - 1;
    const hasFinish = currentStep === maxSteps - 1;
    const { t } = this.props;

    return (
      <div className="menubar" role="banner">
        <div id="mainmenu" className="ui borderless fixed inverted menu" role="menubar">
          <div className="left menu">
            <Item
              className="icon openproject"
              role="menuitem"
              text={t("Home")}
              textClass="landscape only"
              icon="home large"
              onClick={this.goHome}
            />
          </div>
          <div className="ui item link editor-menuitem">
            <div className="ui buttons">
              {hasPrevious ? (
                <Button
                  icon={`left chevron`}
                  className={`prevbutton left attached green ${!hasPrevious ? "disabled" : ""}`}
                  textClass="landscape only"
                  ariaLabel="Go to the previous step of the tutorial."
                  onClick={this.previousTutorialStep}
                />
              ) : (
                undefined
              )}
              <div className="ui segment attached tutorialsegment" onClick={this.showHint}>
                <span id="tutorial-progress">
                  {currentStep} / {maxSteps - 1}
                </span>
              </div>
              {hasNext ? (
                <Button
                  icon={`right chevron`}
                  rightIcon
                  className={`nextbutton right attached green ${!hasNext ? "disabled" : ""}`}
                  textClass="landscape only"
                  ariaLabel="Go to the next step of the tutorial."
                  onClick={this.nextTutorialStep}
                />
              ) : (
                undefined
              )}
              {hasFinish ? (
                <Button
                  icon="left checkmark"
                  className={`blue right attached`}
                  ariaLabel="Finish the tutorial."
                  onClick={this.finishTutorial}
                />
              ) : (
                undefined
              )}
            </div>
          </div>
          <div className="right menu">
            <div role="button" className="avatar-image" onClick={this.showHint} />
            <SettingsMenu parent={this} type="tutorial" />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TutorialMenu);
