import React, { Component } from "react";
import { Icon, fireClickOnEnter } from "../../components/Common";
import { timeSince } from "../../utils/core";

class ProjectCard extends Component {
  handleClick = () => {
    this.props.onProjectClick(this.props.project.id);
  };

  render() {
    const { project } = this.props;
    const icon = this.props.project.mode === "block" ? "xicon blocks" : "python";

    return (
      <div className="carouselitem " onClick={this.handleClick} onKeyDown={fireClickOnEnter}>
        <div className="ui card  link file" tabIndex="0">
          <div className="ui image">
            <div className="ui fileimage">
              <Icon icon={`${icon} big`} />
            </div>
          </div>
          <div className="content">
            <div className="header">{project.name}</div>
          </div>
          <div className="meta">
            <span className="date">{timeSince(this.props.project.updatedDate)}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectCard;
