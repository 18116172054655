import GitUrlParse from "git-url-parse";
import { Octokit } from "@octokit/core";
import * as core from "./core";
import { GITHUB_ACCESS_TOKEN } from "./constants";
import { getCoreExtensions } from "../services/extensionService";

export const getGithubExtension = async (src) => {
  const gitExt = GitUrlParse(src);
  const owner = gitExt.owner;
  const repo = gitExt.name;
  const configPath = "config.json";

  try {
    let config;
    let ext;

    const coreExtUrls = getCoreExtensions().map((ext) => ext.src);
    if (coreExtUrls.includes(src)) {
      const baseUrl = getBaseUrl({ owner, repo });
      config = await core.readS3FileAsync(baseUrl + configPath);
      ext = JSON.parse(config);
      ext.xmlToolbox = await core.readS3FileAsync(baseUrl + ext.toolbox);
    } else {
      config = await core.readGithubFileAsync(owner, repo, configPath);
      ext = JSON.parse(config);

      const octokit = new Octokit({
        auth: GITHUB_ACCESS_TOKEN,
      });
      const githubRepo = await octokit.request("GET /repos/{owner}/{repo}", {
        owner: owner,
        repo: repo,
      });
      ext.defaultBranch = githubRepo.data.default_branch;
      ext.xmlToolbox = await core.readGithubFileAsync(owner, repo, ext.toolbox);
    }

    ext.owner = owner;
    ext.repo = repo;
    ext.src = src;
    ext.id = `${owner}/${repo}`;

    const blocksPromise = getGithubBlocks(ext);
    const libsPromise = getGithubLibs(ext);
    const languagesPromise = getGithubLanguages(ext);
    ext.poster = getBaseUrl(ext) + ext.poster;
    ext.blocks = await blocksPromise;
    ext.libs = await libsPromise;
    ext.languages = await languagesPromise;

    return ext;
  } catch (error) {
    console.error("Import failed", error.message);
    return;
  }
};

export const getGithubBlocks = (ext) => {
  return new Promise((resolve, reject) => {
    const baseUrl = getBaseUrl(ext);
    let files = ext.blocks;

    Promise.all(files.map(async (f) => await fetch(baseUrl + f).then((resp) => resp.blob())))
      .then((blobs) => {
        Promise.all(blobs.map(async (blob) => await core.fileReadAsTextAsync(blob))).then(async (contents) => {
          let blocks = [];
          for (let i = 0; i < contents.length; i++) {
            blocks.push({ name: files[i], content: contents[i] });
          }
          resolve(blocks);
        });
      })
      .catch((error) => {
        console.error("Load extension blocks failed: ", error.message);
        resolve(null);
      });
  });
};

export const getGithubLanguages = (ext) => {
  return new Promise((resolve, reject) => {
    const baseUrl = getBaseUrl(ext);
    let files = [];
    let languages = ext.languages;
    for (let lang in languages) {
      files.push(languages[lang]);
    }

    Promise.all(files.map(async (f) => await fetch(baseUrl + f).then((resp) => resp.blob())))
      .then((blobs) => {
        Promise.all(blobs.map(async (blob) => await core.fileReadAsTextAsync(blob))).then(async (contents) => {
          let i = 0;
          for (let lang in languages) {
            languages[lang] = contents[i];
            i++;
          }
          resolve(languages);
        });
      })
      .catch((error) => {
        console.error("Load extension languages failed: ", error.message);
        resolve(null);
      });
  });
};

export const getGithubLibs = (ext) => {
  return new Promise((resolve, reject) => {
    const baseUrl = getBaseUrl(ext);
    let files = ext.libs;

    Promise.all(files.map(async (f) => await fetch(baseUrl + f).then((resp) => resp.blob())))
      .then((blobs) => {
        Promise.all(blobs.map(async (blob) => await core.fileReadAsTextAsync(blob))).then(async (contents) => {
          let libs = [];
          for (let i = 0; i < contents.length; i++) {
            libs.push({ name: files[i], content: contents[i] });
          }
          resolve(libs);
        });
      })
      .catch((error) => {
        console.error("Load extension libs failed: ", error.message);
        resolve(null);
      });
  });
};

function getBaseUrl(ext) {
  if (["thienuittc", "vanminh0910", "AITT-VN"].includes(ext.owner)) {
    return `https://ohstem-public.s3.ap-southeast-1.amazonaws.com/extensions/${ext.owner}/${ext.repo}/`;
  } else {
    return `https://raw.githubusercontent.com/${ext.owner}/${ext.repo}/${ext.defaultBranch}/`;
  }
}
