import React, { Component } from "react";
import { Button, DropdownMenu, Item } from "../../components/Common";
import { t } from "../../utils/core";

class EditorToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openBlocks = () => {
    this.props.parent.openBlocks();
  };

  openPython = () => {
    this.props.parent.openPython();
  };

  toggleConnect = () => {
    this.props.parent.toggleConnect();
  };

  download = () => {
    this.props.parent.download();
  };

  serial = () => {
    this.props.parent.serial();
  };

  chartSerial = () => {
    this.props.parent.chartSerial();
  };

  toggleSelect = () => {
    this.props.parent.toggleSelect();
  };

  openNewTab = () => {
    this.props.parent.openNewTab();
  };

  render() {
    let connectStatusIcon = "linkify";
    let connectStatusText = t("editorToolbar.connect");
    if (this.props.connectStatus) {
      connectStatusIcon = "unlink";
      connectStatusText = t("editorToolbar.disconnect");
    }

    return (
      <div className="ui equal width grid right aligned padded">
        <div className="column">
          <div className="ui grid equal width editor-toolbar">
            <div className="column left aligned">
              <EditorToolbarButton
                icon={connectStatusIcon}
                className="large editortools-btn connect-btn"
                title="Connect"
                text={connectStatusText}
                onButtonClick={this.toggleConnect}
              />
              <DropdownMenu
                role="menuitem"
                icon={"ellipsis horizontal"}
                textClass="landscape only"
                className="ui button icon large editortools-btn select-btn"
                title="Devices"
              >
                <Item
                  className="device-menu-item"
                  role="menuitem"
                  icon="microchip"
                  text={t("Select device")}
                  onClick={this.toggleSelect}
                />
                <Item
                  className="device-menu-item"
                  role="menuitem"
                  icon="setting"
                  text={t("Setting device")}
                  onClick={this.openNewTab}
                />
              </DropdownMenu>
              <span className="name-device-editor-toobar">
                {this.props.currentDevice?.title}
              </span>
            </div>
            <div className="column center aligned">
              <EditorToolbarButton
                icon="download"
                className="primary large download-button"
                title="Download"
                text={t("editorToolbar.download")}
                onButtonClick={this.download}
              />
            </div>
            <div className="column right aligned">
              {/* <EditorToolbarButton
                icon="line chart"
                className="large editortools-btn"
                title="Chart"
                text={t("Chart")}
                onButtonClick={this.chartSerial}
              /> */}
              <EditorToolbarButton
                icon="bug"
                className="large editortools-btn"
                title="Serial"
                text="Serial"
                onButtonClick={this.serial}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditorToolbar;

class EditorToolbarButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    const { onButtonClick, view } = this.props;
    onButtonClick(view);
  };

  render() {
    const { onClick, onButtonClick, ...rest } = this.props;
    return <Button {...rest} onClick={this.handleClick} />;
  }
}
