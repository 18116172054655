module.exports = {
    
    id: "https://ohstem.vn/may-phan-loai-keo-theo-mau-sac/",
    title: "Máy phân loại kẹo theo màu sắc",
    detail:
      'Mua một túi kẹo M&M đầy màu sắc nhưng đã bao giờ bạn muốn tách riêng từng màu ra chưa? Nếu có hãy cùng nhau làm một chiếc máy phân loại thôi!',
    image: "/assets/tutorials/courses/keo.jpg",
    categoryIds: ["creation"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  