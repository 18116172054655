module.exports = {
  status : "tutorial",
  id: "den-thong-minh",
  title: "Đèn thông minh",
  detail: "Bài này hướng dẫn cách làm cho đèn led tự điều chỉnh độ sáng tùy thuộc vào độ sáng môi trường.",
  image: "/assets/tutorials/den-thong-minh/den-thong-minh.jpg",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_forever"]
    },
    {
      category: "input",
      blocks: ["yolobit_input_get_light_level"]
    },
    {
      category: "led",
      blocks: ["yolobit_led_set_all", "yolobit_led_set_brightness"]
    },
    {
      category: "math",
      blocks: ["math_arithmetic"]
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Bài này hướng dẫn cách làm cho đèn led tự điều chỉnh độ sáng tùy thuộc vào độ sáng môi trường.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/den-thong-minh/den-thong-minh.jpg">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p>Kéo khối <block class="led">điều chỉnh độ sáng của đèn led thành 100</block> trong mục <block class="led">LED</block> ra và gắn vào khối <block class="basic">lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/den-thong-minh/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Kéo khối <block class="math">tính toán hai số</block> trong mục <block class="input">TÍNH TOÁN</block> ra và gắn vào khối <block class="led">điều chỉnh độ sáng của đèn led</block> thay cho số 100.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/den-thong-minh/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Thay số 1 đầu tiên trong khối <block class="math">1 + 1</block> thành 30, đổi dấu cộng (+) thành trừ (-).</p>
        <p>Kéo khối <block class="input">mức độ sáng</block> trong mục <block class="input">NGÕ VÀO</block> ra thay cho số 1 thứ hai.</p>
        <p>Như vậy nếu trời càng tối độ sáng của đèn led sẽ càng tăng và ngược lại. Bạn có thể thay đổi số 30 tuỳ thuộc vào độ sáng đo được trong bài <b>Hiển thị độ sáng</b> đã làm trước đó.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/den-thong-minh/step3.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p>Kéo khối <block class="led">đổi màu tất cả các led</block> trong mục <block class="led">LED</block> ra và gắn vào dưới khối <block class="led">điều chỉnh độ sáng của đèn led</block>. Sau khi điều chỉnh độ sáng, ta đổi màu các led để cập nhật độ sáng mới.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/den-thong-minh/step4.png">
        </div>
      </div>`
    },
    {
      title: "Bước 5",
      content: `<div>
        <p>Cuối cùng nhấn vào <b>Chạy</b> để chạy chương trình cho Yolo:Bit và quan sát kết quả khi bạn lấy tay che cảm biến ánh sáng ở góc phải của Yolo:Bit.</p>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
        <p>Hãy điều chỉnh chương trình để màn hình led sáng màu xanh lá thay vì màu đỏ, chương trình cập nhật độ sáng sau mỗi 5 giây 1 lần.</p>
      </div>`
    }
  ]
};
