module.exports = {
  status:"hardware",
    //id: "https://ohstem.vn/knowledge-base/physbit/",
    id:"https://ohshop.vn/product/physbit-bo-kit-hoc-vat-ly-dien-tu/",
    title: "Phy:Bit",
    detail:
      'Phys:Bit là bộ kit giúp trẻ tìm hiểu và thực hành các kiến thức cơ bản về mạch điện một cách sinh động và thú vị. Bộ kit gồm các linh kiện cơ bản thường được sử dụng trong các mạch điện thông dụng.',
    image: "/assets/tutorials/courses/physbit.jpg",
    categoryIds: ["hardware"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  