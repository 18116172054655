export function isIFrame() {
  try {
    return window && window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function hasNavigator() {
  return typeof navigator !== "undefined";
}

export function isWindows() {
  return hasNavigator() && /(Win32|Win64|WOW64)/i.test(navigator.platform);
}

export function isWindows10() {
  return (
    hasNavigator() && /(Win32|Win64|WOW64)/i.test(navigator.platform) && /Windows NT 10/i.test(navigator.userAgent)
  );
}

export function isMobile() {
  return hasNavigator() && /mobi/i.test(navigator.userAgent);
}

export function isIOS() {
  return hasNavigator() && /iPad|iPhone|iPod/.test(navigator.userAgent);
}

//MacIntel on modern Macs
export function isMac() {
  return hasNavigator() && /Mac/i.test(navigator.platform);
}

//This is generally appears for Linux
//Android *sometimes* returns this
export function isLinux() {
  return !!navigator && /Linux/i.test(navigator.platform);
}

// Detects if we are running on ARM (Raspberry pi)
export function isARM() {
  return hasNavigator() && /arm/i.test(navigator.platform);
}

// Detects if we are running inside the UWP runtime (Microsoft Edge)
export function isUwpEdge() {
  return typeof window !== "undefined" && !!window.Windows;
}

/*
Notes on browser detection

Actually:             Claims to be:
                    IE  MicrosoftEdge    Chrome  Safari  Firefox
        IE          X                           X?
Microsoft Edge                    X       X       X
        Chrome                          X       X
        Safari                                  X       X
        Firefox                                         X

I allow Opera to go about claiming to be Chrome because it might as well be
*/

//Microsoft Edge lies about its user agent and claims to be Chrome, but Microsoft Edge/Version
//is always at the end
export function isEdge() {
  return hasNavigator() && /Edge/i.test(navigator.userAgent);
}

//IE11 also lies about its user agent, but has Trident appear somewhere in
//the user agent. Detecting the different between IE11 and Microsoft Edge isn't
//super-important because the UI is similar enough
export function isIE() {
  return hasNavigator() && /Trident/i.test(navigator.userAgent);
}

//Microsoft Edge and IE11 lie about being Chrome
export function isChrome() {
  return (
    !isEdge() &&
    !isIE() &&
    !!navigator &&
    (/Chrome/i.test(navigator.userAgent) || /Chromium/i.test(navigator.userAgent))
  );
}

//Chrome and Microsoft Edge lie about being Safari
export function isSafari() {
  //Could also check isMac but I don't want to risk excluding iOS
  //Checking for iPhone, iPod or iPad as well as Safari in order to detect home screen browsers on iOS
  return !isChrome() && !isEdge() && !!navigator && /(Macintosh|Safari|iPod|iPhone|iPad)/i.test(navigator.userAgent);
}

//Safari and WebKit lie about being Firefox
export function isFirefox() {
  return !isSafari() && !!navigator && (/Firefox/i.test(navigator.userAgent) || /Seamonkey/i.test(navigator.userAgent));
}

//These days Opera's core is based on Chromium so we shouldn't distinguish between them too much
export function isOpera() {
  return hasNavigator() && /Opera|OPR/i.test(navigator.userAgent);
}

//Midori *was* the default browser on Raspbian, however isn't any more
export function isMidori() {
  return hasNavigator() && /Midori/i.test(navigator.userAgent);
}

//Epiphany (code name for GNOME Web) is the default browser on Raspberry Pi
//Epiphany also lies about being Chrome, Safari, and Chromium
export function isEpiphany() {
  return hasNavigator() && /Epiphany/i.test(navigator.userAgent);
}

export function isTouchEnabled() {
  return (
    typeof window !== "undefined" &&
    ("ontouchstart" in window || // works on most browsers
      (navigator && navigator.maxTouchPoints > 0))
  ); // works on IE10/11 and Surface);
}

export function isPxtElectron() {
  return typeof window != "undefined" && !!window.pxtElectron;
}

export function isIpcRenderer() {
  return typeof window != "undefined" && !!window.ipcRenderer;
}
export function isElectron() {
  return isPxtElectron() || isIpcRenderer();
}

export function hasPointerEvents() {
  return typeof window != "undefined" && !!window.PointerEvent;
}

export function hasSaveAs() {
  return isEdge() || isIE() || isFirefox();
}

export function os() {
  if (isWindows()) return "windows";
  else if (isMac()) return "mac";
  else if (isLinux() && isARM()) return "rpi";
  else if (isLinux()) return "linux";
  else return "unknown";
}

export function browser() {
  if (isEdge()) return "edge";
  if (isEpiphany()) return "epiphany";
  else if (isMidori()) return "midori";
  else if (isOpera()) return "opera";
  else if (isIE()) return "ie";
  else if (isChrome()) return "chrome";
  else if (isSafari()) return "safari";
  else if (isFirefox()) return "firefox";
  else return "unknown";
}

export function popupWindow(url, title, popUpWidth, popUpHeight) {
  try {
    const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
    const winTop = window.screenTop ? window.screenTop : window.screenY;
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const left = width / 2 - popUpWidth / 2 + winLeft;
    const top = height / 2 - popUpHeight / 2 + winTop;

    const popupWindow = window.open(
      url,
      title,
      "width=" + popUpWidth + ", height=" + popUpHeight + ", top=" + top + ", left=" + left
    );
    if (popupWindow.focus) {
      popupWindow.focus();
    }

    return popupWindow;
  } catch (e) {
    return null;
  }
}
