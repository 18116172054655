import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as ReactDOM from "react-dom";
import * as ReactModal from "react-modal";
import * as ReactTooltip from "react-tooltip";
import * as core from "../utils/core";
import * as browserUtils from "../utils/browser";
import { t } from "../utils/core";

export const appElement = document.getElementById("content");

export function cx(classes) {
  return classes.filter(c => !!c && c.trim() !== "").join(" ");
}

function genericClassName(cls, props, ignoreIcon = false) {
  return `${cls} ${
    ignoreIcon ? "" : props.icon && props.text ? "icon icon-and-text" : props.icon ? "icon" : ""
  } ${props.className || ""}`;
}

function genericContent(props) {
  let retVal = [
    props.icon ? (
      <Icon
        key="iconkey"
        icon={props.icon + (props.text ? " icon-and-text " : "") + (props.iconClass ? " " + props.iconClass : "")}
      />
    ) : null,
    props.text ? (
      <span key="textkey" className={"ui text" + (props.textClass ? " " + props.textClass : "")}>
        {props.text}
      </span>
    ) : null
  ];
  if (props.icon && props.rightIcon) retVal = retVal.reverse();
  return retVal;
}

export function fireClickOnEnter(e) {
  const charCode = core.keyCodeFromEvent(e);
  if (charCode === core.ENTER_KEY || charCode === core.SPACE_KEY) {
    e.preventDefault();
    e.currentTarget.click();
  }
}

///////////////////////////////////////////////////////////
////////////             Icons                /////////////
///////////////////////////////////////////////////////////

export const Icon = props => {
  const { icon, className, onClick, children, onKeyDown, ...rest } = props;
  return (
    <i
      className={`icon ${icon} ${className ? className : ""}`}
      onClick={onClick}
      onKeyDown={onKeyDown || fireClickOnEnter}
      aria-hidden={true}
      role="presentation"
      {...rest}
    >
      {children}
    </i>
  );
};

///////////////////////////////////////////////////////////
////////////           Dropdowns              /////////////
///////////////////////////////////////////////////////////

export class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, focus: false };
  }

  show = () => {
    this.setState({ open: true, focus: true });
  };

  hide = () => {
    this.setState({ open: false });
  };

  toggle = () => {
    if (this.state.open) {
      this.hide();
    } else {
      this.show();
    }
  };

  focus(el) {
    this.setActive(el);
    el.focus();
  }

  blur(el) {
    if (this.isActive(el)) {
      el.classList.remove("active");
    }
  }

  setActive(el) {
    if (!this.isActive(el)) {
      el.classList.add("active");
    }
  }

  isActive(el) {
    return el && el.classList.contains("active");
  }

  getChildren() {
    const menu = this.refs["menu"];
    const children = [];
    for (let i = 0; i < menu.childNodes.length; i++) {
      const child = menu.childNodes[i];
      // Remove separators
      if (child.classList.contains("divider")) continue;
      // Check if item is intended for mobile only views
      if (child.classList.contains("mobile") && !browserUtils.isMobile()) continue;
      children.push(child);
    }
    return children;
  }

  isChildFocused() {
    const children = this.getChildren();
    for (let i = 0; i < children.length; i++) {
      if (document.activeElement === children[i]) return true;
    }
    return false;
  }

  navigateToNextElement = (e, prev, next) => {
    const dropdown = this.refs["dropdown"];
    const charCode = core.keyCodeFromEvent(e);
    const current = e.currentTarget;
    if (charCode === 40 /* Down arrow */) {
      e.preventDefault();
      e.stopPropagation();
      if (next) {
        this.focus(next);
      }
    } else if (charCode === 38 /* Up arrow */) {
      e.preventDefault();
      e.stopPropagation();
      if (prev) {
        this.focus(prev);
      } else {
        // Prev is undefined, go to dropdown
        dropdown.focus();
        this.setState({ open: false });
      }
    } else if (charCode === core.SPACE_KEY || charCode === core.ENTER_KEY) {
      // Trigger click
      e.preventDefault();
      e.stopPropagation();
      current.click();
    }
  };

  componentDidMount() {
    const children = this.getChildren();
    for (let i = 0; i < children.length; i++) {
      const prev = i > 0 ? children[i - 1] : undefined;
      const child = children[i];
      const next = i < children.length ? children[i + 1] : undefined;

      child.addEventListener("keydown", e => {
        this.navigateToNextElement(e, prev, next);
      });

      child.addEventListener("focus", e => {
        this.setActive(child);
      });
      child.addEventListener("blur", e => {
        this.blur(child);
      });

      if (i === children.length - 1) {
        // set tab on last child to clear focus
        child.addEventListener("keydown", e => {
          const charCode = core.keyCodeFromEvent(e);
          if (!e.shiftKey && charCode === core.TAB_KEY) {
            this.hide();
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Remove active from all menu items on any update
    const children = this.getChildren();
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      // On allow tabbing to valid child nodes (ie: no separators or mobile only items)
      child.tabIndex = this.state.open ? 0 : -1;
    }

    if (!prevState.focus && this.state.focus) {
      // Dropdown focused
    } else if (prevState.focus && !this.state.focus) {
      // Dropdown blurred
      if (!this.isMouseDown) {
        this.hide();
      }
    }

    if (!prevState.open && this.state.open) {
      // Dropdown opened
      document.addEventListener("keydown", this.closeOnEscape);
    } else if (prevState.open && !this.state.open) {
      // Dropdown closed
      document.removeEventListener("keydown", this.closeOnEscape);
      this.handleClose();
    }
    if (this.focusFirst && children.length > 0) {
      // Focus the first child
      this.focus(children[0]);
      this.focusFirst = false;
    }
  }

  closeOnEscape = e => {
    const charCode = core.keyCodeFromEvent(e);
    if (charCode === core.ESC_KEY) {
      e.preventDefault();
      const dropdown = this.refs["dropdown"];
      dropdown.focus();
      // Reset the focus handlers
      this.isMouseDown = true;
      this.hide();
    }
  };

  handleClick = e => {
    this.toggle();
    e.stopPropagation();
  };

  handleClose = () => {
    this.isMouseDown = false;
    const hasFocus = document.activeElement === this.refs["dropdown"];
    this.setState({ focus: hasFocus });
  };

  handleFocus = e => {
    const { focus } = this.state;
    if (focus) return;

    this.setState({ focus: true });
  };

  handleBlur = e => {
    if (this.isMouseDown) return;
    // Use timeout to delay examination of activeElement until after blur/focus
    // events have been processed.
    setTimeout(() => {
      let open = this.isChildFocused();
      this.setState({ focus: open });
    }, 1);
  };

  focusFirst;
  handleKeyDown = e => {
    const charCode = core.keyCodeFromEvent(e);
    if (charCode === 40 /* Down arrow key */) {
      e.preventDefault();
      this.focusFirst = true;
      this.show();
    } else if (charCode === core.SPACE_KEY || charCode === core.ENTER_KEY) {
      e.preventDefault();
      this.toggle();
    }
  };

  render() {
    const { disabled, title, role, icon, className, children } = this.props;
    const { open } = this.state;

    const aria = {
      role: role || "combobox",
      "aria-disabled": disabled,
      "aria-haspopup": !!disabled,
      "aria-expanded": open
    };
    const menuAria = {
      role: "menu",
      "aria-label": `Dropdown menu ${title}`,
      "aria-hidden": !!open
    };
    const classes = cx(["ui", open ? "active visible" : "", "dropdown", icon ? "icon" : "", className || ""]);
    const menuClasses = cx(["menu", open ? "visible transition" : ""]);
    let dropdown = (
      <div
        role="listbox"
        ref="dropdown"
        title={title}
        {...aria}
        className={classes}
        onMouseDown={this.handleMouseDown}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        tabIndex={0}
      >
        {genericContent(this.props)}
        <div ref="menu" {...menuAria} className={menuClasses} role="menu">
          {children}
        </div>
      </div>
    );
    // Tooltips don't work great on IOS, disabling them
    return this.props.tooltipId && !browserUtils.isIOS() ? (
      <Tooltip
        id={this.props.tooltipId}
        content={this.props.tooltip || this.props.title}
        place={this.props.tooltipPlace}
        delayShow={this.props.tooltipDelayShow}
      >
        {dropdown}
      </Tooltip>
    ) : (
      dropdown
    );
  }
}

///////////////////////////////////////////////////////////
////////////             Items                /////////////
///////////////////////////////////////////////////////////

export class Item extends Component {
  render() {
    const { text, title, ariaLabel } = this.props;

    return (
      <div
        className={genericClassName("ui item link", this.props, true) + ` ${this.props.active ? "active" : ""}`}
        role={this.props.role}
        aria-label={ariaLabel || title || text}
        title={title || text}
        tabIndex={this.props.tabIndex || 0}
        key={this.props.value}
        data-value={this.props.value}
        onClick={this.props.onClick}
        onKeyDown={this.props.onKeyDown || fireClickOnEnter}
      >
        {genericContent(this.props)}
        {this.props.children}
      </div>
    );
  }
}

export class ButtonMenuItem extends Component {
  render() {
    return (
      <div
        className={genericClassName("ui item link", this.props, true) + ` ${this.props.active ? "active" : ""}`}
        role={this.props.role}
        title={this.props.title || this.props.text}
        tabIndex={this.props.tabIndex || 0}
        key={this.props.value}
        data-value={this.props.value}
        onClick={this.props.onClick}
        onKeyDown={this.props.onKeyDown || fireClickOnEnter}
      >
        <div className={genericClassName("ui button", this.props)}>
          {genericContent(this.props)}
          {this.props.children}
        </div>
      </div>
    );
  }
}

///////////////////////////////////////////////////////////
////////////            Buttons               /////////////
///////////////////////////////////////////////////////////

export class Button extends Component {
  render() {
    const { color, size, disabled, loading } = this.props;
    const classes = cx([
      color,
      size,
      disabled || loading ? "disabled" : "",
      loading ? "loading" : "",
      genericClassName("ui button", this.props)
    ]);
    const button = (
      <button
        className={classes}
        id={this.props.id}
        role={this.props.role}
        title={this.props.title}
        tabIndex={this.props.tabIndex || 0}
        aria-label={this.props.ariaLabel}
        aria-expanded={this.props.ariaExpanded}
        onClick={this.props.onClick}
        onKeyDown={this.props.onKeyDown}
      >
        {genericContent(this.props)}
        {this.props.children}
      </button>
    );
    // Tooltips don't work great on IOS, disabling them
    return this.props.tooltipId && !browserUtils.isIOS() ? (
      <Tooltip
        id={this.props.tooltipId}
        content={this.props.tooltip || this.props.title}
        place={this.props.tooltipPlace}
        delayShow={this.props.tooltipDelayShow}
      >
        {button}
      </Tooltip>
    ) : (
      button
    );
  }
}

///////////////////////////////////////////////////////////
////////////           FormField              /////////////
///////////////////////////////////////////////////////////

export class Field extends Component {
  render() {
    return (
      <div className="field">
        {this.props.label ? (
          <label htmlFor={!this.props.ariaLabel ? this.props.htmlFor : undefined}>{this.props.label}</label>
        ) : null}
        {this.props.ariaLabel && this.props.htmlFor ? (
          <label htmlFor={this.props.htmlFor} className="accessible-hidden">
            {this.props.ariaLabel}
          </label>
        ) : (
          ""
        )}
        {this.props.children}
      </div>
    );
  }
}

///////////////////////////////////////////////////////////
////////////             Input                /////////////
///////////////////////////////////////////////////////////

export class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ value: newProps.value });
  }

  clearValue() {
    this.setState({ value: undefined });
  }

  copy = () => {
    const p = this.props;
    const el = ReactDOM.findDOMNode(this);

    if (!p.lines || p.lines === 1) {
      const inp = el.getElementsByTagName("input")[0];
      inp.focus();
      inp.setSelectionRange(0, 9999);
    } else {
      const inp = el.getElementsByTagName("textarea")[0];
      inp.focus();
      inp.setSelectionRange(0, 9999);
    }

    try {
      document.execCommand("copy");
    } catch (e) {}
  };

  handleClick = e => {
    if (this.props.selectOnClick) {
      e.target.setSelectionRange(0, 9999);
    }
  };

  handleChange = e => {
    const newValue = e.target.value;
    if (!this.props.readOnly && (!this.state || this.state.value !== newValue)) {
      this.setState({ value: newValue });
    }
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  };

  render() {
    let p = this.props;
    let copyBtn =
      p.copy && document.queryCommandSupported("copy") ? (
        <Button className="ui right labeled primary icon button" text={t("Copy")} icon="copy" onClick={this.copy} />
      ) : null;
    const { value } = this.state;

    return (
      <Field ariaLabel={p.ariaLabel} htmlFor={p.id} label={p.label}>
        <div
          className={
            "ui input" +
            (p.inputLabel ? " labelled" : "") +
            (p.copy ? " action fluid" : "") +
            (p.disabled ? " disabled" : "")
          }
        >
          {p.inputLabel ? <div className="ui label">{p.inputLabel}</div> : ""}
          {!p.lines || p.lines === 1 ? (
            <input
              autoFocus={p.autoFocus}
              id={p.id}
              className={p.class || ""}
              type={p.type || "text"}
              placeholder={p.placeholder}
              value={value || ""}
              readOnly={!!p.readOnly}
              onClick={this.handleClick}
              onChange={this.handleChange}
              autoComplete={p.autoComplete ? "" : "off"}
              autoCorrect={p.autoComplete ? "" : "off"}
              autoCapitalize={p.autoComplete ? "" : "off"}
              spellCheck={p.autoComplete}
            />
          ) : (
            <textarea
              id={p.id}
              className={"ui input " + (p.class || "") + (p.inputLabel ? " labelled" : "")}
              rows={p.lines}
              placeholder={p.placeholder}
              value={value || ""}
              readOnly={!!p.readOnly}
              onClick={this.handleClick}
              onChange={this.handleChange}
            />
          )}
          {copyBtn}
        </div>
      </Field>
    );
  }
}

///////////////////////////////////////////////////////////
////////////             Modal                /////////////
///////////////////////////////////////////////////////////

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.id = core.guidGen();
    this.state = {};
  }

  afterOpen = () => {
    const { modalDidOpen } = this.props;
    this.setState({ scrolling: false });
    this.setPositionAndClassNames();
    if (modalDidOpen) modalDidOpen(this.getRef());
  };

  onClose() {
    cancelAnimationFrame(this.animationRequestId);
  }

  getRef() {
    const modal = this.refs["modal"];
    const ref = modal && modal.node && modal.node.firstChild && modal.node.firstChild.firstChild;
    return ref;
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationRequestId);
  }

  setPositionAndClassNames = () => {
    const { dimmer } = this.props;
    let classes;

    if (dimmer) {
      classes = "dimmable dimmed";

      if (dimmer === "blurring") {
        classes += " blurring";
      }
    }

    const newState = {};
    const ref = this.getRef();

    if (ref) {
      const { height } = ref.getBoundingClientRect();

      const marginTop = -Math.round(height / 2);
      const scrolling = height >= window.innerHeight;

      if (this.state.marginTop !== marginTop) {
        newState.marginTop = marginTop;
      }

      if (this.state.scrolling !== scrolling) {
        newState.scrolling = scrolling;
      }

      if (scrolling) classes += " scrolling";
    }

    if (this.state.mountClasses !== classes) newState.mountClasses = classes;

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
      if (this.props.onPositionChanged) this.props.onPositionChanged(this.props);
    }

    this.animationRequestId = requestAnimationFrame(this.setPositionAndClassNames);
  };

  onRequestClose = () => {
    const { onClose } = this.props;
    this.onClose();
    onClose();
  };

  render() {
    const {
      isOpen,
      size,
      longer,
      basic,
      className,
      onClose,
      closeIcon,
      children,
      onKeyDown,
      header,
      headerClass,
      headerActions,
      helpUrl,
      description,
      closeOnDimmerClick,
      closeOnDocumentClick,
      closeOnEscape,
      shouldCloseOnEsc,
      shouldCloseOnOverlayClick,
      shouldFocusAfterRender,
      ...rest
    } = this.props;
    const { marginTop, scrolling, mountClasses } = this.state;
    const isFullscreen = size === "fullscreen";
    const showBack = isFullscreen && !!closeIcon;

    const classes = cx([
      "ui",
      size,
      longer ? "longer" : "",
      basic ? "basic" : "",
      scrolling ? "scrolling" : "",
      closeIcon ? "closable" : "",
      "modal transition visible active",
      className
    ]);
    const portalClassName = mountClasses;
    const aria = {
      labelledby: header ? this.id + "title" : undefined,
      describedby: description ? this.id + "description" : this.id + "desc"
    };
    const customStyles = {
      content: {
        marginTop: marginTop
      }
    };

    return (
      <ReactModal
        isOpen={isOpen}
        ref="modal"
        appElement={appElement}
        onRequestClose={this.onRequestClose}
        onAfterOpen={this.afterOpen}
        shouldReturnFocusAfterClose={true}
        shouldFocusAfterRender={shouldFocusAfterRender}
        shouldCloseOnEsc={shouldCloseOnEsc || closeOnEscape}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick || (closeOnDocumentClick || closeOnDimmerClick)}
        portalClassName={portalClassName}
        overlayClassName={`ui page modals dimmer transition ${isOpen ? "visible active" : ""}`}
        className={classes}
        style={customStyles}
        aria={aria}
        {...rest}
      >
        {header || showBack || helpUrl ? (
          <div id={this.id + "title"} className={"header " + (headerClass || "")}>
            <span
              className="header-title"
              style={{ margin: `0 ${helpUrl ? "-20rem" : "0"} 0 ${showBack ? "-20rem" : "0"}` }}
            >
              {header}
            </span>
            {showBack ? (
              <div className="header-close">
                <Button
                  className="back-button large"
                  title={t("Go back")}
                  onClick={onClose}
                  tabIndex={0}
                  onKeyDown={fireClickOnEnter}
                >
                  <Icon icon="arrow left" />
                  <span className="ui text landscape only">{t("Go back")}</span>
                </Button>
              </div>
            ) : (
              undefined
            )}
            {helpUrl ? (
              <div className="header-help">
                <a
                  className={`ui icon help-button`}
                  href={helpUrl}
                  target="_docs"
                  role="button"
                  aria-label={`Help on ${header} dialog`}
                >
                  <Icon icon="help" />
                </a>
              </div>
            ) : (
              undefined
            )}
          </div>
        ) : (
          undefined
        )}
        {isFullscreen && headerActions ? <div className="header-actions">{headerActions}</div> : undefined}
        {!isFullscreen && description ? (
          <label id={this.id + "description"} className="accessible-hidden">
            {description}
          </label>
        ) : (
          undefined
        )}
        <div
          id={this.id + "desc"}
          className={`${longer ? "scrolling" : ""} ${headerActions ? "has-actions" : ""} content`}
        >
          {children}
        </div>
        {!isFullscreen && this.props.buttons && this.props.buttons.length > 0 ? (
          <div className="actions">
            {this.props.buttons.map(action =>
              action.url ? (
                <Link
                  key={`action_${action.label}`}
                  icon={action.icon}
                  text={action.label}
                  className={`ui button approve ${action.icon ? "icon right labeled" : ""} ${action.className || ""} ${
                    action.loading ? "loading disabled" : ""
                  } ${action.disabled ? "disabled" : ""}`}
                  href={action.url}
                  target={!action.fileName ? "_blank" : undefined}
                  download={action.fileName ? core.htmlEscape(action.fileName) : undefined}
                />
              ) : (
                <ModalButtonElement key={`action_${action.label}`} {...action} />
              )
            )}
          </div>
        ) : (
          undefined
        )}
        {!isFullscreen && closeIcon ? (
          <div role="button" className="closeIcon" tabIndex={0} onClick={onClose} onKeyDown={fireClickOnEnter}>
            <Icon icon="close remove circle" />{" "}
          </div>
        ) : (
          undefined
        )}
      </ReactModal>
    );
  }
}

class ModalButtonElement extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (!this.props.disabled) this.props.onclick();
  }

  render() {
    const action = this.props;
    return (
      <Button
        icon={action.icon}
        text={action.label}
        className={`approve ${action.icon ? "icon right labeled" : ""} ${action.className || ""} ${
          action.loading ? "loading disabled" : ""
        } ${action.disabled ? "disabled" : ""}`}
        onClick={this.handleClick}
        onKeyDown={fireClickOnEnter}
      />
    );
  }
}

///////////////////////////////////////////////////////////
////////////             Dimmer               /////////////
///////////////////////////////////////////////////////////

export class Dimmer extends Component {
  render() {
    const { disabled, inverted, page, simple, closable, onClose, active, children, ...rest } = this.props;
    const portalClasses = cx([
      "ui dimmer",
      active ? "active transition visible" : "",
      disabled ? "disabled" : "",
      inverted ? "inverted" : "",
      page ? "page" : "",
      simple ? "simple" : ""
    ]);
    const customStyles = {
      content: {
        background: "none",
        border: "0"
      }
    };
    return (
      <ReactModal
        appElement={appElement}
        style={customStyles}
        shouldCloseOnOverlayClick={closable}
        onRequestClose={onClose}
        overlayClassName={portalClasses}
        {...rest}
      >
        {children}
      </ReactModal>
    );
  }
}

///////////////////////////////////////////////////////////
////////////             Loader               /////////////
///////////////////////////////////////////////////////////

export class Loader extends Component {
  render() {
    const { active, children, disabled, inverted, size, className } = this.props;
    const classes = cx([
      "ui loader",
      size,
      active ? "active" : "",
      disabled ? "disabled" : "",
      inverted ? "inverted" : "",
      children ? "text" : "",
      className
    ]);
    return <div className={classes}>{children}</div>;
  }
}

///////////////////////////////////////////////////////////
////////////           Tooltip                /////////////
///////////////////////////////////////////////////////////

export class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { id, content, className, ...rest } = this.props;

    return (
      <div>
        <div data-tip="tooltip" data-for={id}>
          {this.props.children}
        </div>
        <ReactTooltip id={id} className={`pxt-tooltip ${className || ""}`} effect="solid" {...rest}>
          {content}
        </ReactTooltip>
      </div>
    );
  }
}
