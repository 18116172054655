module.exports = {
    status:"hardware",
    //id: "https://ohstem.vn/knowledge-base/arduino-kit/",
    id:"https://ohshop.vn/product/arduino-kit-hoc-lap-trinh-arduino/",
    title: "Arduino Kit",
    detail:
      'Arduino Kit là bộ kit thích hợp với các bạn bắt đầu học lập trình với Aruduino. Bộ sản phẩm bao gồm module chính Node WiFi 32 và 12 module cảm biến, ngoại vi.',
    image: "/assets/tutorials/courses/arduinoAll.jpg",
    categoryIds: ["hardware"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  