import React, { Component } from "react";
import SettingsMenu from "./SettingsMenu";
import { withTranslation } from "react-i18next";
import { Item } from "../../components/Common";

class EditorMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openBlocks = () => {
    this.props.parent.openBlocks();
  };

  openPython = () => {
    this.props.parent.openPython();
  };

  openGuide = () => {
    console.log("openGuide");
  };

  saveProject = () => {
    this.props.parent.saveEditorProject();
  };

  renameProject = () => {
    this.props.parent.renameProject();
  };

  removeProject = () => {
    this.props.parent.removeProjectAndGoHome();
  };

  exportProject = () => {
    this.props.parent.exportProject();
  };

  shareProject = () => {
    this.props.parent.shareProject();
  };

  goHome = () => {
    this.props.parent.goHome();
  };

  updateFirmware = () => {
    this.props.parent.updateFirmware();
  };

  updateInstalledExtLibraries = () => {
    this.props.parent.updateInstalledExtLibraries();
  };

  render() {
    const blockActive = this.props.parent.isBlocksActive();
    const pythonActive = this.props.parent.isPythonActive();
    const { t } = this.props;

    return (
      <div className="menubar" role="banner">
        <div id="mainmenu" className="ui borderless fixed inverted menu" role="menubar">
          <div className="left menu">
            <Item
              className="icon openproject "
              role="menuitem"
              text={t("Home")}
              textClass="landscape only"
              icon="home large"
              onClick={this.goHome}
            />
          </div>
          <div className="ui item link editor-menuitem">
            <div className="ui grid padded">
              <Item
                className="blocks-menuitem"
                role="menuitem"
                textClass="landscape only"
                text={t("Blocks")}
                icon="xicon blocks"
                active={blockActive}
                onClick={this.openBlocks}
              />
              <Item
                className="python-menuitem"
                role="menuitem"
                textClass="landscape only"
                text={t("Python")}
                icon="python"
                active={pythonActive}
                onClick={this.openPython}
              />
              <div className="ui item toggle" />
            </div>
          </div>
          <div className="right menu">
            <SettingsMenu parent={this} projectName={this.props.parent.state.name} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EditorMenu);
