module.exports = {
    
    id: "https://ohstem.vn/hop-vo-dung/",
    title: "Hộp vô dụng",
    detail:
      'Bạn đang cảm thấy chán nản? Bạn cảm thấy stress? Vậy hãy cùng nhau làm 1 chiệc hộp vô dụng nhưng không vô dụng nhé! :D',
    image: "/assets/tutorials/courses/hopvodung.jpg",
    categoryIds: ["creation"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  