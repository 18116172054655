module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/phan-co-ban/",
    title: "Làm Quen Lập Trình Với Yolo:Bit",
    detail:
      'Khóa học làm quen với các khái niệm lập trình cơ bản cùng Yolo:Bit.',
    image: "/assets/tutorials/courses/lamquen.jpg",
    categoryIds: ["courses"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  