module.exports = {
  status : "tutorial",
  id: "analog-output",
  title: "Analog output",
  detail:
    "Analog output có thể ứng dụng trong nhiều trường hợp như điều chỉnh độ sáng của đèn, điều khiển tốc độ của động cơ… ",
  image: "/assets/tutorials/analog-output/analog-output.png",
  categoryIds: ["tutorial"],
  toolbox: [
    {
      category: "basic",
      blocks: ["yolobit_basic_forever", "yolobit_basic_sleep"]
    },
    {
      category: "loops",
      blocks: ["controls_for"]
    },
    {
      category: "advanced",
      blocks: ["yolobit_pin_write_analog"]
    },
    {
      category: "pins",
      blocks: ["yolobit_pin_write_analog"]
    },
    {
      category: "variables"
    }
  ],
  steps: [
    {
      title: "Giới thiệu",
      content: `<div>
        <p>Trong bài viết này chúng ta sẽ dùng analog output để điều khiển độ sáng của đèn led có sẵn trên mặt sau của board, led này được nối với pin 13. Ta sẽ thực hiện chương trình điều khiển đèn sáng dần với 4 mức sáng khác nhau.</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/analog-output/analog-output.png">
        </div>
      </div>`
    },
    {
      title: "Bước 1",
      content: `<div>
        <p> Kéo khối <block class="loops">đếm theo i từ ... đến ...</block> trong mục <block class="loops">VÒNG LẶP</block> gắn vào khối <block class="basic">lặp lại mãi</block>:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/analog-output/step1.png">
        </div>
      </div>`
    },
    {
      title: "Bước 2",
      content: `<div>
        <p>Thay đổi giá trị của khối <block class="loops">đếm theo i từ ... đến ...</block> thành từ  0 đến 1023, mỗi lần thêm 256. Analog output có giá trị trong khoảng từ 0 đến 1023. Mỗi lần tăng 256 thì từ 0 đến 1023 sẽ có 4 giá trị khác nhau, tương ứng với 4 mức sáng:
        </p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/analog-output/step2.png">
        </div>
      </div>`
    },
    {
      title: "Bước 3",
      content: `<div>
        <p>Trong mục <block class="advanced">NÂNG CAO</block> chọn mục <block class="pins">CHÂN CẮM</block>, dùng khối <block class="pins">xuất ra giá trị analog i cho chân P13</block> để điều khiển led:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/analog-output/step3.png">
        </div>
      </div>`
    },
    {
      title: "Bước 4",
      content: `<div>
        <p><block class="basic">tạm dừng...</block> chương trình một khoảng thời gian để dễ dàng quan sát:</p>
        <div class="ui segment raised">
          <img src="/assets/tutorials/analog-output/step4.png">
        </div>
      </div>`
    },
    {
      title: "Bước 5",
      content: `<div>
        <p>Kết nối Yolo:Bit với máy tính rồi chạy chương trình và quan sát kết quả.</p>
      </div>`
    },
    {
      title: "Bài tập mở rộng",
      content: `<div>
        <p>Hãy sử dụng analog output để điều khiển đèn led ở pin 13 tắt dần với 4 mức sáng khác nhau.</p>
      </div>`
    }
  ]
};
