module.exports = {
    
    id: "https://ohstem.vn/lp-courses/lap-trinh-stem/lap-trinh-tro-choi-voi-yolobit/lessons/gioi-thieu-8/",
    title: "Bắn tàu vũ trụ",
    detail:
      'Trong trò chơi này, có 1 tàu vũ trụ màu xanh lá cây sẽ liên tục chạy qua chạy lại ở hàng led trên cùng. Người chơi sẽ điều chỉnh 1 viên đạn màu đỏ ở hàng dưới cùng qua trái hoặc qua phải rồi khai hỏa để đạn bắn về phía tàu. Nếu bắn trúng sẽ được điểm. Trò chơi kết thúc khi người chơi sẽ thắng khi bắn trúng được 3 lần hoặc thua khi bắn hết 5 lượt .',
    image: "/assets/tutorials/courses/bantau.jpg",
    categoryIds: ["games"],
    //label: "Mới bắt đầu?",
    labelClass: "green ribbon huge",
    toolbox: [
      {
        category: "basic",
        blocks: ["yolobit_basic_scroll_text", "yolobit_basic_forever"]
      }
    ],
    steps: [
      {
       
      }
    ]
  };
  