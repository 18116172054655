import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./pages/home";
import Editor from "./pages/editor";
import Loader from "./components/Loader";
import "./App.scss";

class App extends Component {
  render() {
    return (
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/editor/" component={Editor} exact />
            <Route path="/editor/:editorId" component={Editor} />
            <Route path="/share/:sharedId" component={Editor} />
            <Route path="/tutorial/:tutorialId" component={Editor} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    );
  }
}

export default App;
